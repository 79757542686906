import { DatePicker } from '@shopify/polaris';
import { PageDownMajor } from '@shopify/polaris-icons';
import { SelectionType } from '@shopify/polaris/build/ts/latest/src/utilities/index-provider';
import moment from 'moment';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { AppChoiceList } from '../../../../core/components/choice-list/ChoiceList';
import { AppCollapsible } from '../../../../core/components/collapsible/Collapsible';
import { DateTooltip } from '../../../../core/components/date-tooltip/date-tooltip';
import { AppFilters, IAppliedFilter } from '../../../../core/components/filters/filters';
import { AppIcon } from '../../../../core/components/icon/icon';
import { AppIndexTable } from '../../../../core/components/index-table/index-table';
import { AppIndexTableCell } from '../../../../core/components/index-table/index-table-cell';
import { AppIndexTableRow } from '../../../../core/components/index-table/index-table-row';
import { AppLink } from '../../../../core/components/link/link';
import { AppTooltip } from '../../../../core/components/overlays/tooltip/tooltip';
import { IAction } from '../../../../core/components/resource-list/ResourceItem';
import { INVOICES_PER_PAGE } from '../../../../core/constants/invoices.constants';
import { TIME_INTERVAL } from '../../../../core/constants/product.constants';
import { withCurrency } from '../../../../core/helpers/currency.helper';
import { ICurrency } from '../../../../core/interfaces/ICurrency';
import { IGetPaidOrdersInvoicesParams } from '../../../api/invoice.api';
import { IOrderInvoice } from '../../../interfaces/IInvoice';

interface IInvoiceListProps {
  invoices: IOrderInvoice[];
  fetching: boolean;
  totalPageCount: number;
  currency?: ICurrency;
  onSelectionChange: (values: string[]) => void;
  setFilterParams: (filters: IGetPaidOrdersInvoicesParams) => void;
  handleDownloadInvoice: (invoiceId: string, paidViaCrowdship: boolean) => void;
  handleDownloadCredit: (creditId: string) => void;
  listActions: IAction[];
  filterParams: IGetPaidOrdersInvoicesParams;
  suppliers: {
    label: string;
    value: string;
  }[];
  selectedSuppliers: string[];
  setSelectedSuppliers: (values: string[]) => void;
  handleSelectionChange: (
    selectionType: SelectionType,
    isSelecting: boolean,
    selection?: any,
  ) => void;
  selectedResources: string[];
  allResourcesSelected: boolean;
  isFiltersHidden: boolean;
}

export function InvoiceList({
  invoices,
  fetching,
  totalPageCount,
  onSelectionChange,
  listActions,
  setFilterParams,
  filterParams,
  suppliers,
  selectedSuppliers,
  setSelectedSuppliers,
  handleSelectionChange,
  handleDownloadInvoice,
  handleDownloadCredit,
  selectedResources,
  allResourcesSelected,
  isFiltersHidden,
}: IInvoiceListProps) {
  const [queryValue, setQueryValue] = useState<string>('');
  const [period, setPeriod] = useState<TIME_INTERVAL[]>([TIME_INTERVAL.NONE]);
  const [{ month, year }, setDate] = useState({ month: moment().month(), year: moment().year() });
  const [selectedDates, setSelectedDates] = useState({
    start: moment().subtract(7, 'd').toDate(),
    end: moment().toDate(),
  });
  useEffect(() => {
    onSelectionChange(selectedResources);
  }, [selectedResources, onSelectionChange]);

  const handleSelectedSuppliersChange = useCallback(
    (value: string[]) => {
      setSelectedSuppliers(value);
      setFilterParams({
        ...filterParams,
        page: 0,
        suppliers: value,
      });
    },
    [filterParams, setFilterParams, setSelectedSuppliers],
  );
  const handleSelectedSuppliersRemove = useCallback(() => {
    setSelectedSuppliers([]);
    setFilterParams({
      page: 0,
      limit: INVOICES_PER_PAGE,
      dateRange: filterParams.dateRange,
      title: queryValue.replace(/\$/g, ''),
    });
  }, [filterParams.dateRange, queryValue, setFilterParams, setSelectedSuppliers]);

  const debouncedOnFilterChange = useDebouncedCallback(() => {
    setFilterParams({
      ...filterParams,
      title: queryValue.replace(/\$/g, ''),
    });
  }, 500);

  const handleQueryValueRemove = useCallback(
    (bulk?: boolean) => {
      setQueryValue('');
      if (!bulk)
        setFilterParams({
          ...filterParams,
          title: '',
        });
    },
    [setFilterParams, filterParams],
  );

  const handleFiltersQueryChange = (value: string) => {
    setQueryValue(value);
    debouncedOnFilterChange();
  };

  const handleMonthChange = useCallback((month, year) => setDate({ month, year }), []);

  const getChosenDateRange = useCallback(
    (interval: TIME_INTERVAL): { start: string; end: string } | undefined => {
      const todayTimestamp = moment().toISOString();
      switch (interval) {
        case TIME_INTERVAL.NONE:
          return;
        case TIME_INTERVAL.WEEK:
          return {
            start: moment().subtract(7, 'd').toISOString(),
            end: todayTimestamp,
          };
        case TIME_INTERVAL.MONTH:
          return {
            start: moment().subtract(1, 'M').toISOString(),
            end: todayTimestamp,
          };
        case TIME_INTERVAL.SIX_MONTHS:
          return {
            start: moment().subtract(6, 'M').toISOString(),
            end: todayTimestamp,
          };
        case TIME_INTERVAL.CUSTOM:
          return {
            start: selectedDates.start.toISOString(),
            // we need to grab the end-of-day moment to include invoices created throughout the day
            end: moment(selectedDates.end).endOf('day').toISOString(),
          };
      }
    },
    [selectedDates],
  );

  const handleCustomDateChange = useCallback(
    (date: { start: Date; end: Date }) => {
      setSelectedDates(date);

      setFilterParams({
        ...filterParams,
        page: 0,
        dateRange: {
          start: date.start.toISOString(),
          // we need to grab the end-of-day moment to include invoices created throughout the day
          end: moment(date.end).endOf('day').toISOString(),
        },
      });
    },
    [filterParams, setFilterParams],
  );

  const handleFiltersClearAll = useCallback(() => {
    setSelectedSuppliers([]);
    handleQueryValueRemove(true);
    setFilterParams({
      page: 0,
      limit: INVOICES_PER_PAGE,
    });
  }, [handleQueryValueRemove, setFilterParams, setSelectedSuppliers]);

  const appliedFilters: IAppliedFilter[] = useMemo(() => {
    const appliedSupplier = {
      key: 'selectedSuppliers',
      label: `Supplier: ${suppliers.find((s) => s.value === selectedSuppliers[0])?.label || ''}`,
      onRemove: handleSelectedSuppliersRemove,
    };

    if (selectedSuppliers.length) {
      return [appliedSupplier];
    }
    return [];
  }, [handleSelectedSuppliersRemove, suppliers, selectedSuppliers]);

  const invoicePOsCreationDates = useCallback((min: string, max: string) => {
    const minDate = moment(min).startOf('day').format('MMMM Do');
    const maxDate = moment(max).startOf('day').format('MMMM Do');
    if (minDate === maxDate) return minDate;
    return `${minDate}...${maxDate}`;
  }, []);

  const invoicesRows = useMemo(
    () =>
      invoices.map(
        (
          {
            name,
            id,
            invoiceId,
            creditNoteId,
            orderNumber,
            purchaseOrderNumber,
            supplierOrderNumber,
            paidAt,
            amountPaid,
            amountRefunded,
            minPOCreatedAt,
            maxPOCreatedAt,
            paymentMethod,
            supplier,
            lastCCDigits,
          },
          index,
        ) => (
          <AppIndexTableRow
            id={id.toString()}
            key={id}
            position={index}
            selected={selectedResources.includes(`${id}`)}
          >
            <AppIndexTableCell>{name}</AppIndexTableCell>
            <AppIndexTableCell>{orderNumber}</AppIndexTableCell>
            <AppIndexTableCell>
              <DateTooltip date={paidAt} />
            </AppIndexTableCell>
            <AppIndexTableCell>{purchaseOrderNumber}</AppIndexTableCell>
            <AppIndexTableCell>
              {invoicePOsCreationDates(minPOCreatedAt, maxPOCreatedAt)}
            </AppIndexTableCell>
            <AppIndexTableCell>{supplier}</AppIndexTableCell>
            <AppIndexTableCell>{supplierOrderNumber}</AppIndexTableCell>
            <AppIndexTableCell>
              {paymentMethod === 'CC'
                ? lastCCDigits
                  ? `CC x${lastCCDigits}`
                  : 'Credits'
                : `Manual via ${paymentMethod}`}
            </AppIndexTableCell>
            <AppIndexTableCell>
              <div className="amount">
                <div>{withCurrency(amountPaid)}</div>
                <div className="icon-wrapper">
                  <AppTooltip content="Download invoice PDF" dismissOnMouseOut>
                    <AppLink
                      onClick={() => {
                        handleDownloadInvoice(
                          paymentMethod === 'CC' ? name : (invoiceId as string),
                          paymentMethod === 'CC',
                        );
                      }}
                    >
                      <AppIcon source={PageDownMajor} />
                    </AppLink>
                  </AppTooltip>
                </div>
              </div>
            </AppIndexTableCell>
            <AppIndexTableCell>
              {amountRefunded && creditNoteId && (
                <div className="amount">
                  <div>{withCurrency(amountRefunded)}</div>
                  <div className="icon-wrapper">
                    <AppTooltip content="Download credit notes PDF" dismissOnMouseOut>
                      <AppLink onClick={() => handleDownloadCredit(creditNoteId)}>
                        <AppIcon source={PageDownMajor} />
                      </AppLink>
                    </AppTooltip>
                  </div>
                </div>
              )}
            </AppIndexTableCell>
          </AppIndexTableRow>
        ),
      ),
    [
      invoices,
      selectedResources,
      invoicePOsCreationDates,
      handleDownloadInvoice,
      handleDownloadCredit,
    ],
  );

  return (
    <>
      {!isFiltersHidden ? (
        <AppFilters
          filters={[
            {
              key: 'selectedSuppliers',
              label: 'Suppliers',
              shortcut: true,
              filter: (
                <AppChoiceList
                  title="Supplier list"
                  titleHidden
                  choices={suppliers}
                  selected={selectedSuppliers}
                  onChange={handleSelectedSuppliersChange}
                />
              ),
            },
            {
              key: 'period',
              label: 'Date',
              shortcut: true,
              hideClearButton: true,
              filter: (
                <AppChoiceList
                  title="Time interval options list"
                  titleHidden
                  choices={[
                    { label: 'all', value: TIME_INTERVAL.NONE },
                    { label: 'last week', value: TIME_INTERVAL.WEEK },
                    { label: 'last month', value: TIME_INTERVAL.MONTH },
                    { label: 'last six months', value: TIME_INTERVAL.SIX_MONTHS },
                    { label: 'custom', value: TIME_INTERVAL.CUSTOM },
                  ]}
                  selected={period}
                  onChange={(val: TIME_INTERVAL[]) => {
                    setPeriod(val);
                    setFilterParams({ ...filterParams, dateRange: getChosenDateRange(val[0]) });
                  }}
                />
              ),
            },
          ]}
          appliedFilters={appliedFilters}
          onQueryChange={handleFiltersQueryChange}
          onQueryClear={() => handleQueryValueRemove()}
          onClearAll={handleFiltersClearAll}
          queryValue={queryValue}
        />
      ) : null}
      <AppCollapsible id={'datePicker'} open={period.includes(TIME_INTERVAL.CUSTOM)}>
        <DatePicker
          month={month}
          year={year}
          onChange={handleCustomDateChange}
          onMonthChange={handleMonthChange}
          selected={selectedDates}
          multiMonth
          allowRange
        />
      </AppCollapsible>
      <AppIndexTable
        selectable={true}
        resourceName={{ singular: 'invoice', plural: 'invoices' }}
        headings={[
          { title: 'ID' },
          { title: 'Order' },
          { title: 'Date' },
          { title: 'Purchase order' },
          { title: 'POs created at' },
          { title: 'Supplier' },
          { title: 'Supplier order' },
          { title: 'Paid via' },
          { title: 'Amount paid' },
          { title: 'Amount refunded' },
        ]}
        itemCount={invoices.length}
        hasMoreItems={totalPageCount > 1}
        onSelectionChange={handleSelectionChange}
        selectedItemsCount={allResourcesSelected ? 'All' : selectedResources.length}
        promotedBulkActions={listActions.map((la) => ({
          ...la,
          onAction: () => {
            la.onAction && la.onAction();
          },
        }))}
        loading={fetching}
      >
        {invoicesRows}
      </AppIndexTable>
    </>
  );
}
