import { axiosInstance } from '../../core/api/base.api';
import { IAddress } from '../../shopify-supplier/interfaces/IOrder';
import { IGetRetailerOrdersQueryParams } from '../../shopify-supplier/api/orders.api';
import {
  IOrderDetails,
  IRetailerOrder,
  IRetailerOrdersSummary,
  IRetailerPurchaseOrderDetails,
  IRetailerPurchaseOrderWithUnpaidFeeForList,
  IRetailerPurchaseOrderForList,
} from '../interfaces/IOrder';

class OrdersApi {
  private backend = process.env.REACT_APP_BACK_END_URL as string;
  private rootPath = '/api/retailer/shopify/orders';

  async getOrders(params: IGetRetailerOrdersQueryParams) {
    return axiosInstance.get<IRetailerOrdersWithCount>(`${this.backend}${this.rootPath}`, {
      params: { ...params, statuses: params.statuses?.length ? params.statuses.join() : undefined },
    });
  }

  async getPurchaseOrders(filter: IGetPurchaseOrders) {
    const { fulfillmentStatus, suppliers, dateRange } = filter;

    return await axiosInstance.get<IRetailerPurchaseOrderList>(
      `${this.backend}${this.rootPath}/purchase-orders`,
      {
        params: {
          ...filter,
          fulfillmentStatus: fulfillmentStatus?.join(),
          suppliers: suppliers?.join(),
          startDate: dateRange?.start,
          endDate: dateRange?.end,
        },
      },
    );
  }

  async getSuppliersForPurchaseOrders() {
    return await axiosInstance.get<{ label: string; value: string }[]>(
      `${this.backend}${this.rootPath}/purchase-orders/suppliers`,
    );
  }

  async convertOrderId(id: number) {
    return await axiosInstance.get<{ id: string } | { notFound: true }>(
      `${this.backend}${this.rootPath}/convert-id/${id}`,
    );
  }

  async exportAllPurchaseOrders(filter: Omit<IGetPurchaseOrders, 'limit' | 'page'>) {
    const { fulfillmentStatus, query, paymentStatus, suppliers, dateRange } = filter;
    return await axiosInstance.post(
      `${this.backend}${this.rootPath}/purchase-orders/unpaid`,
      {
        query,
        paymentStatus,
        fulfillmentStatus: fulfillmentStatus?.join(),
        suppliers: suppliers?.join(),
        startDate: dateRange?.start,
        endDate: dateRange?.end,
      },
      {
        responseType: 'blob',
      },
    );
  }

  async getPurchaseOrdersWithUnpaidFee(filter: {
    limit: number;
    page: number;
    dateRange?: {
      start: string;
      end: string;
    };
  }) {
    const { limit, page, dateRange } = filter;
    return await axiosInstance.get<IRetailerPurchaseOrderWithUnpaidFeeList>(
      `${this.backend}${this.rootPath}/purchase-orders/unpaid-fee`,
      { params: { limit, page, startDate: dateRange?.start, endDate: dateRange?.end } },
    );
  }

  async getPurchaseOrderDetails(purchaseOrderId: string) {
    return await axiosInstance.get<IRetailerPurchaseOrderDetails>(
      `${this.backend}${this.rootPath}/purchase-orders/${purchaseOrderId}`,
    );
  }

  async getOrdersSummary() {
    return await axiosInstance.get<IRetailerOrdersSummary>(
      `${this.backend}${this.rootPath}/summary`,
    );
  }

  async getOrderDetails(orderId: string) {
    return await axiosInstance.get<IOrderDetails | { notFound: true }>(
      `${this.backend}${this.rootPath}/${orderId}/details`,
    );
  }

  async requestCancellation(orderId: string, lineItems: ICancellationRequestLineItem[]) {
    return await axiosInstance.post<void>(
      `${this.backend}${this.rootPath}/${orderId}/request-cancellation`,
      lineItems,
    );
  }

  async revokeCancellation(orderId: string) {
    return await axiosInstance.post<void>(
      `${this.backend}${this.rootPath}/${orderId}/revoke-cancellation`,
    );
  }

  async cancelOrder(orderId: string) {
    return await axiosInstance.post<void>(`${this.backend}${this.rootPath}/${orderId}/cancel`);
  }

  async acceptCrowdshipCancellation(orderId: string) {
    return await axiosInstance.post<IChargeResponse>(
      `${this.backend}${this.rootPath}/${orderId}/accept-crowdship-cancellation`,
    );
  }

  async chargeForOrder(orderId: string) {
    return await axiosInstance.post<IChargeResponse>(
      `${this.backend}${this.rootPath}/${orderId}/charge`,
    );
  }

  async editShippingAddress(orderId: string, address: IAddress, validate: boolean) {
    return axiosInstance.post<{ isValid: boolean }>(
      `${this.backend}${this.rootPath}/${orderId}/edit-address`,
      {
        address,
        validate,
      },
    );
  }

  async processOrder(orderId: string) {
    return await axiosInstance.post<void>(
      `${this.backend}${this.rootPath}/${orderId}/process-order`,
    );
  }
}

export const ordersApi = new OrdersApi();

export interface IRetailerOrdersWithCount {
  orders: IRetailerOrder[];
  total: number;
}

export interface ICancellationRequestLineItem {
  id: string;
  quantity: string;
}

export type IChargeResponse =
  | { success: true }
  | { paymentFailed: true }
  | { outOfStock: true }
  | { cancelled: true }
  | { notFound: true }
  | { noCancellation: true };

export interface IRetailerPurchaseOrderList {
  orders: IRetailerPurchaseOrderForList[];
  total: number;
}

export interface IGetPurchaseOrders {
  limit: number;
  page: number;
  paymentStatus?: string;
  fulfillmentStatus?: string[];
  query?: string;
  suppliers?: string[];
  dateRange?: {
    start: string;
    end: string;
  };
}

interface IRetailerPurchaseOrderWithUnpaidFeeList {
  orders: IRetailerPurchaseOrderWithUnpaidFeeForList[];
  total: number;
}
