import { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getSyncMatchesAction,
  getSyncStatusAction,
  startInventorySyncAction,
} from '../../../shopify-retailer/redux/modules/variant-sync/variant-sync.actions';
import {
  getVariantSyncInProgressSelector,
  getVariantSyncMatchesTotalSelector,
} from '../../../shopify-retailer/redux/modules/variant-sync/variant-sync.selectors';
import { IInventoryProduct } from '../../interfaces/IProduct';
import { AppCard } from '../structure/card/card';
import { ProductList } from './product-list';
import {
  ACTION_TITLE,
  INVENTORY_LIST_TITLE,
  retailerInventoryProductListHeadings,
} from './product-list.constants';
import { ProductListBaseProps } from './product-list.interfaces';
import { ProductSync } from './products-sync';
import { InventoryProductFilter } from './table-elements/product-filter/inventory-product-filter';
import { SyncProductFilter } from './table-elements/product-filter/sync-product-filter';
import { InventoryProductRow } from './table-elements/rows/inventory-product-row';
import { getIsVariantsSyncEnableSelector } from '../../../shopify-retailer/redux/modules/preferences/preferences.selectors';
import { InventoryRemoveModal } from './modals/inventory-remove-modal';

type InventoryProductListProps = ProductListBaseProps<IInventoryProduct> & {
  removeFromStore: () => void;
  disconnectProducts: () => void;
  handleExportCSV: () => void;
  selectedProducts: string[];
};

export const InventoryProductList = ({
  selectedProducts,
  products,
  removeFromStore,
  disconnectProducts,
  handleExportCSV,
  ...props
}: InventoryProductListProps) => {
  const dispatch = useDispatch();
  const syncInProgress = useSelector(getVariantSyncInProgressSelector);
  const matchesTotal = useSelector(getVariantSyncMatchesTotalSelector);
  const isVariantsSyncEnable = useSelector(getIsVariantsSyncEnableSelector);
  const [showSync, setShowSync] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const handleShowSync = useCallback(() => {
    setShowSync(!showSync);
    dispatch(getSyncStatusAction());
    if (!syncInProgress) {
      dispatch(getSyncMatchesAction(0));
    }
  }, [dispatch, showSync, syncInProgress]);

  const handleRefreshMatches = useCallback(() => {
    dispatch(startInventorySyncAction());
  }, [dispatch]);

  const actions = useMemo(() => {
    const listActions = [
      {
        content: ACTION_TITLE.EXPORT_PRODUCTS,
        onAction: handleExportCSV,
      },
      {
        content: ACTION_TITLE.DELETE_PRODUCT,
        onAction: () => {
          setOpenDeleteModal(true);
        },
      },
    ];

    if (
      !products
        .filter((p) => !p.processing && selectedProducts.find((id) => p.id === id))
        .every((p) => !p.connected)
    ) {
      listActions.push({
        content: ACTION_TITLE.DISCONNECT_PRODUCT,
        onAction: disconnectProducts,
      });
    }

    return listActions;
  }, [products, selectedProducts, disconnectProducts, handleExportCSV]);

  return (
    <div key={INVENTORY_LIST_TITLE} className="product-list">
      <AppCard
        title={INVENTORY_LIST_TITLE}
        actions={[
          {
            content: !showSync ? 'Show Matches' : 'Hide Matches',
            onAction: handleShowSync,
          },
          ...(matchesTotal && showSync
            ? [
                {
                  content: 'Refresh Matches',
                  onAction: handleRefreshMatches,
                  disabled: syncInProgress,
                },
              ]
            : []),
        ]}
      >
        {showSync ? (
          <>
            <SyncProductFilter />
            <ProductSync />
          </>
        ) : (
          <ProductList
            products={products}
            isVariantsSyncEnable={isVariantsSyncEnable}
            headings={retailerInventoryProductListHeadings}
            colSpan={11}
            listActions={actions}
            row={InventoryProductRow}
            filter={InventoryProductFilter}
            {...props}
          />
        )}
        <InventoryRemoveModal
          open={openDeleteModal}
          close={() => setOpenDeleteModal(false)}
          removeFromStore={removeFromStore}
        />
      </AppCard>
    </div>
  );
};
