import React, { useCallback, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import queryString from 'query-string';
import { ExternalMinor } from '@shopify/polaris-icons';
import { useSelector } from 'react-redux';
import { getShopSelector } from '../../../../../core/redux/modules/auth/auth.selectors';
import { AppIndexTable } from '../../../../../core/components/index-table/index-table';
import { AppIndexTableCell } from '../../../../../core/components/index-table/index-table-cell';
import { AppIndexTableRow } from '../../../../../core/components/index-table/index-table-row';
import { PageAwarePagination } from '../../../../../core/components/pagination/page-aware-pagination';
import { AppTextStyle } from '../../../../../core/components/text/text-style/TextStyle';
import { withCurrency } from '../../../../../core/helpers/currency.helper';
import { getCustomerFullName } from '../../../../../core/helpers/customer-fullname.helper';
import { ICurrency } from '../../../../../core/interfaces/ICurrency';
import { IGetRetailerOrdersQueryParams } from '../../../../../shopify-supplier/api/orders.api';
import { IRetailerOrder } from '../../../../interfaces/IOrder';
import { DateTooltip } from '../../../../../core/components/date-tooltip/date-tooltip';
import { OrderStatus } from '../common/order-status/order-status';
import { AppFilters, IAppliedFilter } from '../../../../../core/components/filters/filters';
import { AppChoiceList } from '../../../../../core/components/choice-list/ChoiceList';
import { useDebouncedCallback } from 'use-debounce';
import { AppIcon } from '../../../../../core/components/icon/icon';

import './order-list.scss';

interface IOrderListProps {
  searchString: string;
  ordersNumber: number;
  selectedTab: number;
  fetching: boolean;
  orders: IRetailerOrder[];
  ordersPerPage: number;
  filterParams: IGetRetailerOrdersQueryParams;
  onFilterChange: (params: IGetRetailerOrdersQueryParams) => void;
  // TODO: set as required when we'll store retailer's currency info
  currency?: ICurrency;
  changeUrlParams: (tabNumber: any, params?: IGetRetailerOrdersQueryParams) => void;
}

export function OrderList({
  orders,
  fetching,
  filterParams,
  onFilterChange,
  ordersNumber,
  ordersPerPage,
  searchString,
  changeUrlParams,
  selectedTab,
  currency = { isoCode: 'USD', symbol: '$' },
}: IOrderListProps) {
  const history = useHistory();
  const shop = useSelector(getShopSelector);
  const { search: query } = useLocation();

  const { statuses: URLStatuses, query: searchQueryString } = queryString.parse(query, {
    arrayFormat: 'separator',
    arrayFormatSeparator: '.',
  });

  const [selectedStatuses, setSelectedStatuses] = useState<string[]>(
    !!URLStatuses
      ? Array.isArray(URLStatuses) && URLStatuses.length
        ? (URLStatuses as string[]).map((s) => s.replace('-', ' '))
        : [(URLStatuses as string).replace('-', ' ')]
      : [],
  );
  const [queryValue, setQueryValue] = useState<string>((searchQueryString as string) || '');
  const statuses = useMemo(
    () => [
      { label: 'Fulfilled', value: 'fulfilled' },
      { label: 'Unfulfilled', value: 'unfulfilled' },
      { label: 'Partially fulfilled', value: 'partially fulfilled' },
      { label: 'Partially unfulfilled', value: 'partially unfulfilled' },
      { label: 'Cancelled', value: 'cancelled' },
      { label: 'Payment failed', value: 'payment failed' },
      { label: 'Needs Attention', value: 'needs attention' },
      { label: 'Account suspended', value: 'account suspended' },
    ],
    [],
  );

  const debouncedOnFilterChange = useDebouncedCallback(() => {
    onFilterChange({
      limit: filterParams.limit,
      statuses: selectedStatuses.map((s) => s.replace('needs attention', 'error')),
      page: 0,
      query: queryValue,
    });
    changeUrlParams(selectedTab, {
      limit: filterParams.limit,
      statuses: selectedStatuses,
      page: 0,
      query: queryValue,
    });
  }, 500);

  const totalPageCount = useMemo(
    () => Math.ceil(ordersNumber / ordersPerPage),
    [ordersNumber, ordersPerPage],
  );

  const onQueryChange = (value: string) => {
    setQueryValue(value);
    debouncedOnFilterChange();
  };

  const onPageChange = useCallback(
    (page: number) => {
      onFilterChange({
        limit: filterParams.limit,
        statuses: selectedStatuses,
        query: queryValue,
        page,
      });
      changeUrlParams(selectedTab, {
        limit: filterParams.limit,
        statuses: selectedStatuses,
        query: queryValue,
        page,
      });
    },
    [
      onFilterChange,
      filterParams.limit,
      selectedStatuses,
      queryValue,
      changeUrlParams,
      selectedTab,
    ],
  );

  const handleSelectedStatusesChange = useCallback(
    (value: string[]) => {
      setSelectedStatuses(value);
      onFilterChange({
        limit: filterParams.limit,
        statuses: value.map((v) => v.replace('needs attention', 'error')),
        query: queryValue,
        page: 0,
      });
      changeUrlParams(selectedTab, {
        limit: filterParams.limit,
        statuses: value,
        query: queryValue,
        page: 0,
      });
    },
    [onFilterChange, filterParams.limit, queryValue, changeUrlParams, selectedTab],
  );

  const handleSelectedStatusesRemove = useCallback(() => {
    setSelectedStatuses([]);
    onFilterChange({ limit: filterParams.limit, statuses: [], query: queryValue, page: 0 });
    changeUrlParams(selectedTab, {
      limit: filterParams.limit,
      statuses: [],
      query: queryValue,
      page: 0,
    });
  }, [onFilterChange, filterParams.limit, queryValue, changeUrlParams, selectedTab]);

  const handleQueryValueRemove = useCallback(
    (bulk?: boolean) => {
      setQueryValue('');
      if (!bulk) {
        onFilterChange({
          limit: filterParams.limit,
          statuses: selectedStatuses,
          page: 0,
          query: '',
        });
        changeUrlParams(selectedTab, {
          limit: filterParams.limit,
          statuses: selectedStatuses,
          page: 0,
          query: '',
        });
      }
    },
    [onFilterChange, filterParams.limit, selectedStatuses, changeUrlParams, selectedTab],
  );

  const handleFiltersClearAll = useCallback(() => {
    setSelectedStatuses([]);
    handleQueryValueRemove(true);
    onFilterChange({
      limit: filterParams.limit,
      statuses: [],
      page: 0,
      query: '',
    });
    changeUrlParams(selectedTab, {
      limit: filterParams.limit,
      statuses: [],
      page: 0,
      query: '',
    });
  }, [changeUrlParams, filterParams.limit, handleQueryValueRemove, onFilterChange, selectedTab]);

  const appliedFilters: IAppliedFilter[] = useMemo(() => {
    const appliedStatuses = {
      key: 'selectedStatuses',
      label: `Statuses: ${selectedStatuses.join(', ')}`,
      onRemove: handleSelectedStatusesRemove,
    };

    return selectedStatuses.length ? [appliedStatuses] : [];
  }, [selectedStatuses, handleSelectedStatusesRemove]);

  const renderOrdersTableRows = useMemo(() => {
    return orders.map(
      (
        {
          id,
          orderName,
          date,
          itemsCount,
          status,
          total,
          customerFirstName,
          customerLastName,
          customerCompany,
          purchaseOrdersCount,
        },
        index,
      ) => (
        <AppIndexTableRow
          id={id.toString()}
          key={id}
          position={index}
          selected={false}
          onNavigation={() => history.push(`/orders/${id}${searchString}`)}
        >
          <AppIndexTableCell>
            <div data-primary-link className="order-name">
              <AppTextStyle variation="strong">{orderName}</AppTextStyle>
              <div
                onClick={(e) => {
                  e.stopPropagation();

                  if (process.env.REACT_APP_APP_NAME) {
                    window.open(
                      `https://${shop}/admin/apps/${process.env.REACT_APP_APP_NAME}/orders/${id}`,
                    );
                    return;
                  }

                  window.open(window.location.pathname + `/${id}`);
                }}
              >
                <AppIcon source={ExternalMinor} color="base" />
              </div>
            </div>
          </AppIndexTableCell>

          <AppIndexTableCell>
            <DateTooltip date={date} />
          </AppIndexTableCell>
          <AppIndexTableCell>
            {getCustomerFullName(customerFirstName, customerLastName, customerCompany)}
          </AppIndexTableCell>
          <AppIndexTableCell>{withCurrency(total, currency)}</AppIndexTableCell>
          <AppIndexTableCell>{itemsCount}</AppIndexTableCell>
          <AppIndexTableCell>{purchaseOrdersCount}</AppIndexTableCell>
          <AppIndexTableCell>
            <OrderStatus status={status} />
          </AppIndexTableCell>
        </AppIndexTableRow>
      ),
    );
  }, [orders, currency, history, searchString, shop]);

  return (
    <>
      <AppFilters
        filters={[
          {
            key: 'selectedStatuses',
            label: 'Status',
            shortcut: true,
            filter: (
              <AppChoiceList
                title="Statuses list"
                titleHidden
                choices={statuses}
                selected={selectedStatuses}
                onChange={handleSelectedStatusesChange}
                allowMultiple
              />
            ),
          },
        ]}
        appliedFilters={appliedFilters}
        onQueryChange={onQueryChange}
        onQueryClear={() => handleQueryValueRemove()}
        onClearAll={handleFiltersClearAll}
        queryValue={queryValue}
      />
      <AppIndexTable
        selectable={false}
        resourceName={{ singular: 'order', plural: 'orders' }}
        headings={[
          { title: 'Order' },
          { title: 'Date' },
          { title: 'Customer Name' },
          { title: 'Total' },
          { title: 'Items' },
          { title: "PO's" },
          { title: 'Status' },
        ]}
        itemCount={orders.length}
        loading={fetching}
      >
        {renderOrdersTableRows}
      </AppIndexTable>
      <PageAwarePagination totalPageCount={totalPageCount} onPageChange={onPageChange} />
    </>
  );
}
