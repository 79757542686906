import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { AppBanner } from '../../../../core/components/feedback-indicators/banner/banner';
import { AppSpinner } from '../../../../core/components/feedback-indicators/spinner/spinner';
import { AppLink } from '../../../../core/components/link/link';
import { plansApi } from '../../../api/plans.api';
import { IRetailerStatus } from '../../../interfaces/IPlan';
import { getStatusAction } from '../../../redux/modules/status/status.actions';

interface IMigratingToShopifyBillingBannerProps {
  status: IRetailerStatus | null;
}

export const MigratingToShopifyBillingBanner = ({
  status,
}: IMigratingToShopifyBillingBannerProps) => {
  const dispatch = useDispatch();
  const [requesting, setRequesting] = useState(false);

  const migrate = useCallback(() => {
    if (!status) return;
    const { currentPlanId } = status;
    setRequesting(true);
    plansApi
      .requestPlanChange(currentPlanId)
      .then(({ data }) => {
        if ('requested' in data && data.requested) {
          window.open(data.confirmationUrl, '_blank');
        } else {
          console.error('Unexpected request return result', data);
        }
      })
      .catch(console.error)
      .finally(() => {
        setRequesting(false);
        dispatch(getStatusAction());
      });
  }, [dispatch, status]);

  if (!status || status?.chargeConfirmation || !status.migrationPending) return null;

  return (
    <div className="banner">
      <AppBanner title="Migrate to billing with Shopify" status="warning">
        <p>
          You are currently being billed using ChargeBee. Please{' '}
          <AppLink onClick={migrate}>click here</AppLink> to migrate to billing with Shopify.
        </p>
        <p>
          Once you migrate with a couple simple clicks, your monthly subscription fee will be
          charged through Shopify, however, orders will continue to be charged to the credit card
          you set up on the Account page.
        </p>
        {requesting && (
          <div style={{ display: 'flex', paddingTop: '0.5em' }}>
            <span style={{ paddingRight: '0.5em' }}>
              <AppSpinner size="small" />
            </span>{' '}
            Requesting...
          </div>
        )}
      </AppBanner>
    </div>
  );
};
