import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import Decimal from 'decimal.js';
import { useSelector } from 'react-redux';
import { useIndexResourceState } from '@shopify/polaris';
import { SelectionType } from '@shopify/polaris/build/ts/latest/src/utilities/index-provider';
import { AppPage } from '../../../../core/components/structure/page/page';
import { getNumberOfPages } from '../../../../core/helpers/item-list.helper';
import { ILabelCandidateDetail, ordersApi } from '../../../api/orders.api';
import { getStatusSelector } from '../../../redux/modules/status/status.selectors';
import { LabelList } from '../../containers/label-list/label-list';
import { PurchaseLabelsModal } from '../../modals/purchase-labels-modal/purchase-labels-modal';
import { AppToast } from '../../../../core/components/feedback-indicators/toast/toast';
import { printPdfChangingHistory } from '../../../../core/helpers/print-js-safari.helper';

const LABELS_PER_PAGE = 10;

export const ShipmentLayout: React.FunctionComponent<{}> = () => {
  const { currency } = useSelector(getStatusSelector);

  const history = useHistory();
  const [fetching, setFetching] = useState(false);
  const [shipmentDetails, setShipmentDetails] = useState<ILabelCandidateDetail[]>([]);
  const [selectedLabelsCandidates, setSelectedLabelsCandidates] = useState<string[]>([]);
  const [allLabelsSelected, setAllLabelsSelected] = useState(false);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(0);
  const [openPurchaseModal, setOpenPurchaseModal] = useState(false);
  const [showErrorToast, setShowErrorToast] = useState(false);
  const [allLabelsCost, setAllLabelsCost] = useState<null | number>(null);

  useEffect(() => {
    setFetching(true);
    ordersApi
      .getLabelCandidates({ page, limit: LABELS_PER_PAGE })
      .then(({ data: labelInfo }) => {
        setShipmentDetails(labelInfo.data);
        setTotal(labelInfo.total);
      })
      .finally(() => {
        setFetching(false);
      });
  }, [page]);

  useEffect(() => {
    setFetching(true);
    ordersApi
      .getLabelsCost()
      .then(({ data }) => {
        setAllLabelsCost(data.total);
      })
      .finally(() => {
        setFetching(false);
      });
  }, []);

  const calculateSelectedLabelsCost = useCallback(
    () =>
      shipmentDetails
        .reduce((acc, curr) => {
          if (selectedLabelsCandidates.includes(curr.id)) return acc.plus(new Decimal(curr.amount));

          return acc;
        }, new Decimal(0))
        .toNumber(),
    [shipmentDetails, selectedLabelsCandidates],
  );

  const { selectedResources, allResourcesSelected, handleSelectionChange } =
    useIndexResourceState(shipmentDetails);

  const deselectAll = useCallback(() => {
    handleSelectionChange('page' as SelectionType, false);
  }, [handleSelectionChange]);

  const selectionChanged = (
    selectionType: SelectionType,
    isSelecting: boolean,
    selection?: any,
  ) => {
    if (selectionType === 'all') setAllLabelsSelected(true);
    else if (allResourcesSelected) setAllLabelsSelected(false);
    handleSelectionChange(selectionType, isSelecting, selection);
  };

  const handlePurchaseLabels = useCallback(
    (withPS: boolean) => {
      setFetching(true);
      setOpenPurchaseModal(false);
      ordersApi
        .purchaseLabels(selectedLabelsCandidates, withPS)
        .then(({ data }) => {
          printPdfChangingHistory(data, history, '/orders');
        })
        .catch((error) => {
          console.error(error);
          setShowErrorToast(true);
        })
        .finally(() => setFetching(false));
    },
    [selectedLabelsCandidates, history],
  );

  const handlePurchaseAllLabels = useCallback(
    (withPS: boolean) => {
      setFetching(true);
      setOpenPurchaseModal(false);
      ordersApi
        .purchaseAllLabels(withPS)
        .then(({ data }) => {
          printPdfChangingHistory(data, history, '/orders');
        })
        .catch((error) => {
          console.error(error);
          setShowErrorToast(true);
        })
        .finally(() => setFetching(false));
    },
    [history],
  );

  return (
    <AppPage title="Create shipping labels" breadcrumbs={[{ content: 'Orders', url: '/orders' }]}>
      <LabelList
        shipmentDetails={shipmentDetails}
        fetching={fetching}
        currency={currency}
        selectedResources={selectedResources}
        allResourcesSelected={allResourcesSelected}
        handleSelectionChange={selectionChanged}
        onSelectionChange={setSelectedLabelsCandidates}
        listActions={[{ content: 'Purchase Labels', onAction: () => setOpenPurchaseModal(true) }]}
        totalPageCount={getNumberOfPages(total, LABELS_PER_PAGE)}
        onPageChange={(page) => {
          deselectAll();
          setPage(page);
        }}
      />
      {allLabelsCost && (
        <PurchaseLabelsModal
          open={openPurchaseModal}
          totalSum={allLabelsSelected ? allLabelsCost : calculateSelectedLabelsCost()}
          handlePurchaseLabels={allLabelsSelected ? handlePurchaseAllLabels : handlePurchaseLabels}
          onClose={() => setOpenPurchaseModal(false)}
        />
      )}
      {showErrorToast && (
        <AppToast error content="Unexpected error" onDismiss={() => setShowErrorToast(false)} />
      )}
    </AppPage>
  );
};
