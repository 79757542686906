import { useMemo } from 'react';
import { AppHeading } from '../../../../core/components/text/heading/heading';
import { convertWeightFromGramsToPoundsAndOunces } from '../../../../core/helpers/weight.helper';
import { ICurrency } from '../../../../core/interfaces/ICurrency';
import { ILocation } from '../../../interfaces/ILocation';
import {
  IAddress,
  IDimensions,
  IOrderDetailsProduct,
  IOrderProductLabel,
} from '../../../interfaces/IOrder';
import { LabelCandidate, LabelCandidateProductGroup } from './label-candidate';
import { LabeledGroup, LabeledProductGroup } from './labeled-group';

import './order-details.scss';

type IOrderLabelsProps = {
  orderId: number;
  shippingAddress: IAddress;
  products: IOrderDetailsProduct[];
  locations: ILocation[];
  requestedShipping?: string;
  currency?: ICurrency;
};

const defaultDimensions: IDimensions = {
  height: '9',
  length: '9',
  width: '9',
};

function groupProductsByLocation(
  products: IOrderDetailsProduct[],
  locations: ILocation[],
): LabelCandidateProductGroup[] {
  const candidates = products.reduce((groups: LabelCandidateProductGroup[], p) => {
    const unfulfilledQty =
      p.totalQty -
      (p.fulfilledQty + p.cancellations.reduce((total, c) => total + c.quantity.accepted, 0));
    if (groups.some((g) => g.location.id === p.locationId))
      return groups.map((g) =>
        g.location.id === p.locationId
          ? { ...g, products: [...g.products, { ...p, unfulfilledQty: unfulfilledQty.toString() }] }
          : g,
      );
    const location = locations.find((l) => l.id === p.locationId);
    if (!location) {
      console.error(`Location ${p.locationId} not found for product ${p.variantId}`);
      return groups;
    }
    return [
      ...groups,
      {
        location,
        products: [{ ...p, unfulfilledQty: unfulfilledQty.toString() }],
        dimensions: defaultDimensions,
        weight: { lbs: '', oz: '' },
      },
    ];
  }, []);

  return candidates.map((c) => {
    const totalGrams = c.products.reduce((total, p) => total + p.grams * +p.unfulfilledQty, 0);
    const { lbs, oz } = convertWeightFromGramsToPoundsAndOunces(totalGrams);
    return { ...c, weight: { lbs: lbs.toString(), oz: oz.toString() } };
  });
}

function splitGroupByLabel(group: LabelCandidateProductGroup): LabeledProductGroup[] {
  type ProductWithLabel = {
    product: Omit<IOrderDetailsProduct, 'labels'> & { labelQuantity: number };
    label: Pick<
      IOrderProductLabel,
      | 'labelId'
      | 'labelUrl'
      | 'price'
      | 'trackingNumber'
      | 'trackingUrl'
      | 'trackingCompany'
      | 'totalWeight'
      | 'dimensions'
    >;
  };

  const productsWithLabel = group.products.reduce(
    (div: ProductWithLabel[], p) => [
      ...div,
      ...p.labels.map((l) => ({ product: { ...p, labelQuantity: l.quantity }, label: { ...l } })),
    ],
    [],
  );

  return productsWithLabel.reduce(
    (groups: LabeledProductGroup[], p) =>
      groups.some((g) => g.label.labelId === p.label.labelId)
        ? groups.map((g) =>
            g.label.labelId === p.label.labelId
              ? { ...g, products: [...g.products, p.product] }
              : g,
          )
        : [...groups, { location: group.location, label: p.label, products: [p.product] }],
    [],
  );
}

/**
 * This component does not display cancelled products, only unfulfilled and fulfilled products (with labels) are shown
 * User can print or void labels for fulfilled items; calculate label cost and purchase labels for unfulfilled items
 * Weight and location are displayed for all products
 * Unfulfilled products are grouped by location and label calc/purchase features are provided along with quantity selection
 * Fulfilled products are grouped by label and label print and void features are provided
 */
export const OrderLabels = ({
  orderId,
  products,
  locations,
  requestedShipping,
  currency = { isoCode: 'USD', symbol: '$' },
}: IOrderLabelsProps) => {
  const unfulfilledProductGroups = useMemo(
    () =>
      groupProductsByLocation(
        products.filter(
          (p) =>
            p.fulfilledQty +
              p.cancellations.reduce((total, c) => total + c.quantity.accepted, 0) !==
            p.totalQty,
        ),
        locations,
      ),
    [products, locations],
  );

  const fulfilledProductGroups = useMemo(
    (): LabeledProductGroup[] =>
      groupProductsByLocation(
        products.filter((p) => p.fulfilledQty && p.labels.length),
        locations,
      ).reduce(
        (grouped: LabeledProductGroup[], group) => [...grouped, ...splitGroupByLabel(group)],
        [],
      ),
    [products, locations],
  );

  return (
    <div className="order-labels">
      {!unfulfilledProductGroups.length && !fulfilledProductGroups.length && (
        <div>No products applicable for label purchase.</div>
      )}
      {!!unfulfilledProductGroups.length && (
        <div className="unfulfilled-product-groups">
          <AppHeading>Unfulfilled products</AppHeading>
          {unfulfilledProductGroups.map((g, i) => (
            <div key={i}>
              <LabelCandidate
                group={g}
                currency={currency}
                orderId={orderId}
                requestedShipping={requestedShipping}
              />
            </div>
          ))}
        </div>
      )}
      {!!fulfilledProductGroups.length && (
        <div className="labeled-product-groups">
          <AppHeading>Fulfilled products</AppHeading>
          {fulfilledProductGroups.map((g, i) => (
            <div key={i}>
              <LabeledGroup group={g} orderId={orderId} />
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
