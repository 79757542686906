import { Icon } from '@shopify/polaris';
import { ShipmentMajor } from '@shopify/polaris-icons';
import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppBadge } from '../../../../../core/components/badge/badge';
import { AppButton } from '../../../../../core/components/button/Button';
import { AppThumbnail } from '../../../../../core/components/image-containers/thumbnail/Thumbnail';
import { AppIndexTableCell } from '../../../../../core/components/index-table/index-table-cell';
import { AppIndexTableRow } from '../../../../../core/components/index-table/index-table-row';
import { AppModal } from '../../../../../core/components/overlays/modal/modal';
import { AppTextStyle } from '../../../../../core/components/text/text-style/TextStyle';
import { calculateMargin } from '../../../../../core/helpers/calculate-margin.helper';
import { withCurrency } from '../../../../../core/helpers/currency.helper';
import { IMarketplaceBuildVariant } from '../../../../interfaces/IProduct';
import {
  disconnectMarketplaceConnectedProductsAction,
  getMarketplaceConnectedProductsAction,
} from '../../../../redux/modules/marketplace/marketplace.actions';
import { ConnectedProducts } from './connected-products';
import {
  fetchingMarketplaceVariantViewSelector,
  getMarketplaceConnectedProductsSelector,
} from '../../../../redux/modules/marketplace/marketplace.selectors';
import { TrackedQuantityLabelForVariant } from '../../../../../core/components/tracked-quantity-label-for-variant/tracked-quantity-label-for-variant';
import { getIsVariantsSyncEnableSelector } from '../../../../redux/modules/preferences/preferences.selectors';

type VariantListRowProps = {
  variant: IMarketplaceBuildVariant;
  index: number;
  onConnect: (id: string) => void;
  onDisconnect?: (id: string) => void;
  selected?: boolean;
  disableAdding?: boolean | null;
  showConnectedProductsLinks?: boolean;
  disableCurrentConnectionsButton?: boolean;
};
export const BuilderVariantListRow = ({
  variant,
  index,
  onConnect,
  onDisconnect,
  selected = false,
  disableAdding,
  showConnectedProductsLinks,
  disableCurrentConnectionsButton,
}: VariantListRowProps) => {
  const {
    id,
    productGroupTitle,
    title,
    vendor,
    MSRP,
    priceForRetailers,
    qty,
    sku,
    image,
    connectedProducts,
    supplierName,
    freeShipping,
    tracked,
    inventoryPolicy,
    margin,
  } = variant;

  const dispatch = useDispatch();
  const connectedProductsList = useSelector(getMarketplaceConnectedProductsSelector);
  const fetching = useSelector(fetchingMarketplaceVariantViewSelector);
  const isVariantsSyncEnable = useSelector(getIsVariantsSyncEnableSelector);
  const localMargin = useMemo(
    () => margin || calculateMargin(priceForRetailers, MSRP),
    [margin, MSRP, priceForRetailers],
  );

  const showConnectedProducts = useCallback(
    (variantId: string) => {
      dispatch(getMarketplaceConnectedProductsAction(variantId));
      setOpenConnectedProductsModal(true);
    },
    [dispatch],
  );

  const [openConnectedProductsModal, setOpenConnectedProductsModal] = useState(false);

  const handleDisconnect = useCallback(
    (productId: string) => {
      dispatch(disconnectMarketplaceConnectedProductsAction(productId, id));
      if (onDisconnect) onDisconnect(id);
    },
    [dispatch, onDisconnect, id],
  );

  const createConnectedProductsMarkup = useMemo(() => {
    if (!connectedProductsList) return null;
    return (
      <AppModal
        open={openConnectedProductsModal}
        title={'Connected products'}
        onClose={() => {
          setOpenConnectedProductsModal(false);
        }}
        large
      >
        <ConnectedProducts
          connectedProducts={connectedProductsList}
          onDisconnect={handleDisconnect}
          showLinks={showConnectedProductsLinks}
          fetching={fetching}
          isVariantsSyncEnable={isVariantsSyncEnable}
        />
      </AppModal>
    );
  }, [
    connectedProductsList,
    openConnectedProductsModal,
    handleDisconnect,
    showConnectedProductsLinks,
    fetching,
    isVariantsSyncEnable,
  ]);

  return (
    <>
      <AppIndexTableRow id={id} key={id} selected={selected} position={index}>
        <AppIndexTableCell>
          <div className="product-image">
            <AppThumbnail source={image} alt={title || productGroupTitle} />
          </div>
        </AppIndexTableCell>
        <AppIndexTableCell>
          <div className="product-title">
            <AppTextStyle variation="subdued">{vendor}</AppTextStyle>
            <AppTextStyle variation="strong">{productGroupTitle}</AppTextStyle>
            {title && <AppBadge>{title}</AppBadge>}
          </div>
        </AppIndexTableCell>
        <AppIndexTableCell>{sku}</AppIndexTableCell>
        <AppIndexTableCell>
          <div className="product-supplier">
            {supplierName} {freeShipping && <Icon source={ShipmentMajor} color="primary" />}
          </div>
        </AppIndexTableCell>
        <AppIndexTableCell>{withCurrency(priceForRetailers)}</AppIndexTableCell>
        <AppIndexTableCell>{withCurrency(MSRP)}</AppIndexTableCell>
        <AppIndexTableCell>
          <AppTextStyle variation={localMargin && localMargin > 0 ? 'positive' : 'negative'}>
            {(localMargin !== null ? localMargin : '--') + '%'}
          </AppTextStyle>
        </AppIndexTableCell>
        <AppIndexTableCell>
          <TrackedQuantityLabelForVariant
            quantity={qty}
            tracked={tracked}
            inventoryPolicy={inventoryPolicy}
          />
        </AppIndexTableCell>
        <AppIndexTableCell>
          <div className="expand-variants-btn undelined-text">
            <AppButton
              handleClick={(e) => {
                e.stopPropagation();
                onConnect(id);
              }}
              disabled={!!disableAdding}
            >
              Connect
            </AppButton>
            {!!connectedProducts && (
              <div className="connected-product-count">
                <AppButton
                  plain
                  disabled={disableCurrentConnectionsButton}
                  onClick={() => {
                    showConnectedProducts(id);
                  }}
                >
                  {`${connectedProducts}`} connected
                </AppButton>
              </div>
            )}
          </div>
        </AppIndexTableCell>
      </AppIndexTableRow>
      {createConnectedProductsMarkup}
    </>
  );
};
