import React, { useCallback, useMemo, useState } from 'react';
import { Popover } from '@shopify/polaris';
import { LinkMinor } from '@shopify/polaris-icons';
import { AppPopover } from '../../../../core/components/overlays/popover/popover';
import { AppTextStyle } from '../../../../core/components/text/text-style/TextStyle';
import { AppControlledRadioGroup } from '../../../../core/components/forms/radio/controlled-radio-group/controlled-radio-group';
import { Field, Form, Formik } from 'formik';
import { BasicFormInput } from '../../../../core/components/forms/basic-form-input/basic-form-input';
import { inventoryApi } from '../../../api/inventory.api';
import { IVariantDetails } from '../../../interfaces/IProductDetails';
import { SALE_PRICE_OPTION } from '../../../interfaces/IPreferences';
import { validation } from '../../../../core/helpers/validations.helper';
import { AppIcon } from '../../../../core/components/icon/icon';
import { ReactComponent as BrokenLink } from '../../../../core/images/broken-link.svg';
import { withCurrency } from '../../../../core/helpers/currency.helper';

interface IPopoverWithSectionProps {
  preferredAlignment?: 'left' | 'right' | 'center';
  fixed?: boolean;
  setUpdating: (value: boolean) => void;
  unsavedVariant: IVariantDetails;
  variant: IVariantDetails;
  position: number;
  setFieldValue: (field: string, value: any) => void;
  productId: string;
  prefix?: string;
}

export function PriceSyncPreferencePopover({
  preferredAlignment,
  fixed,
  setUpdating,
  unsavedVariant,
  variant,
  position,
  setFieldValue,
  productId,
  prefix = '',
  children,
}: React.PropsWithChildren<IPopoverWithSectionProps>) {
  const { id } = variant;
  const [active, setActive] = useState(false);

  const renderSyncField = useMemo(() => {
    const cursorPointerDisable = variant.managed ? 'price-sync' : 'cursor-default';
    if (unsavedVariant?.manuallySetPrice)
      return (
        <span className={`${cursorPointerDisable}`}>
          <AppTextStyle variation="subdued">
            <AppIcon source={() => <BrokenLink />} />
            {withCurrency(unsavedVariant.price)}
          </AppTextStyle>
        </span>
      );
    else if (unsavedVariant.useCompanyDefault)
      return unsavedVariant.salePriceOption === SALE_PRICE_OPTION.MSRP ? (
        <span className={`${cursorPointerDisable}`}>
          <AppTextStyle variation="positive">
            <AppIcon source={LinkMinor} color="success" />
            <div>
              SRP
              <div className="price-value">= {withCurrency(unsavedVariant.price)}</div>
            </div>
          </AppTextStyle>
        </span>
      ) : (
        <span className={`${cursorPointerDisable}`}>
          <AppTextStyle variation="positive">
            <AppIcon source={LinkMinor} color="success" />
            <div>
              Cost + {unsavedVariant.defaultMarkup}%
              <div className="price-value">= {withCurrency(unsavedVariant.price)}</div>
            </div>
          </AppTextStyle>
        </span>
      );

    return (
      <span className={`${cursorPointerDisable}`}>
        {unsavedVariant.salePriceOption === SALE_PRICE_OPTION.MSRP ? (
          <AppTextStyle variation="positive">
            <AppIcon source={LinkMinor} color="success" />
            <div>
              SRP<div className="price-value">= {withCurrency(unsavedVariant.price)}</div>
            </div>
          </AppTextStyle>
        ) : (
          <AppTextStyle variation="positive">
            <AppIcon source={LinkMinor} color="success" />
            <div>
              Cost + {unsavedVariant.defaultMarkup}%
              <div className="price-value">= {withCurrency(unsavedVariant.price)}</div>
            </div>
          </AppTextStyle>
        )}
      </span>
    );
  }, [
    unsavedVariant.defaultMarkup,
    unsavedVariant?.manuallySetPrice,
    unsavedVariant.salePriceOption,
    unsavedVariant.useCompanyDefault,
    unsavedVariant.price,
    variant.managed,
  ]);

  const toggleActive = useCallback(
    () =>
      setActive((active) => {
        return variant.managed ? !active : active;
      }),
    [variant.managed],
  );
  const activator = <div onClick={toggleActive}>{renderSyncField}</div>;

  const retailerSetting = useMemo(() => {
    if (variant.retailerSalePriceOption === SALE_PRICE_OPTION.DROP_SHIP_COST_WITH_MARKUP)
      return `Cost + ${variant.retailerDefaultMarkup}%`;

    return 'SRP';
  }, [variant.retailerSalePriceOption, variant.retailerDefaultMarkup]);

  const updateSync = useCallback(
    (value: string, id: string, markup?: number) => {
      setUpdating(true);
      inventoryApi
        .getUpdatedPrice(productId, {
          markup: markup !== undefined ? markup : unsavedVariant.defaultMarkup || 0,
          useCompanyDefault: value === 'use company',
          salePriceOption:
            value === 'msrp'
              ? SALE_PRICE_OPTION.MSRP
              : value === 'cost'
              ? SALE_PRICE_OPTION.DROP_SHIP_COST_WITH_MARKUP
              : variant.salePriceOption,
          id: variant.id,
        })
        .then(({ data }) => {
          if (!('notFound' in data)) {
            setFieldValue(`${prefix}[${position}].price`, data.price.toString());
            switch (value) {
              case 'msrp':
                setFieldValue(`${prefix}[${position}].salePriceOption`, SALE_PRICE_OPTION.MSRP);
                setFieldValue(`${prefix}[${position}].useCompanyDefault`, false);
                setFieldValue(`${prefix}[${position}].manuallySetPrice`, false);
                break;
              case 'cost':
                setFieldValue(
                  `${prefix}[${position}].salePriceOption`,
                  SALE_PRICE_OPTION.DROP_SHIP_COST_WITH_MARKUP,
                );
                setFieldValue(`${prefix}[${position}].useCompanyDefault`, false);
                setFieldValue(`${prefix}[${position}].manuallySetPrice`, false);
                break;
              case 'disable sync':
                setFieldValue(`${prefix}[${position}].useCompanyDefault`, false);
                setFieldValue(`${prefix}[${position}].manuallySetPrice`, true);
                break;
              case 'use company':
                setFieldValue(`${prefix}[${position}].useCompanyDefault`, true);
                setFieldValue(`${prefix}[${position}].manuallySetPrice`, false);
                setFieldValue(
                  `${prefix}[${position}].defaultMarkup`,
                  data.defaultMarkup.toString(),
                );
                setFieldValue(`${prefix}[${position}].salePriceOption`, data.salePriceOption);
            }
          }
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          setUpdating(false);
        });
    },
    [
      position,
      prefix,
      productId,
      setFieldValue,
      setUpdating,
      unsavedVariant.defaultMarkup,
      variant.id,
      variant.salePriceOption,
    ],
  );

  return (
    <AppPopover
      active={active}
      activator={activator}
      onClose={toggleActive}
      fixed={fixed}
      preferredAlignment={preferredAlignment}
    >
      <Popover.Pane>
        <Popover.Section>
          <div className="radio-popover-wrapper">
            <AppTextStyle variation="strong">Customize Price Sync</AppTextStyle>
            <br />
            <AppControlledRadioGroup
              optionsDesc={[
                {
                  value: 'disable sync',
                  label: (
                    <AppTextStyle>
                      <span className="disable-sync-wrapper">
                        Static price: $&nbsp;
                        <Formik
                          initialValues={unsavedVariant}
                          onSubmit={() => {}}
                          enableReinitialize
                        >
                          {({ values }) => (
                            <Form name={`variant-${id}`}>
                              <Field name={'price'} validate={validation.isPositiveNumber}>
                                {(meta: any) => (
                                  <BasicFormInput
                                    name={'price'}
                                    controlled
                                    autoFocus
                                    onBlur={(value: string) => {
                                      if (
                                        !meta.meta.error &&
                                        value &&
                                        unsavedVariant.price !== value
                                      ) {
                                        setFieldValue(`${prefix}[${position}].price`, value);

                                        setFieldValue(
                                          `${prefix}[${position}].manuallySetPrice`,
                                          true,
                                        );
                                        setFieldValue(
                                          `${prefix}[${position}].priceSyncOption`,
                                          'disable sync',
                                        );
                                      }
                                    }}
                                  />
                                )}
                              </Field>
                            </Form>
                          )}
                        </Formik>
                      </span>
                    </AppTextStyle>
                  ),
                },
                {
                  value: 'cost',
                  label: (
                    <AppTextStyle>
                      <span className="sync-cost-wrapper">
                        Sync Cost +&nbsp;
                        <Formik
                          initialValues={unsavedVariant}
                          onSubmit={() => {}}
                          enableReinitialize
                        >
                          {({ values }) => (
                            <Form name={`variant-${id}`}>
                              <Field name="defaultMarkup" validate={validation.isNumber}>
                                {(meta: any) => (
                                  <BasicFormInput
                                    name="defaultMarkup"
                                    controlled
                                    autoFocus
                                    onBlur={(value: string) => {
                                      if (!meta.meta.error && value) {
                                        setFieldValue(
                                          `${prefix}[${position}].defaultMarkup`,
                                          value,
                                        );
                                        if (unsavedVariant.defaultMarkup !== +value) {
                                          setFieldValue(
                                            `${prefix}[${position}].priceSyncOption`,
                                            'cost',
                                          );
                                          updateSync('cost', variant.id, +value);
                                        }
                                      }
                                    }}
                                  />
                                )}
                              </Field>
                            </Form>
                          )}
                        </Formik>
                        &nbsp;%
                      </span>
                    </AppTextStyle>
                  ),
                },
                {
                  value: 'msrp',
                  label: <AppTextStyle>Sync SRP</AppTextStyle>,
                },
                {
                  value: 'use company',
                  label: (
                    <AppTextStyle>
                      <div>
                        Use company default
                        <p>[{retailerSetting}]</p>
                      </div>
                    </AppTextStyle>
                  ),
                },
              ]}
              name={`${prefix}[${position}].priceSyncOption`}
              onChange={updateSync}
            />
            <div className="price-label">
              <span>{`Price: $${unsavedVariant.price}`}</span>
              {children}
            </div>
          </div>
        </Popover.Section>
      </Popover.Pane>
    </AppPopover>
  );
}
