import { toLower } from 'lodash';
import queryString from 'query-string';
import React, { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { AppPage } from '../../../../core/components/structure/page/page';
import { AppTabs } from '../../../../core/components/tabs/tabs';
import { ShopifyOrderDetailsLayout } from '../order-details-layout/shopify-order-details-layout';
import { OrderListLayout } from '../order-list-layout/order-list-layout';
import { PurchaseOrderListLayout } from '../purchase-order-list-layout/purchase-order-list-layout';
import './orders-layout.scss';

interface IOrdersLayout {}

const tabs = [
  { id: 'orders-view', content: 'Orders' },
  { id: 'purchase-orders-view', content: 'Purchase Orders' },
];
const findTabIndexByQuery = (tab?: string): number => {
  if (!tab) return 0;
  const index = tabs.findIndex((t) => toLower(t.content).replace(' ', '-') === tab);
  if (index === -1) return 0;
  return index;
};

export const OrdersLayout: React.FunctionComponent<IOrdersLayout> = () => {
  const { search: query } = useLocation();
  const history = useHistory();
  const [orderId, setOrderId] = useState<number | undefined>();

  const [searchString, setSearchString] = useState(query);
  const parsedSearchQuery = useMemo(() => new URLSearchParams(query), [query]);
  const tab = parsedSearchQuery.get('tab') as string;
  const [selectedTab, setSelectedTab] = useState<number>(findTabIndexByQuery(tab));

  const changeUrlParams = useCallback(
    (tabNumber: number, params?) => {
      const search = `?tab=${toLower(tabs[tabNumber].content).replace(' ', '-')}${
        params
          ? Object.keys(params)
              .map((p) => {
                const value = params[`${p}`];
                if (!value) return '';
                if (p === 'dateRange') return '';
                if (Array.isArray(value))
                  return value.length
                    ? `${queryString.stringify(
                        { [p]: value.map((v) => v.replace(' ', '-')) },
                        { arrayFormat: 'separator', arrayFormatSeparator: '.' },
                      )}`
                    : '';
                return `&${p}=${value}`;
              })
              .join('&')
          : ''
      }`;
      history.push({
        search,
      });
      setSearchString(search);
    },
    [history],
  );

  useEffect(() => {
    const id = parsedSearchQuery.get('id');
    if (!id || Number.isNaN(id)) {
      setOrderId(undefined);
      return;
    }

    setOrderId(+id);
  }, [parsedSearchQuery]);

  useEffect(() => {
    setSelectedTab(findTabIndexByQuery(tab));
  }, [tab]);

  const tabContent: { [key: number]: ReactNode } = useMemo(
    () => ({
      0: (
        <OrderListLayout
          changeUrlParams={changeUrlParams}
          searchString={searchString}
          selectedTab={0}
        />
      ),
      1: (
        <PurchaseOrderListLayout
          selectedTab={1}
          changeUrlParams={changeUrlParams}
          searchString={searchString}
        />
      ),
    }),
    [changeUrlParams, searchString],
  );

  return (
    <React.Fragment>
      {orderId ? (
        <ShopifyOrderDetailsLayout id={orderId} />
      ) : (
        <div className="orders-wrapper">
          <AppPage title="Orders">
            <AppTabs
              tabs={tabs}
              selected={selectedTab}
              onSelect={(i) => {
                setSelectedTab(i);
                changeUrlParams(i);
              }}
            >
              {tabContent[selectedTab]}
            </AppTabs>
          </AppPage>
        </div>
      )}
    </React.Fragment>
  );
};
