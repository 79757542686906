import { AppControlledSelect } from '../../../../../core/components/forms/controlled-select/controlled-select';
import { AppControlledTextField } from '../../../../../core/components/forms/controlled-text-field/controlled-text-field';
import { PAYOUT_ACCOUNT_TYPE } from '../../../../interfaces/IPreferences';

export const AchWireDetailsForm = () => (
  <div className="payout-details">
    <AppControlledTextField
      label="Routing number"
      placeholder="123456789"
      name="payout.achOrWireDetails.routing"
    />
    <AppControlledTextField
      label="Account number"
      placeholder="0123456789"
      name="payout.achOrWireDetails.account"
    />
    <AppControlledTextField
      label="SWIFT code"
      placeholder="AAAA-BB-CC-123"
      name="payout.achOrWireDetails.swiftCode"
    />
    <AppControlledTextField
      label="Account Owner Name"
      name="payout.achOrWireDetails.accountOwnerName"
    />
    <AppControlledTextField label="Account Address" name="payout.achOrWireDetails.accountAddress" />
    <AppControlledSelect
      label="Account Type"
      options={Object.values(PAYOUT_ACCOUNT_TYPE).map((type) => ({ label: type, value: type }))}
      name="payout.achOrWireDetails.accountType"
    />
  </div>
);
