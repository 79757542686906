import { Form, Formik } from 'formik';
import React, { useCallback, useState } from 'react';
import { setAxiosAuthorization } from '../../../../core/api/base.api';
import { AppButton } from '../../../../core/components/button/Button';
import { AppToast } from '../../../../core/components/feedback-indicators/toast/toast';
import { AppControlledTextField } from '../../../../core/components/forms/controlled-text-field/controlled-text-field';
import { AppCard } from '../../../../core/components/structure/card/card';
import { AppPage } from '../../../../core/components/structure/page/page';
import { validation } from '../../../../core/helpers/validations.helper';
import './auth.scss';
import { useHistory } from 'react-router';
import { setIsLogged } from '../../../../core/redux/modules/auth/auth.actions';
import { useDispatch } from 'react-redux';
import { authApi } from '../../../api/auth.api';

export const OnboardingLayout = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [loggingIn, setLoggingIn] = useState(false);
  const [error, setError] = useState('');

  const handleCreateNewAccount = useCallback(() => {
    authApi
      .confirmNewAccount()
      .then(({ data }) => {
        if ('success' in data) {
          dispatch(setIsLogged(true));
          history.push('/');
        } else {
          setError('Unexpected error');
        }
      })
      .catch(() => {
        setError('Unexpected error');
      });
  }, [dispatch, history]);

  const handleLogin = useCallback(
    (body: { email: string; password: string }) => {
      setLoggingIn(true);
      authApi
        .login(body)
        .then(({ data }) => {
          if ('error' in data) {
            setError('Cannot login with these credentials');
          } else {
            setAxiosAuthorization(data.token);
            setLoggingIn(false);
            dispatch(setIsLogged(true));
            history.push('/');
          }
        })
        .catch((e) => {
          console.error(e);
          setError('Unexpected error. Please, try again later');
        })
        .finally(() => {
          setLoggingIn(false);
        });
    },
    [dispatch, history],
  );

  return (
    <AppPage>
      <div className="auth-page">
        <div className="small-card">
          <Formik
            initialValues={{ email: '', password: '' }}
            onSubmit={handleLogin}
            enableReinitialize
          >
            {({ submitForm }) => (
              <Form name={'sign-in'}>
                <AppCard
                  title="Sign In"
                  sectioned
                  primaryFooterAction={{
                    content: 'Sign In',
                    onAction: submitForm,
                    loading: loggingIn,
                  }}
                >
                  <div className="sign-in-via-platform">
                    <AppButton fullWidth onClick={() => handleCreateNewAccount()}>
                      Create a new account
                    </AppButton>
                  </div>
                  <div className="sign-in-separator">Or</div>
                  <AppControlledTextField
                    autoComplete=""
                    label="Email"
                    name="email"
                    type="email"
                    validate={validation.isEmail}
                  />
                  <AppControlledTextField
                    name="password"
                    autoComplete=""
                    label="Password"
                    type="password"
                    validate={validation.isNotEmpty}
                  />
                </AppCard>
              </Form>
            )}
          </Formik>
        </div>
        {error && <AppToast content={error} onDismiss={() => setError('')} error />}
      </div>
    </AppPage>
  );
};
