import { AppIcon } from '../../../../../../core/components/icon/icon';
import { AppLink } from '../../../../../../core/components/link/link';
import { AppTooltip } from '../../../../../../core/components/overlays/tooltip/tooltip';
import { PageDownMajor } from '@shopify/polaris-icons';
import './invoice-download-link.scss';
import React from 'react';

type InvoiceDownloadLinkProps = {
  title?: string;
  invoiceId?: string;
  link?: string;
};

export const InvoiceDownloadLink = ({
  title = 'Invoice: ',
  invoiceId,
  link,
}: InvoiceDownloadLinkProps) => (
  <div className="invoice-download-link">
    {title}
    {invoiceId && link ? (
      <span>
        {invoiceId}{' '}
        <div className="icon-wrapper">
          <AppTooltip content="Download invoice PDF" dismissOnMouseOut>
            <AppLink onClick={() => window.open(link)}>
              <AppIcon color="highlight" source={PageDownMajor} />
            </AppLink>
          </AppTooltip>
        </div>
      </span>
    ) : (
      'not created'
    )}
  </div>
);
