import { AxiosResponse } from 'axios';
import { axiosInstance } from '../../core/api/base.api';
import {
  IProductPreferences,
  IOrderPreferences,
  INotificationsPreferences,
  IShippingPreferences,
  IPreferences,
} from '../interfaces/IPreferences';

class PreferencesApi {
  private backend = process.env.REACT_APP_BACK_END_URL as string;
  private rootPath = '/api/retailer/shopify/preferences';

  async getPreferences(): Promise<AxiosResponse<IPreferences>> {
    return await axiosInstance.get(`${this.backend}${this.rootPath}`);
  }

  async getProductPreferences(): Promise<AxiosResponse<IProductPreferences>> {
    return await axiosInstance.get(`${this.backend}${this.rootPath}/product`);
  }

  async getCarrierServices(): Promise<AxiosResponse<string[]>> {
    return await axiosInstance.get(`${this.backend}${this.rootPath}/carrier-services`);
  }

  async updateProfileLogoPreferences(file: FormData) {
    return await axiosInstance.put<{ logo: string }>(
      `${this.backend}${this.rootPath}/profile/logo`,
      file,
    );
  }

  async updateProductPreferences(preference: IProductPreferences) {
    return await axiosInstance.put<void>(`${this.backend}${this.rootPath}/product`, {
      preference,
    });
  }

  async updateOrderPreferences(preference: IOrderPreferences) {
    return await axiosInstance.put<void>(`${this.backend}${this.rootPath}/order`, preference);
  }

  async updateShippingPreferences(preferences: IShippingPreferences) {
    return await axiosInstance.put<void>(
      `${this.backend}/api/account/preferences-retailer/shipping`,
      preferences,
    );
  }

  async updateNotificationPreferences(preferences: INotificationsPreferences) {
    return await axiosInstance.put<void>(
      `${this.backend}/api/account/preferences-retailer/notifications`,
      preferences,
    );
  }

  async calculatePriceChanges(
    preferences: IProductPreferences,
  ): Promise<AxiosResponse<IProductChangesToCompare[]>> {
    return await axiosInstance.post(`${this.backend}${this.rootPath}/changes`, preferences);
  }

  async validateShippoToken(token: string) {
    return await axiosInstance.post<{ valid: boolean }>(
      `${this.backend}${this.rootPath}/validate-token`,
      { token },
    );
  }
}

export const preferencesApi = new PreferencesApi();

export interface IProductChangesToCompare {
  title: string;
  image?: string;
  variants: {
    title: string;
    currentSalePrice: number;
    pendingSalePrice: number;
    currentCompareAtPrice: number | string;
    pendingCompareAtPrice: number | string;
  }[];
}
