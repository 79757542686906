import { useCallback, useEffect, useMemo, useState } from 'react';
import { Country, countryApi } from '../../../../../core/api/country.api';
import { AppButton } from '../../../../../core/components/button/Button';
import { AppDataTable } from '../../../../../core/components/data-table/DataTable';
import { AppSpinner } from '../../../../../core/components/feedback-indicators/spinner/spinner';
import { AppControlledCheckbox } from '../../../../../core/components/forms/controlled-checkbox/controlled-checkbox';
import { AppControlledSelect } from '../../../../../core/components/forms/controlled-select/controlled-select';
import { AppControlledTextField } from '../../../../../core/components/forms/controlled-text-field/controlled-text-field';
import { AppControlledRadioGroup } from '../../../../../core/components/forms/radio/controlled-radio-group/controlled-radio-group';
import { AppCard } from '../../../../../core/components/structure/card/card';
import { AppLayoutAnnotatedSection } from '../../../../../core/components/structure/layout/layout-annotated-section';
import { AppTextContainer } from '../../../../../core/components/text-container/text-container';
import { AppTextStyle } from '../../../../../core/components/text/text-style/TextStyle';
import { carrierServiceConstants } from '../../../../../core/constants/carrier-service.constants';
import { validation } from '../../../../../core/helpers/validations.helper';
import { preferencesApi } from '../../../../api/preferences.api';
import {
  PACKING_SLIP_FORMAT,
  postageSetupOptions,
  shippingMappingOptions,
} from '../../../../constants/preferences.constants';
import {
  AVAILABLE_RATES,
  IPreferences,
  ISupplierShippingTypesMapping,
  IWarehouseAddress,
} from '../../../../interfaces/IPreferences';
import './shipping-section.scss';

type ShippingSectionProps = {
  values: IPreferences;
  setFieldValue: (field: string, value: any) => void;
};

export const ShippingSection = ({ values, setFieldValue }: ShippingSectionProps) => {
  const getCarrierServiceLabel = useCallback((key: string | undefined) => {
    if (!key) return '';
    const [carrier, service] = key.split('.');
    return carrier + ' ' + carrierServiceConstants[carrier][service].name;
  }, []);
  const [countries, setCountries] = useState<Country[]>([]);
  const [validatingAddress, setValidatingAddress] = useState(false);
  const [cachedAddressValidation, setCachedAddressValidation] = useState<
    Promise<string | undefined> | undefined | string
  >(undefined);
  const [advancedMappingOptionsExpanded, setAdvancedMappingOptionsExpanded] = useState(false);
  useEffect(() => {
    countryApi.getCountries().then(({ data: result }) => {
      setCountries(result.data);
    });
  }, []);

  const [selectedCountry, setSelectedCountry] = useState(
    values.shipping.warehouseAddress?.countryCode || '',
  );

  const countriesOption = useMemo(
    () => countries.map((c) => ({ label: c.name, value: c.iso2 })),
    [countries],
  );

  const states = useMemo(
    () =>
      selectedCountry
        ? countries
            .find((c) => c.iso2 === selectedCountry)
            ?.states.map((s) => ({
              label: s.name,
              value: s.state_code,
            })) || []
        : [],
    [selectedCountry, countries],
  );

  const getDefaultDays = useCallback(
    (
      shippingType: ISupplierShippingTypesMapping,
    ): { startDeliveryTime: number; finishDeliveryTime: number } => {
      switch (shippingType.shippingType) {
        case 'Standard Domestic':
          return {
            startDeliveryTime: 5,
            finishDeliveryTime: 9,
          };
        case 'Expedited Domestic':
          return {
            startDeliveryTime: 2,
            finishDeliveryTime: 4,
          };
        case 'Standard International':
          return {
            startDeliveryTime: 7,
            finishDeliveryTime: 21,
          };
        case 'Expedited International':
          return {
            startDeliveryTime: 2,
            finishDeliveryTime: 14,
          };
      }
    },
    [],
  );

  const validateAddress = useCallback(
    (warehouseAddress: IWarehouseAddress) => {
      const addressFilled =
        warehouseAddress.address1 && warehouseAddress.city && warehouseAddress.zip;
      if (values.shipping.useCustomWarehouseAddress === 'use default' || !addressFilled) {
        setCachedAddressValidation(undefined);
        return;
      }

      setValidatingAddress(true);
      let errorMessage: string | undefined;
      const validating = new Promise<string | undefined>((resolve, reject) => {
        preferencesApi
          .validateAddress({
            ...warehouseAddress,
            address2: warehouseAddress.address2 || undefined,
            province: warehouseAddress.province || undefined,
            provinceCode: warehouseAddress.provinceCode || undefined,
          })
          .then(({ data }) => {
            if (!data.isValid) {
              errorMessage = 'Invalid address';
            }
            resolve(errorMessage);
          })
          .catch((error) => {
            console.error(error);
            reject(error);
          })
          .finally(() => {
            setFieldValue('shipping.warehouseAddress.warehouseAddressValid', true);
            setValidatingAddress(false);
          });
      });
      setCachedAddressValidation(validating);
    },
    [values.shipping.useCustomWarehouseAddress, setFieldValue],
  );

  const nonEmptyAddressFieldValidation = useCallback(
    (value) => validation.isNotEmpty(value) || cachedAddressValidation,
    [cachedAddressValidation],
  );

  return (
    <>
      <AppLayoutAnnotatedSection title="Shipping Location">
        <AppCard sectioned title="Return address">
          <AppTextStyle>
            The address that shows on your shipping labels as the “Return address”.
          </AppTextStyle>
          <br />
          <AppTextStyle variation="subdued">
            Note: only supported by US carriers USPS, UPS, FedEx.
          </AppTextStyle>
          <br />
          <AppControlledRadioGroup
            optionsDesc={[
              {
                value: 'use default',
                label: (
                  <AppTextStyle variation="strong">
                    Use the Ship From location as the return address
                  </AppTextStyle>
                ),
              },
              {
                value: 'use custom',
                label: (
                  <AppTextStyle variation="strong">Specify a custom Return Address</AppTextStyle>
                ),
              },
            ]}
            name="shipping.useCustomWarehouseAddress"
          />
          {values.shipping.useCustomWarehouseAddress === 'use custom' && (
            <>
              <AppControlledSelect
                label="Country"
                name={'shipping.warehouseAddress.countryCode'}
                options={countriesOption}
                onChange={(value) => {
                  const country = countriesOption.find((c) => c.value === value)?.label;
                  setSelectedCountry(value);
                  setFieldValue('shipping.warehouseAddress.country', country);
                }}
                disabled
              />
              <AppControlledSelect
                label="Province"
                name={'shipping.warehouseAddress.provinceCode'}
                options={states}
                onChange={(value) => {
                  const province = states.find((s) => s.value === value)?.label;
                  setFieldValue('shipping.warehouseAddress.province', province);
                }}
                onBlur={() => {
                  validateAddress(values.shipping.warehouseAddress);
                }}
              />
              <AppControlledTextField
                requiredIndicator
                label="Address"
                name={'shipping.warehouseAddress.address1'}
                validate={nonEmptyAddressFieldValidation}
                suffix={validatingAddress && <AppSpinner size="small" />}
                onBlur={() => {
                  validateAddress(values.shipping.warehouseAddress);
                }}
              />
              <AppControlledTextField
                label="Apartment, suite, etc."
                name={'shipping.warehouseAddress.address2'}
                onBlur={() => {
                  validateAddress(values.shipping.warehouseAddress);
                }}
              />
              <AppControlledTextField
                requiredIndicator
                label="City"
                name={'shipping.warehouseAddress.city'}
                validate={nonEmptyAddressFieldValidation}
                suffix={validatingAddress && <AppSpinner size="small" />}
                onBlur={() => {
                  validateAddress(values.shipping.warehouseAddress);
                }}
              />
              <AppControlledTextField
                requiredIndicator
                label="Zip code"
                name={'shipping.warehouseAddress.zip'}
                validate={nonEmptyAddressFieldValidation}
                suffix={validatingAddress && <AppSpinner size="small" />}
                onBlur={() => {
                  validateAddress(values.shipping.warehouseAddress);
                }}
              />
            </>
          )}
        </AppCard>
      </AppLayoutAnnotatedSection>
      <AppLayoutAnnotatedSection title="Shipping Cost">
        <AppCard sectioned title="Postage setup">
          <AppControlledRadioGroup optionsDesc={postageSetupOptions} name="shipping.postageSetup" />
        </AppCard>
        <AppCard sectioned title="Shipping mapping">
          <AppTextContainer>
            Select which shipping service levels you are able to offer
          </AppTextContainer>
          <br />
          {values.shipping.shippingTypesMapping.map((sTM, index) => (
            <div className="shipping-mapping">
              <div className="shipping-mapping-radio">
                <AppTextStyle variation="strong">{sTM.shippingType}</AppTextStyle>
                <AppControlledRadioGroup
                  onChange={(value) => {
                    if (value === AVAILABLE_RATES.FREE) {
                      if (!sTM.startDeliveryTime) {
                        setFieldValue(
                          `shipping.shippingTypesMapping[${index}].startDeliveryTime`,
                          getDefaultDays(sTM).startDeliveryTime.toString(),
                        );
                      }
                      if (!sTM.finishDeliveryTime) {
                        setFieldValue(
                          `shipping.shippingTypesMapping[${index}].finishDeliveryTime`,
                          getDefaultDays(sTM).finishDeliveryTime.toString(),
                        );
                      }
                    }
                  }}
                  optionsDesc={shippingMappingOptions.map((sMO) => {
                    switch (sMO.value) {
                      case AVAILABLE_RATES.FREE:
                        return {
                          ...sMO,
                          label: (
                            <AppTextStyle variation="strong">{`Free ${
                              sTM.shippingType.split(' ')[0]
                            } shipping`}</AppTextStyle>
                          ),
                          ...(sTM.availableRates === AVAILABLE_RATES.FREE && {
                            helpText: (
                              <div className="free-shipping-options">
                                <AppControlledTextField
                                  name={`shipping.shippingTypesMapping[${index}].startDeliveryTime`}
                                  placeholder={`${getDefaultDays(sTM).startDeliveryTime}`}
                                  validate={validation.isPositiveNumber}
                                  label="Fastest"
                                  suffix="d"
                                />
                                <div className="dash"></div>
                                <AppControlledTextField
                                  name={`shipping.shippingTypesMapping[${index}].finishDeliveryTime`}
                                  placeholder={`${getDefaultDays(sTM).finishDeliveryTime}`}
                                  validate={validation.isPositiveNumber}
                                  label="Slowest"
                                  suffix="d"
                                />
                                <AppControlledTextField
                                  name={`shipping.shippingTypesMapping[${index}].customShippingTypeName`}
                                  placeholder={`Free ${sTM.shippingType} shipping`}
                                  label="Custom name"
                                />
                              </div>
                            ),
                          }),
                        };
                      case AVAILABLE_RATES.FLAT_RATE:
                        return {
                          ...sMO,
                          ...(sTM.availableRates === AVAILABLE_RATES.FLAT_RATE && {
                            helpText: (
                              <div
                                className="narrow-text-field"
                                onClick={(e) => e.stopPropagation()}
                              >
                                <AppControlledTextField
                                  name={`shipping.shippingTypesMapping[${index}].flatRateCost`}
                                  prefix={'$'}
                                  placeholder={'0'}
                                  disabled={sTM.availableRates !== AVAILABLE_RATES.FLAT_RATE}
                                  validate={validation.isDecimal}
                                />
                              </div>
                            ),
                          }),
                        };
                      default:
                        return sMO;
                    }
                  })}
                  name={`shipping.shippingTypesMapping[${index}].availableRates`}
                />
              </div>
            </div>
          ))}
        </AppCard>
        <AppCard
          sectioned
          title={advancedMappingOptionsExpanded ? 'Advanced Mapping Settings' : ''}
        >
          <AppButton
            onClick={() => setAdvancedMappingOptionsExpanded(!advancedMappingOptionsExpanded)}
          >
            {advancedMappingOptionsExpanded ? 'Hide' : 'Show'} advanced Mapping Settings
          </AppButton>

          {advancedMappingOptionsExpanded && (
            <AppDataTable
              columnContentTypes={['text', 'text']}
              headings={['Crowdship carrier service', 'Custom service name']}
              rows={values.shipping.mapping.map((service, index) => [
                <AppControlledCheckbox
                  name={`shipping.mapping[${index}].selected`}
                  label={getCarrierServiceLabel(service.crowdshipCarrierServiceKey)}
                />,
                <AppControlledTextField
                  name={`shipping.mapping[${index}].customCarrierServiceName`}
                  placeholder={getCarrierServiceLabel(service.crowdshipCarrierServiceKey)}
                  label=""
                />,
              ])}
            />
          )}
        </AppCard>
      </AppLayoutAnnotatedSection>
      <AppLayoutAnnotatedSection title="Packing Slip Format">
        <AppCard sectioned title="Packing slip format">
          <AppTextContainer>
            Select the format and dimension you want to use for packing slips (this should match
            your packing slip printer’s ideal print size)
          </AppTextContainer>
          <AppControlledRadioGroup
            optionsDesc={[
              {
                value: PACKING_SLIP_FORMAT.A4,
                label: <AppTextStyle variation="strong">8.5 x 11 in.</AppTextStyle>,
              },
              {
                value: PACKING_SLIP_FORMAT['4x6'],
                label: <AppTextStyle variation="strong">4 x 6 in.</AppTextStyle>,
              },
            ]}
            name="shipping.packingSlipFormat"
          />
        </AppCard>
      </AppLayoutAnnotatedSection>
      <AppLayoutAnnotatedSection title="Handling Fee">
        <AppCard sectioned title="Handling Fee">
          <AppTextContainer>Add a "per order" fee to each Purchase Order</AppTextContainer>
          <AppControlledTextField
            name="shipping.handlingFee"
            prefix="$"
            validate={validation.isDecimal}
          />
          <AppTextContainer>
            <AppTextStyle variation="subdued">
              Note: if enabled, this will create a product in your Shopify store to include the fee
              cost in your future Shopify orders.
            </AppTextStyle>
          </AppTextContainer>
        </AppCard>
      </AppLayoutAnnotatedSection>
    </>
  );
};
