import React from 'react';
import { getLocaleQuantity } from '../../helpers/quantity.helper';
import { ReactComponent as InfinitySymbol } from '../../images/infinity.svg';

interface ITrackedQuantityLabelForVariantProps {
  quantity: number;
  tracked?: boolean;
  inventoryPolicy?: 'deny' | 'continue';
}

export const TrackedQuantityLabelForVariant = ({
  quantity,
  tracked,
  inventoryPolicy,
}: ITrackedQuantityLabelForVariantProps) => {
  switch (true) {
    case tracked === false:
      return <InfinitySymbol />;
    case quantity > 0:
      return (
        <div>
          <div>{getLocaleQuantity(quantity)}</div>
          {inventoryPolicy === 'continue' && <InfinitySymbol />}
        </div>
      );
    case quantity <= 0:
      return (
        <div>
          {(!inventoryPolicy || inventoryPolicy === 'deny') && (
            <div>{getLocaleQuantity(quantity)}</div>
          )}
          {inventoryPolicy === 'continue' && <InfinitySymbol />}
        </div>
      );
    default:
      return <>{getLocaleQuantity(quantity || 0)}</>;
  }
};
