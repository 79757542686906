import { ReactNode, useMemo, useState } from 'react';
import { AppTabs } from '../../../../core/components/tabs/tabs';
import { ILocation } from '../../../interfaces/ILocation';
import {
  IAddress,
  IOrderDetailsProduct,
  IOrderDetailsTotals,
  IRetailerOrderDetails,
} from '../../../interfaces/IOrder';
import { OrderDetails } from './order-details';
import { OrderLabels } from './order-labels';

type IOrderDetailsWithTabsProps = {
  retailerOrderName: string;
  retailer: IRetailerOrderDetails;
  orderId: number;
  createdAt: string;
  status: string;
  products: IOrderDetailsProduct[];
  totals: IOrderDetailsTotals;
  onAcceptCancellationRequest: () => void;
  onDeclineCancellationRequest: () => void;
  locations: ILocation[];
  shippingAddress: IAddress;
  requestedShipping?: string;
};

export const OrderDetailsWithTabs = (props: IOrderDetailsWithTabsProps) => {
  const [selectedTab, setSelectedTab] = useState<number>(0);
  const tabContent: { [key: number]: ReactNode } = useMemo(
    () => ({
      0: <OrderDetails {...props} />,
      1: <OrderLabels {...props} />,
    }),
    [props],
  );

  return (
    <AppTabs
      tabs={[
        { id: 'details-view', content: 'View details' },
        { id: 'labels-view', content: 'Manage labels' },
      ]}
      selected={selectedTab}
      onSelect={setSelectedTab}
    >
      {tabContent[selectedTab]}
    </AppTabs>
  );
};
