import { createAction } from 'typesafe-actions';
import { ACTIONS_CONSTANTS } from '../../actions-constants';
import { ISupplierStatus } from '../../../api/status.api';

export const getStatusAction = createAction(ACTIONS_CONSTANTS.GET_STATUS)();

export const setStatusAction = createAction(
  ACTIONS_CONSTANTS.SET_STATUS,
  (info: ISupplierStatus) => info,
)();

export const setUpdatingPriceMappingPreferenceAction = createAction(
  ACTIONS_CONSTANTS.SET_UPDATING_PRICE_MAPPING,
  (updating: boolean) => updating,
)();
