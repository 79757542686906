import { AxiosResponse } from 'axios';
import { axiosInstance } from '../../core/api/base.api';
import { IPayout, IPendingConfirmation } from '../../shopify-supplier/interfaces/IPayout';
import {
  IFeeInvoice,
  IInvoiceWithOrders,
  InvoiceList,
  IOrderInvoice,
} from '../interfaces/IInvoice';

class InvoiceApi {
  private backend = process.env.REACT_APP_BACK_END_URL as string;
  private rootPath = '/api/retailer/invoices';

  async getInvoiceDetails(invoiceId: string) {
    return await axiosInstance.get<IInvoiceWithOrders>(
      `${this.backend}${this.rootPath}/${invoiceId}`,
    );
  }

  async getInvoicePdf(invoiceId: string) {
    return await axiosInstance.get<any>(
      `${this.backend}${this.rootPath}/${invoiceId}/invoice-pdf`,
      {
        responseType: 'blob',
      },
    );
  }

  async getInvoicePdfUrl(invoiceId: string) {
    return await axiosInstance.get<any>(
      `${this.backend}${this.rootPath}/${invoiceId}/invoice-pdf-url`,
    );
  }

  async getCreditPdfUrl(invoiceId: string) {
    return await axiosInstance.get<any>(`${this.backend}${this.rootPath}/credit-pdf/${invoiceId}`);
  }

  async createPayment(invoiceId: string, payment: IPendingConfirmation) {
    return await axiosInstance.post<null>(
      `${this.backend}${this.rootPath}/${invoiceId}/payment`,
      payment,
    );
  }

  async getPaidOrderInvoices(filters: IGetPaidOrdersInvoicesParams) {
    const { limit, page, dateRange, title, suppliers } = filters;
    return await axiosInstance.get<InvoiceList<IOrderInvoice>>(
      `${this.backend}${this.rootPath}/paid`,
      {
        params: {
          limit,
          page,
          title,
          suppliers: suppliers?.join(),
          startDate: dateRange?.start,
          endDate: dateRange?.end,
        },
      },
    );
  }

  async getPaidFeeInvoices(filters: IGetInvoicesFilters) {
    const { limit, page, dateRange } = filters;
    return await axiosInstance.get<InvoiceList<IFeeInvoice>>(
      `${this.backend}${this.rootPath}/fees`,
      {
        params: { limit, page, startDate: dateRange?.start, endDate: dateRange?.end },
      },
    );
  }

  async getUnpaidBatches(filters: IGetUnpaidPayoutsFilters) {
    const { limit, page, supplierId, dateRange } = filters;
    return await axiosInstance.get<{ payouts: PayoutDisplayInfo[]; total: number }>(
      `${this.backend}${this.rootPath}/unpaid`,
      {
        params: { limit, page, supplierId, startDate: dateRange?.start, endDate: dateRange?.end },
      },
    );
  }

  async getSuppliersForUnpaidBatches() {
    return await axiosInstance.get<{ label: string; value: string }[]>(
      `${this.backend}${this.rootPath}/unpaid/suppliers`,
    );
  }

  async getSuppliersForPaymentHistory() {
    return await axiosInstance.get<{ label: string; value: string }[]>(
      `${this.backend}${this.rootPath}/paid/suppliers`,
    );
  }

  async getUnpaidOrdersSummary() {
    return await axiosInstance.get<IGetUnpaidBatchesSummaryResult>(
      `${this.backend}${this.rootPath}/unpaid/summary`,
    );
  }

  async exportInvoicesCSV(body: { invoicesIds: string[] }): Promise<AxiosResponse<any>> {
    return await axiosInstance.post(`${this.backend}${this.rootPath}/paid`, body, {
      responseType: 'blob',
    });
  }

  async exportUnpaidInvoice(id: string): Promise<AxiosResponse<any>> {
    return await axiosInstance.post(
      `${this.backend}${this.rootPath}/unpaid`,
      { payoutId: id },
      {
        responseType: 'blob',
      },
    );
  }

  async exportAllInvoices(filter: Omit<IGetPaidOrdersInvoicesParams, 'limit' | 'page'>) {
    const { title, suppliers, dateRange } = filter;
    return await axiosInstance.post(
      `${this.backend}${this.rootPath}/paid/all`,
      {
        title,
        suppliers: suppliers?.length ? suppliers : undefined,
        startDate: dateRange?.start,
        endDate: dateRange?.end,
      },
      {
        responseType: 'blob',
      },
    );
  }
}

export const invoiceApi = new InvoiceApi();

interface IGetInvoicesFilters {
  limit: number;
  page: number;
  dateRange?: {
    start: string;
    end: string;
  };
}

export interface IGetUnpaidPayoutsFilters {
  limit: number;
  page: number;
  supplierId?: string;
  dateRange?: {
    start: number;
    end: number;
  };
}

export interface IGetPaidOrdersInvoicesParams extends IGetInvoicesFilters {
  title?: string;
  suppliers?: string[];
}

export type PayoutDisplayInfo = Pick<
  IPayout,
  | '_id'
  | 'createdAt'
  | 'updatedAt'
  | 'amount'
  | 'confirmationId'
  | 'adjustmentAmount'
  | 'adjustmentReason'
  | 'status'
  | 'supplierId'
  | 'ordersCount'
  | 'orderIds'
  | 'name'
> & { supplierName: string; lineTotal: number; shippingTotal: number; handlingFeeTotal: number };

export interface IGetUnpaidBatchesSummaryResult {
  totalBatches: number;
  totalNotPaidOut: number;
}
