import { Navigation } from '@shopify/polaris';
import {
  CustomersMajor,
  HomeMajor,
  OrdersMajor,
  ProductsMajor,
  ReceiptMajor,
  SettingsMajor,
  StoreMajor,
  TeamMajor,
  ToolsMajor,
} from '@shopify/polaris-icons';
import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { ACCOUNT_ROLE } from '../../../../core/redux/modules/auth/auth.reducer';
import { getRolesSelector } from '../../../redux/modules/auth/auth.selectors';
import { ROUTES } from '../../../router/routes';

export const Sidebar = () => {
  const location = useLocation();
  const roles = useSelector(getRolesSelector);

  return (
    <Navigation location={location.pathname}>
      <Navigation.Section
        items={[
          {
            url: ROUTES.ROOT,
            exactMatch: true,
            label: 'Dashboard',
            icon: HomeMajor,
          },
          {
            url: ROUTES.MARKETPLACE,
            label: 'Marketplace',
            icon: StoreMajor,
          },
          {
            url: ROUTES.IMPORT_LIST,
            label: 'Import List',
            icon: StoreMajor,
          },
          {
            url: ROUTES.INVENTORY,
            label: 'My products',
            icon: ProductsMajor,
          },
          {
            url: ROUTES.ORDERS,
            label: 'Orders',
            icon: OrdersMajor,
          },
          {
            url: ROUTES.ACCOUNTING,
            label: 'Accounting',
            icon: ReceiptMajor,
          },
          {
            url: ROUTES.SETTINGS,
            label: 'Settings',
            icon: SettingsMajor,
          },
          {
            url: ROUTES.ACCOUNT,
            label: 'Account',
            icon: CustomersMajor,
          },
          {
            url: ROUTES.INTEGRATIONS,
            label: 'Integrations',
            icon: TeamMajor,
          },
          ...(roles.includes(ACCOUNT_ROLE.ADMIN)
            ? [{ url: ROUTES.ADMIN_TOOLS, label: 'Admin tools', icon: ToolsMajor }]
            : []),
        ]}
      />
    </Navigation>
  );
};
