import { PageDownMajor } from '@shopify/polaris-icons';
import React, { useCallback } from 'react';
import { AppDataTable } from '../../../../core/components/data-table/DataTable';
import { DateTooltip } from '../../../../core/components/date-tooltip/date-tooltip';
import { AppIcon } from '../../../../core/components/icon/icon';
import { AppLink } from '../../../../core/components/link/link';
import { AppTooltip } from '../../../../core/components/overlays/tooltip/tooltip';
import { withCurrency } from '../../../../core/helpers/currency.helper';
import { invoiceApi } from '../../../api/invoice.api';
import { IFeeInvoice } from '../../../interfaces/IInvoice';

type FeeInvoiceListProps = {
  invoices: IFeeInvoice[];
};

export const FeeInvoiceList = ({ invoices }: FeeInvoiceListProps) => {
  const handleDownloadPdf = useCallback((invoiceId: string) => {
    invoiceApi.getInvoicePdfUrl(invoiceId).then(({ data }) => window.open(data.url));
  }, []);

  return (
    <AppDataTable
      columnContentTypes={['text', 'text', 'text', 'numeric']}
      headings={['ID', 'Date', 'Purchase orders', 'Amount paid']}
      rows={invoices.map((i) => [
        i.name,
        <DateTooltip date={i.paidAt} />,
        `Includes ${i.purchaseOrdersCount} purchase order${i.purchaseOrdersCount > 1 ? 's' : ''}`,
        <div className="amount">
          <div>{withCurrency(i.amountPaid)}</div>
          <div className="icon-wrapper">
            <AppTooltip content="Download invoice PDF" dismissOnMouseOut>
              <AppLink onClick={() => handleDownloadPdf(i.name)}>
                <AppIcon source={PageDownMajor} />
              </AppLink>
            </AppTooltip>
          </div>
        </div>,
      ])}
    />
  );
};
