import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getStatusSelector } from '../../../redux/modules/status/status.selectors';
import { AccountSuspensionBanner } from '../account-suspension-banner/account-suspension-banner';
import { ChargeConfirmationBanner } from '../charge-confirmation-banner/charge-confirmation-banner';
import { InstallationFlowBanner } from '../installation-flow-banner/installation-flow-banner';
import { LimitsStatusBanner } from '../limits-status-banner/limits-status-banner';
import { MigratingToShopifyBillingBanner } from '../migrating-to-shopify-billing-banner/migrating-to-shopify-billing-banner';
import { NoValidCreditCardBanner } from '../no-valid-credit-card-banner/no-valid-credit-card-banner';
import { OrderPaymentFailedBanner } from '../order-payment-failed-banner/order-payment-failed-banner';
import { PromotionBanner } from '../promotion-banner/promotion-banner';

export function AppBanners() {
  const status = useSelector(getStatusSelector);
  const installationSteps = useMemo(
    () =>
      status
        ? {
            setupAccount: status.installationSteps.accountInfoSetUp,
            reviewPreferences: status.installationSteps.preferencesReviewed,
            importProducts: !!status.productsCount,
          }
        : null,
    [status],
  );
  const show = useMemo(
    () =>
      !(
        installationSteps &&
        installationSteps.importProducts &&
        installationSteps.reviewPreferences &&
        installationSteps.setupAccount
      ),
    [installationSteps],
  );

  if (!installationSteps || !show) {
    return (
      <>
        <LimitsStatusBanner status={status} />
        <NoValidCreditCardBanner status={status} />
        <MigratingToShopifyBillingBanner status={status} />
        <AccountSuspensionBanner status={status} />
        <OrderPaymentFailedBanner status={status} />
        <ChargeConfirmationBanner status={status} />
        <PromotionBanner status={status} />
        {/** Temporary disabled sign up wizard since we do not have any unfilled required fields in new
         * customers account records. Needed to be disabled since this messes up the installation flow.
         * A GET request is sent to /account and at the moment this triggers the account to be marked
         * as set. And the setup wizard is never shown because of that.
         */}
        {/* <SignUpWizard show={!!(status && !status.installationSteps.accountInfoSetUp)} /> */}
      </>
    );
  }

  return (
    <>
      <InstallationFlowBanner installationSteps={installationSteps} />
    </>
  );
}
