import { CirclePlusMajor } from '@shopify/polaris-icons';
import { FieldArray } from 'formik';
import React, { useCallback, useMemo, useState } from 'react';
import { AppButton } from '../../../../../core/components/button/Button';
import { AppDataTable } from '../../../../../core/components/data-table/DataTable';
import { AppBanner } from '../../../../../core/components/feedback-indicators/banner/banner';
import { AppSpinner } from '../../../../../core/components/feedback-indicators/spinner/spinner';
import { AppControlledAutocompleteTextField } from '../../../../../core/components/forms/controlled-autocomplete-text-field/controlled-autocomplete-text-field';
import { AppControlledCheckbox } from '../../../../../core/components/forms/controlled-checkbox/controlled-checkbox';
import { AppControlledChoiceList } from '../../../../../core/components/forms/controlled-choice-list/controlled-choice-list';
import { AppControlledSelect } from '../../../../../core/components/forms/controlled-select/controlled-select';
import { AppControlledTextField } from '../../../../../core/components/forms/controlled-text-field/controlled-text-field';
import { AppCard } from '../../../../../core/components/structure/card/card';
import { AppLayoutAnnotatedSection } from '../../../../../core/components/structure/layout/layout-annotated-section';
import { AppTextContainer } from '../../../../../core/components/text-container/text-container';
import { AppTextStyle } from '../../../../../core/components/text/text-style/TextStyle';
import { validation } from '../../../../../core/helpers/validations.helper';
import { preferencesApi } from '../../../../api/preferences.api';
import { estimateOptions } from '../../../../constants/preferences.constants';
import { IShippingPreferences } from '../../../../interfaces/IPreferences';

type ShippingSectionProps = {
  unsavedPreferences: IShippingPreferences;
  carrierServices: string[];
  setFieldValue: (field: string, value: any) => void;
};

export const ShippingSection = ({
  unsavedPreferences,
  carrierServices,
  setFieldValue,
}: ShippingSectionProps) => {
  const [validatingToken, setValidatingToken] = useState(false);
  /**
   * Formik has issues with validating only onBlur. Formik always validates all fields when any of them have changed.
   * This is a disaster for async validations using requests to 3rd-party services. We would've ended up with 429 errors from Shippo.
   * In this solution we validate only onBlur and store the result cached so we don't have to do multiple requests each time Formik wants to validate it.
   * */
  const [cachedTokenValidation, setCachedTokenValidation] = useState<Promise<any> | undefined>(
    undefined,
  );

  const autoCompleteOptions = useMemo(
    () => carrierServices.map((cs) => ({ value: cs, label: cs })),
    [carrierServices],
  );

  const renderShippingMappingForm = useCallback(
    (unsavedPreferences: IShippingPreferences) => (
      <FieldArray
        name="preferences.shipping.mapping"
        render={(arrayHelpers) => (
          <AppDataTable
            columnContentTypes={['text', 'text', 'text']}
            headings={['Your carrier service', 'Crowdship carrier service', '']}
            rows={unsavedPreferences.mapping.map((mapping, index) => [
              <AppControlledAutocompleteTextField
                options={autoCompleteOptions.filter(
                  (opt) =>
                    !unsavedPreferences.mapping.some(
                      (mp) => mp.customCarrierServiceName.trim() === opt.label.trim(),
                    ),
                )}
                name={`preferences.shipping.mapping[${index}].customCarrierServiceName`}
                validate={(value) =>
                  validation.isUnique(
                    value,
                    unsavedPreferences.mapping.map((mp) => mp.customCarrierServiceName),
                  ) || validation.isNotEmpty(value)
                }
                disabled={!mapping.custom}
              />,
              <div className="width-control">
                <AppControlledSelect
                  name={`preferences.shipping.mapping[${index}].crowdshipCarrierServiceKey`}
                  options={estimateOptions}
                />
              </div>,
              mapping.custom && (
                <AppButton onClick={() => arrayHelpers.remove(index)} plain destructive>
                  Remove
                </AppButton>
              ),
            ])}
            footerContent={
              <AppButton
                icon={CirclePlusMajor}
                onClick={() =>
                  arrayHelpers.push({
                    customCarrierServiceName: '',
                    crowdshipCarrierServiceKey: 'cheapest',
                    specified: false,
                    custom: true,
                  })
                }
              >
                Add carrier service
              </AppButton>
            }
          />
        )}
      />
    ),
    [autoCompleteOptions],
  );

  const validateShippoToken = useCallback(
    (token: string | undefined) => {
      if (!token) {
        setFieldValue('preferences.shipping.shippo.isValid', true);
        setCachedTokenValidation(undefined);
        return;
      }
      setValidatingToken(true);
      let errorMessage: string | undefined;
      const validating = new Promise<string | undefined>((resolve, reject) => {
        preferencesApi
          .validateShippoToken(token)
          .then(({ data }) => {
            if (!data.valid) {
              errorMessage = 'Invalid token';
            }
            resolve(errorMessage);
          })
          .catch((error) => {
            console.error(error);
            reject(error);
          })
          .finally(() => {
            setFieldValue('preferences.shipping.shippo.isValid', true);
            setValidatingToken(false);
          });
      });

      setCachedTokenValidation(validating);
    },
    [setCachedTokenValidation, setFieldValue],
  );

  return (
    <AppLayoutAnnotatedSection title="Shipping">
      <AppCard sectioned title="Shipping mapping">
        {renderShippingMappingForm(unsavedPreferences)}
      </AppCard>
      <AppCard sectioned title="Shipping Cost Threshold">
        <AppControlledCheckbox
          name="preferences.shipping.takeIntoAccountEndClientServiceChoice"
          label={
            <>
              <label>Allow shipping cost to exceed shipping fees collected by this amount</label>
              <div className="narrow-text-field separated" onClick={(e) => e.stopPropagation()}>
                <AppControlledTextField
                  label="Shipping cost threshold"
                  name="preferences.shipping.shippingCostThreshold"
                  suffix="%"
                  type="number"
                  placeholder="0"
                  disabled={!unsavedPreferences.takeIntoAccountEndClientServiceChoice}
                />
              </div>
            </>
          }
        />
      </AppCard>
      <AppCard sectioned title="International shipping">
        <AppControlledCheckbox
          name="preferences.shipping.internationalShipping"
          label="I would like to see suppliers and their products if they are located in a different country"
        />
      </AppCard>
      <AppCard sectioned title="Shippo account token">
        {unsavedPreferences.shippo?.isValid === false && (
          <AppBanner status="warning">
            The token has become invalid and cannot be used for label purchases. Please, update your
            token
          </AppBanner>
        )}
        <AppControlledTextField
          name="preferences.shipping.shippo.token"
          label="You can provide a token so the suppliers can buy postages for your orders using your Shippo account when possible"
          helpText="Otherwise, we will charge your credit card for an estimated shipment value. The supplier will not be able to buy postages via Shippo if he provides free shipping for this product and if the order includes international shipments"
          suffix={validatingToken && <AppSpinner size="small" />}
          onBlur={() => {
            validateShippoToken(unsavedPreferences.shippo?.token);
          }}
          validate={() => cachedTokenValidation}
        />
      </AppCard>
      <AppCard sectioned title="Customs declaration">
        <div className="customs-declaration-section">
          <AppTextContainer>
            <p>
              <AppTextStyle variation="strong"> Note: </AppTextStyle>Please ensure your phone number
              is filled in the "Company" section. Otherwise, we will not be able to provide
              international shipping labels.
            </p>
          </AppTextContainer>
          {!unsavedPreferences.customsDeclaration.certifySigner && (
            <AppBanner status="warning">
              International shipping labels will not be purchased via Crowdship if the Customs
              Declaration preference is not filled
            </AppBanner>
          )}
          <AppControlledTextField
            name="preferences.shipping.customsDeclaration.certifySigner"
            label="Certify signer"
          />
          <AppControlledChoiceList
            title="EEL_PFC"
            name="preferences.shipping.customsDeclaration.eelPfc"
            choices={[
              { value: 'NOEEI_30_37_a', label: 'NOEEI_30_37_a' },
              { value: 'NOEEI_30_37_h', label: 'NOEEI_30_37_h' },
              { value: 'NOEEI_30_36', label: 'NOEEI_30_36' },
              {
                value: 'AES_ITN',
                label: 'AES_ITN',
                helpText: unsavedPreferences.customsDeclaration.eelPfc === 'AES_ITN' && (
                  <AppControlledTextField
                    name="preferences.shipping.customsDeclaration.aesItn"
                    validate={validation.isNotEmpty}
                  />
                ),
              },
            ]}
          />
          <AppControlledChoiceList
            title="Incoterm"
            name="preferences.shipping.customsDeclaration.incoterm"
            choices={[
              { value: 'DDP', label: 'DDP' },
              { value: 'DDU', label: 'DDU' },
            ]}
          />
          <AppControlledChoiceList
            title="Non-delivery option"
            name="preferences.shipping.customsDeclaration.nonDelivery"
            choices={[
              { value: 'RETURN', label: 'Return' },
              { value: 'ABANDON', label: 'Abandon' },
            ]}
          />
          <AppControlledTextField
            name="preferences.shipping.customsDeclaration.customItemName"
            label="Custom item name (optional)"
            helpText="Here you can set a static name for all items in the customs declaration if you don't want the item's title to be used"
          />
        </div>
      </AppCard>
    </AppLayoutAnnotatedSection>
  );
};
