import { Icon } from '@shopify/polaris';
import { ShipmentMajor } from '@shopify/polaris-icons';
import { IMarketplaceProduct } from '../../../../interfaces/IProduct';
import { AppThumbnail } from '../../../image-containers/thumbnail/Thumbnail';
import { AppIndexTableCell } from '../../../index-table/index-table-cell';
import { AppIndexTableRow } from '../../../index-table/index-table-row';
import { AppTextStyle } from '../../../text/text-style/TextStyle';
import { ProductRowBaseProps } from '../../product-list.interfaces';
import { AverageMargin } from '../average-margin';
import { ExpandVariantsBtn } from '../expand-variants-btn';
import { LinkToDetails } from '../link-to-details';
import { TrackedQuantityLabelForProduct } from '../../../tracked-quantity-label-for-product/tracked-quantity-label-for-product';
import { AppBadge } from '../../../badge/badge';

type MarketplaceProductRowProps = ProductRowBaseProps<IMarketplaceProduct> & {};

export const MarketplaceProductRow = ({
  product,
  selected,
  index,
  expandedVariants,
  toggleExpansion,
}: MarketplaceProductRowProps) => {
  const {
    id,
    title,
    image,
    productType,
    vendor,
    quantityAvailable,
    variationsNumber,
    averageMargin,
    supplierName,
    connectedProducts,
    freeShipping,
    variants,
  } = product;
  return (
    <AppIndexTableRow id={id} key={id} selected={selected} position={index}>
      <AppIndexTableCell>
        <div className="product-image">
          <AppThumbnail source={image} alt={title} />
        </div>
      </AppIndexTableCell>
      <AppIndexTableCell>
        <div className="product-title">
          <AppTextStyle variation="subdued">{vendor}</AppTextStyle>
          <AppTextStyle variation="strong">{title}</AppTextStyle>
          <LinkToDetails id={id} title={title} visible={expandedVariants} page={'marketplace'} />
        </div>
      </AppIndexTableCell>
      <AppIndexTableCell>{productType}</AppIndexTableCell>
      <AppIndexTableCell>
        <div className="product-supplier">
          {supplierName} {freeShipping && <Icon source={ShipmentMajor} color="primary" />}
        </div>
      </AppIndexTableCell>
      <AppIndexTableCell>
        <TrackedQuantityLabelForProduct quantity={quantityAvailable} variants={variants} />
      </AppIndexTableCell>
      <AppIndexTableCell>
        <div className="seller-wrapper">
          <AverageMargin margin={averageMargin} />
        </div>
      </AppIndexTableCell>
      <AppIndexTableCell>
        {!!connectedProducts ? (
          <AppBadge status="success">Connected</AppBadge>
        ) : (
          <AppBadge status="new" size="small">
            None
          </AppBadge>
        )}
      </AppIndexTableCell>
      <AppIndexTableCell>
        <ExpandVariantsBtn
          variantsCount={variationsNumber}
          expanded={expandedVariants}
          toggleExpansion={toggleExpansion}
        />
      </AppIndexTableCell>
    </AppIndexTableRow>
  );
};
