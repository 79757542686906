import React, { ReactNode, useEffect } from 'react';
import { SelectionType } from '@shopify/polaris/build/ts/latest/src/utilities/index-provider';
import { AppButton } from '../../../../core/components/button/Button';
import { AppIndexTable } from '../../../../core/components/index-table/index-table';

import { PageAwarePagination } from '../../../../core/components/pagination/page-aware-pagination';
import { IAction } from '../../../../core/components/resource-list/ResourceItem';

import { ICurrency } from '../../../../core/interfaces/ICurrency';
import { ISupplierOrder } from '../../../interfaces/IOrder';
import { BaseOrderListRows } from '../base-order-list-rows/base-order-list-rows';

interface IOrderListProps {
  orders: ISupplierOrder[];
  fetching: boolean;
  totalPageCount: number;
  currency: ICurrency;
  onSelectionChange?: (values: string[]) => void;
  allResourcesSelected?: boolean;
  selectedResources?: string[];
  handleSelectionChange?: (
    selectionType: SelectionType,
    isSelecting: boolean,
    selection?: any,
  ) => void;
  listActions?: IAction[];
  selectable?: boolean;
  handleExportCSV?: () => void;
  appFilters?: ReactNode;
  searchString?: string;
  onPageChange: (page: number) => void;
}

export const OrderList = ({
  orders,
  fetching,
  totalPageCount,
  currency,
  onSelectionChange,
  allResourcesSelected,
  selectedResources,
  handleSelectionChange,
  listActions,
  selectable,
  handleExportCSV,
  appFilters,
  onPageChange,
  searchString,
}: IOrderListProps) => {
  useEffect(() => {
    onSelectionChange && selectedResources && onSelectionChange(selectedResources);
  }, [selectedResources, onSelectionChange]);

  return (
    <div>
      {handleExportCSV && (
        <div style={{ paddingLeft: '16px', width: 'fit-content' }}>
          <AppButton
            handleClick={(e) => {
              e.stopPropagation();
              handleExportCSV();
            }}
          >
            Export CSV
          </AppButton>
        </div>
      )}
      {appFilters || null}
      <AppIndexTable
        resourceName={{ singular: 'order', plural: 'orders' }}
        headings={[
          { title: 'Order' },
          { title: 'Date' },
          { title: 'Retailer' },
          { title: 'My Total' },
          { title: 'Items Count' },
          { title: 'Status' },
        ]}
        itemCount={orders.length}
        loading={fetching}
        hasMoreItems={totalPageCount > 1}
        promotedBulkActions={listActions?.map((la) => ({
          ...la,
          onAction: () => {
            la.onAction && la.onAction();
          },
        }))}
        selectedItemsCount={allResourcesSelected ? 'All' : selectedResources?.length}
        onSelectionChange={handleSelectionChange}
        selectable={selectable}
      >
        <BaseOrderListRows
          orders={orders}
          currency={currency}
          selectedResources={selectedResources}
          searchString={searchString}
        />
      </AppIndexTable>
      <PageAwarePagination totalPageCount={totalPageCount} onPageChange={onPageChange} />
    </div>
  );
};
