import { INotificationSources } from '../../core/interfaces/INotificationSources';

export interface IPreferences {
  profile: IProfilePreferences;
  product: IProductPreferences;
  order: IOrderPreferences;
  notifications: INotificationsPreferences;
  shipping: IShippingPreferences;
}

export interface IProfilePreferences {
  logo?: string;
}

export interface ICustomsDeclarationPreference {
  certifySigner: string;
  eelPfc: 'NOEEI_30_37_a' | 'NOEEI_30_37_h' | 'NOEEI_30_36' | 'AES_ITN';
  incoterm: 'DDP' | 'DDU';
  nonDelivery: 'ABANDON' | 'RETURN';
  aesItn?: string;
  customItemName?: string;
}

export interface IShippingPreferences {
  internationalShipping: boolean;
  mapping: IShippingMappingPreference[];
  takeIntoAccountEndClientServiceChoice: boolean;
  shippingCostThreshold?: string;
  shippo?: {
    token: string;
    // The token could be valid when initially set but become invalid later
    isValid: boolean;
  };
  customsDeclaration: ICustomsDeclarationPreference;
}

export interface IShippingMappingPreference {
  customCarrierServiceName: string;
  crowdshipCarrierServiceKey: string;
  specified: boolean;
  custom: boolean;
}
export interface IProductUpdateFields {
  // when `all` is enabled, the other options are ignored
  all: boolean;

  title: boolean;
  description: boolean;
  tags: boolean;
  vendor: boolean;
  productType: boolean;
  images: boolean;
  variants: boolean;

  // Variant fields
  sku: boolean;
  barcode: boolean;
  weight: boolean;
  image: boolean;
}

export interface IProductPreferences {
  importedOrConnectedProductStatusOption: IMPORTED_OR_CONNECTED_PRODUCT_STATUS_OPTION;
  discontinuationOption: PRODUCT_DISCONTINUATION_OPTION;
  updateOption: PRODUCT_UPDATE_OPTION;
  updateFields: IProductUpdateFields;
  defaultMarkup: string;
  salePriceOption: SALE_PRICE_OPTION;
  compareAtPriceOption: COMPARE_AT_PRICE_OPTION;
  compareAtPriceMarkup?: string;
  addTag: boolean;
  customTag: string;
}

export enum IMPORTED_OR_CONNECTED_PRODUCT_STATUS_OPTION {
  ACTIVE = 'active',
  DRAFT = 'draft',
}

export enum SALE_PRICE_OPTION {
  MSRP = 'MSRP',
  DROP_SHIP_COST_WITH_MARKUP = 'drop_ship_cost_with_markup',
}

export enum COMPARE_AT_PRICE_OPTION {
  NOT_SET = 'not_set',
  MSRP = 'MSRP',
  MARK_UP_FROM_SALE_PRICE = 'markup_from_sale_price',
}

export enum PRODUCT_DISCONTINUATION_OPTION {
  DELETE = 'delete',
  UNPUBLISH = 'unpublish',
  IGNORE = 'ignore',
}

export enum PRODUCT_UPDATE_OPTION {
  UPDATE = 'update',
  IGNORE = 'ignore',
}

export enum PRODUCT_REMOVE_FROM_CROWDSHIP_OPTION {
  DELETE = 'delete',
  LEAVE = 'leave',
}

export enum ORDER_PROCESSING_OPTION {
  ON_PAID = 'onPaid',
  ON_FULFILLMENT_REQUEST = 'onFulfillmentRequest',
}

export interface IOrderPreferences {
  notifyCustomer: 'true' | 'false';
  lineItemsCancellationOption: LINE_ITEMS_CANCELLATION_OPTION;
  requestLineItemsCancellationOption: LINE_ITEMS_CANCELLATION_OPTION;
  addressValidation: 'true' | 'false';
  orderProcessing: ORDER_PROCESSING_OPTION;
}

export enum LINE_ITEMS_CANCELLATION_OPTION {
  REFUND = 'refund',
  REMOVE = 'remove', // without refunding
  IGNORE = 'ignore',
}

export interface INotificationsPreferences {
  sellProduct: INotificationSources;
  dropShipCostChanges: INotificationSources;
  orderFulfillment: INotificationSources;
  newBrand: INotificationSources;
}
