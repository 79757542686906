import React, { useCallback, useEffect, useMemo, useState } from 'react';
import queryString from 'query-string';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { RefundsDetailsPage } from '../../../../core/components/refunds/refunds-details-page';
import { AppCard } from '../../../../core/components/structure/card/card';
import { AppPage } from '../../../../core/components/structure/page/page';
import { AppToast } from '../../../../core/components/feedback-indicators/toast/toast';
import { AppBanner } from '../../../../core/components/feedback-indicators/banner/banner';
import { ORDERS_PER_PAGE } from '../../../../core/constants/orders';
import { IGetOrdersQueryParams, ordersApi } from '../../../api/orders.api';
import { getOrdersListAction } from '../../../redux/modules/orders/orders.actions';
import {
  getOrdersCountSelector,
  getOrdersListFetchingSelector,
  getOrdersListSelector,
} from '../../../redux/modules/orders/orders.selectors';
import { getStatusSelector } from '../../../redux/modules/status/status.selectors';
import { OrderListWithTabs } from '../../containers/order-list/order-list-with-tabs';
import { OrdersSummaryComponent } from '../../containers/orders-summary/orders-summary';
import { OrderDetailsLayout } from '../order-details-layout/order-details-layout';
import { LoadingWrapper } from '../../../../core/components/loading-wrapper/loading-wrapper';

import './orders-layout.scss';

export const OrdersLayout: React.FunctionComponent = () => {
  const dispatch = useDispatch();
  const { search: query } = useLocation();
  const history = useHistory();
  const {
    statuses: URLStatuses,
    query: searchQueryString,
    page,
    limit,
  } = queryString.parse(query, {
    arrayFormat: 'separator',
    arrayFormatSeparator: '.',
  });
  const orders = useSelector(getOrdersListSelector);
  const { currency } = useSelector(getStatusSelector);
  const fetching = useSelector(getOrdersListFetchingSelector);
  const ordersNumber = useSelector(getOrdersCountSelector);
  const [isOrderDetails, setIsOrderDetails] = useState(false);
  const [isRefundDetails, setIsRefundDetails] = useState(false);
  const [orderId, setOrderId] = useState<string | undefined>();
  const [calculatingLabels, setCalculatingLabels] = useState(false);
  const [error, setError] = useState('');
  const [noLabels, setNoLabels] = useState(false);

  const [filterParams, setFilterParams] = useState<IGetOrdersQueryParams>({
    page: +(page as string) || 0,
    limit: +(limit as string) || ORDERS_PER_PAGE,
    query: (searchQueryString as string) || '',
    statuses: !!URLStatuses
      ? Array.isArray(URLStatuses) && URLStatuses.length
        ? (URLStatuses as string[]).map((s) => s.replace('-', ' '))
        : [(URLStatuses as string).replace('-', ' ')]
      : [],
  });

  useEffect(() => {
    const parsedQuery = new URLSearchParams(query);
    const id = parsedQuery.get('id');
    const invoiceItemId = parsedQuery.get('invoiceItemId');

    if (id) {
      setOrderId(id);
      setIsOrderDetails(true);
    }

    if (invoiceItemId) {
      setOrderId(invoiceItemId);
      setIsRefundDetails(true);
    }
  }, [history, query]);

  useEffect(() => {
    dispatch(getOrdersListAction(filterParams));
  }, [dispatch, filterParams]);

  const handleFilterUpdate = useCallback(
    (filter: IGetOrdersQueryParams) => {
      setFilterParams(filter);
      dispatch(getOrdersListAction(filter));
    },
    [dispatch],
  );

  const handleCalculateLabels = useCallback(
    (selectedOrders: string[]) => {
      setCalculatingLabels(true);
      ordersApi
        .calculateLabels({
          orderIds: selectedOrders,
        })
        .then(({ data }) => {
          if ('success' in data && data.success) {
            setCalculatingLabels(false);
            history.push('/shipments');
          }

          if ('noLabelCandidates' in data && data.noLabelCandidates) setNoLabels(true);
        })
        .catch(() => setError('Unexpected error.'))
        .finally(() => {
          setCalculatingLabels(false);
        });
    },
    [history],
  );

  const handleCalculateAllLabels = useCallback(() => {
    setCalculatingLabels(true);
    ordersApi
      .calculateAllLabels({
        statuses: filterParams.statuses,
        query: filterParams.query,
      })
      .then(({ data }) => {
        if ('success' in data && data.success) {
          setCalculatingLabels(false);
          history.push('/shipments');
        }

        if ('noLabelCandidates' in data && data.noLabelCandidates) setNoLabels(true);
      })
      .catch(() => setError('Unexpected error.'))
      .finally(() => {
        setCalculatingLabels(false);
      });
  }, [history, filterParams.statuses, filterParams.query]);

  const handlePrintLabels = useCallback(
    (allOrdersSelected: boolean, selectedOrders: string[]) => {
      if (allOrdersSelected) {
        handleCalculateAllLabels();
      } else {
        handleCalculateLabels(selectedOrders);
      }
    },
    [handleCalculateAllLabels, handleCalculateLabels],
  );

  const totalPageCount = useMemo(() => Math.ceil(ordersNumber / ORDERS_PER_PAGE), [ordersNumber]);

  return isOrderDetails ? (
    <OrderDetailsLayout orderId={orderId} />
  ) : isRefundDetails ? (
    <RefundsDetailsPage refundId={orderId} />
  ) : (
    <LoadingWrapper loading={calculatingLabels} className="orders-wrapper-loader">
      <div className="orders-wrapper">
        <AppPage title="Orders">
          {noLabels && (
            <AppBanner title="No orders applicable for label purchase" status="warning" />
          )}
          <AppCard title="Summary" sections={[{ content: <OrdersSummaryComponent /> }]} />
          <AppCard
            title="Orders"
            sections={[
              {
                content: (
                  <OrderListWithTabs
                    orders={orders}
                    fetching={fetching}
                    totalPageCount={totalPageCount}
                    filterParams={filterParams}
                    onFilterChange={handleFilterUpdate}
                    currency={currency}
                    handlePrintLabels={handlePrintLabels}
                  />
                ),
              },
            ]}
          />
        </AppPage>
      </div>
      {error && <AppToast content={error} onDismiss={() => setError('')} error />}
    </LoadingWrapper>
  );
};
