import { createAction } from 'typesafe-actions';
import { IGetRetailerOrdersQueryParams } from '../../../../shopify-supplier/api/orders.api';
import { IAddress } from '../../../../shopify-supplier/interfaces/IOrder';
import { ICancellationRequestLineItem, IRetailerOrdersWithCount } from '../../../api/orders.api';
import { IOrderDetails, IRetailerOrdersSummary } from '../../../interfaces/IOrder';
import { ACTIONS_CONSTANTS } from '../../actions-constants';

export const getOrdersAction = createAction(
  ACTIONS_CONSTANTS.GET_ORDERS,
  (params: IGetRetailerOrdersQueryParams) => params,
)();

export const setOrderListFetchingAction = createAction(
  ACTIONS_CONSTANTS.SET_ORDERS_FETCHING,
  (fetching: boolean) => fetching,
)();

export const setOrderDetailsFetchingAction = createAction(
  ACTIONS_CONSTANTS.SET_ORDERS_DETAILS_FETCHING,
  (fetching: boolean) => fetching,
)();

export const setOrdersAction = createAction(
  ACTIONS_CONSTANTS.SET_ORDERS,
  (ordersAndCount: IRetailerOrdersWithCount) => ordersAndCount,
)();

export const getOrderDetailsAction = createAction(
  ACTIONS_CONSTANTS.GET_ORDER_DETAILS,
  (orderId: string) => orderId,
)();

export const setOrderDetailsAction = createAction(
  ACTIONS_CONSTANTS.SET_ORDER_DETAILS,
  (orderId: string, details: IOrderDetails) => ({ orderId, details }),
)();

export const requestCancellationAction = createAction(
  ACTIONS_CONSTANTS.REQUEST_CANCELLATION,
  (orderId: string, body: ICancellationRequestLineItem[]) => ({ orderId, body }),
)();

export const revokeCancellationAction = createAction(
  ACTIONS_CONSTANTS.REVOKE_CANCELLATION,
  (orderId: string) => orderId,
)();

export const cancelOrderAction = createAction(
  ACTIONS_CONSTANTS.CANCEL_ORDER,
  (orderId: string) => orderId,
)();

export const acceptCrowdshipCancellationAction = createAction(
  ACTIONS_CONSTANTS.ACCEPT_CROWDSHIP_CANCELLATION,
  (orderId: string) => orderId,
)();

export const chargeForOrderAction = createAction(
  ACTIONS_CONSTANTS.CHARGE_FOR_ORDER,
  (orderId: string) => orderId,
)();

export const processOrderAction = createAction(
  ACTIONS_CONSTANTS.PROCESS_ORDER,
  (orderId: string) => orderId,
)();

export const setProcessingAction = createAction(
  ACTIONS_CONSTANTS.SET_PROCESS,
  (isProcessing: boolean) => isProcessing,
)();

export const editShippingAddressAction = createAction(
  ACTIONS_CONSTANTS.EDIT_ADDRESS,
  (address: IAddress, orderId: string, validate: boolean) => ({ address, orderId, validate }),
)();

export const setShippingAddressAction = createAction(
  ACTIONS_CONSTANTS.SET_ADDRESS,
  (address: IAddress, orderId: string) => ({ address, orderId }),
)();

export const getOrdersSummaryAction = createAction(ACTIONS_CONSTANTS.GET_ORDERS_SUMMARY)();

export const showPaymentFailedToastAction = createAction(
  ACTIONS_CONSTANTS.SHOW_PAYMENT_FAILED_TOAST,
)();
export const hidePaymentFailedToastAction = createAction(
  ACTIONS_CONSTANTS.HIDE_PAYMENT_FAILED_TOAST,
)();

export const showAddressValidationFailedToastAction = createAction(
  ACTIONS_CONSTANTS.SHOW_ADDRESS_VALIDATION_FAILED_TOAST,
)();
export const hideAddressValidationFailedToastAction = createAction(
  ACTIONS_CONSTANTS.HIDE_ADDRESS_VALIDATION_FAILED_TOAST,
)();

export const showAddressValidationSuccessToastAction = createAction(
  ACTIONS_CONSTANTS.SHOW_ADDRESS_VALIDATION_SUCCESS_TOAST,
)();
export const hideAddressValidationSuccessToastAction = createAction(
  ACTIONS_CONSTANTS.HIDE_ADDRESS_VALIDATION_SUCCESS_TOAST,
)();

export const setAddressValidationAction = createAction(
  ACTIONS_CONSTANTS.SET_ADDRESS_VALIDATION,
  (validating: boolean) => validating,
)();

export const hideUpdatingOrderStatusToastAction = createAction(
  ACTIONS_CONSTANTS.HIDE_UPDATING_CANCELLATION_STATUS_TOAST,
)();

export const setOrdersSummaryAction = createAction(
  ACTIONS_CONSTANTS.SET_ORDERS_SUMMARY,
  (summary: IRetailerOrdersSummary) => summary,
)();
