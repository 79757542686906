import { TopBar } from '@shopify/polaris';
import { HintMajor } from '@shopify/polaris-icons';
import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { logoutAction } from '../../../redux/modules/auth/auth.actions';
import {
  getAccountNameSelector,
  getRetailerNameSelector,
  isAuthenticatedSelector,
} from '../../../redux/modules/auth/auth.selectors';

type NavbarProps = {
  theme: 'dark' | 'light';
  onThemeChange: (theme: 'dark' | 'light') => void;
  onNavigationToggle: () => void;
};

export const Navbar = ({ theme, onThemeChange, onNavigationToggle }: NavbarProps) => {
  const dispatch = useDispatch();
  const [isUserMenuOpen, setIsUserMenuOpen] = useState(false);
  const authenticated = useSelector(isAuthenticatedSelector);
  const accountName = useSelector(getAccountNameSelector);
  const retailerName = useSelector(getRetailerNameSelector);

  const initials = useMemo(
    () =>
      accountName
        ? accountName
            .trim()
            .split(' ')
            .map((word) => word[0])
            .join('')
        : '',
    [accountName],
  );

  const toggleIsUserMenuOpen = useCallback(
    () => setIsUserMenuOpen((isUserMenuOpen) => !isUserMenuOpen),
    [],
  );

  const userMenu = useMemo(
    () => (
      <TopBar.UserMenu
        actions={[
          {
            items: [
              {
                content: 'Toggle color theme',
                icon: HintMajor,
                onAction: () => {
                  theme === 'dark' ? onThemeChange('light') : onThemeChange('dark');
                },
              },
            ],
          },
          {
            items: [
              {
                content: 'Logout',
                onAction: () => {
                  localStorage.removeItem('token');
                  dispatch(logoutAction());
                },
              },
            ],
          },
        ]}
        name={retailerName || accountName || '<not set>'}
        detail={retailerName ? accountName : undefined}
        initials={initials}
        open={isUserMenuOpen}
        onToggle={toggleIsUserMenuOpen}
      />
    ),
    [
      retailerName,
      accountName,
      initials,
      isUserMenuOpen,
      toggleIsUserMenuOpen,
      theme,
      onThemeChange,
      dispatch,
    ],
  );

  return (
    <TopBar
      showNavigationToggle
      userMenu={authenticated && userMenu}
      onNavigationToggle={onNavigationToggle}
    />
  );
};
