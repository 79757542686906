import { axiosInstance } from '../../core/api/base.api';
import { VISIBILITY_MODE } from '../constants/preferences.constants';
import {
  AVAILABLE_RATES,
  IMarketplaceVisibilityPreferences,
  INotificationsPreferences,
  IOrderPreferences,
  IPayoutPreferences,
  IPreferences,
  IProductPreferences,
  IProfilePreferences,
  IShippingPreferences,
  IVisibilityPreferences,
  IWarehouseAddress,
  ShippingType,
} from '../interfaces/IPreferences';

class PreferencesApi {
  private backend = process.env.REACT_APP_BACK_END_URL as string;
  private rootPath = '/api/supplier/shopify/preferences';
  private supplierPath = '/api/supplier/preferences';

  async getPreferences() {
    return await axiosInstance.get<IPreferencesFromApi>(`${this.backend}${this.rootPath}`);
  }

  async updatePrivateNetworkPreferences(
    preferences: Pick<IVisibilityPreferences, 'privateNetwork'>,
  ) {
    return await axiosInstance.put<void | { error: string }>(
      `${this.backend}${this.supplierPath}/private-network`,
      preferences,
    );
  }

  async updateProfilePreferences(preferences: IProfilePreferences) {
    return await axiosInstance.put<void>(`${this.backend}${this.rootPath}/profile`, preferences);
  }

  async updateProfileFilesPreferences(files: FormData) {
    return await axiosInstance.put<{ logo: string; licenseFile: string }>(
      `${this.backend}${this.supplierPath}/profile/files`,
      files,
    );
  }

  async updateMarketplacePreferences(preferences: IMarketplaceVisibilityPreferences) {
    return await axiosInstance.put<void>(`${this.backend}${this.supplierPath}/marketplace`, {
      blacklist: preferences.list,
      autoApprove: preferences.autoApprove,
    });
  }

  async updateProductPreferences(preferences: IProductPreferences) {
    return await axiosInstance.put<void>(`${this.backend}${this.rootPath}/product`, {
      product: preferences,
    });
  }

  async updateShippingPreferences(preferences: ShippingPreferencesFromApi) {
    return await axiosInstance.put<{ isValid: boolean }>(
      `${this.backend}${this.supplierPath}/shipping`,
      preferences,
    );
  }

  async validateAddress(warehouseAddress: WarehouseAddressFromApi) {
    return await axiosInstance.post<{ isValid: boolean }>(
      `${this.backend}${this.supplierPath}/validate-address`,
      { warehouseAddress },
    );
  }

  async updateOrderPreferences(preferences: IOrderPreferences) {
    return await axiosInstance.put<void>(`${this.backend}${this.rootPath}/order`, {
      order: preferences,
    });
  }

  async updateNotificationPreferences(preferences: INotificationsPreferences) {
    return await axiosInstance.put<void>(
      `${this.backend}${this.supplierPath}/notifications`,
      preferences,
    );
  }

  async updatePayoutPreferences(preferences: IPayoutPreferences) {
    return await axiosInstance.put<void>(`${this.backend}${this.supplierPath}/payout`, preferences);
  }

  async validateCustomerId(customerId: string) {
    return await axiosInstance.get<{ success: true } | { notFound: true } | { notProvided: true }>(
      `${this.backend}${this.rootPath}/private-network/validate-customer`,
      { params: { customerId } },
    );
  }
}

export const preferencesApi = new PreferencesApi();

type WarehouseAddressFromApi = Partial<
  Pick<IWarehouseAddress, 'address2' | 'province' | 'provinceCode'>
> &
  Omit<IWarehouseAddress, 'address2' | 'province' | 'provinceCode'>;

export interface IPreferencesFromApi extends Omit<IPreferences, 'visibility' | 'shipping'> {
  visibility: {
    marketplace: {
      mode: VISIBILITY_MODE;
      list: { _id: string; name: string }[];
      autoApprove: boolean;
    };
    privateNetwork: {
      retailer: { _id: string; name: string };
      paidViaCrowdship: boolean;
      customerId?: string;
      handlingFee?: number;
      orderPrefix?: string;
      tags?: string;
    }[];
  };
  shipping: ShippingPreferencesFromApi;
}

type ShippingPreferencesFromApi = Omit<
  IShippingPreferences,
  'shippingTypesMapping' | 'handlingFee' | 'useCustomWarehouseAddress' | 'warehouseAddress'
> & {
  handlingFee?: number;
  shippingTypesMapping: {
    shippingType: ShippingType;
    availableRates: AVAILABLE_RATES;
    startDeliveryTime?: number | null;
    finishDeliveryTime?: number | null;
    customShippingTypeName?: string | null;
    flatRateCost?: string;
  }[];
  warehouseAddress?: WarehouseAddressFromApi;
  useCustomWarehouseAddress: boolean;
};
