import { Autocomplete, Icon, Stack, Tag } from '@shopify/polaris';
import { SearchMinor } from '@shopify/polaris-icons';
import { OptionDescriptor } from '@shopify/polaris/build/ts/latest/src/types';
import { Field, useFormikContext } from 'formik';
import { useCallback, useEffect, useState } from 'react';

type AutocompleteWithLoading = {
  name: string;
  options: OptionDescriptor[];
  loading: boolean;
  label?: string;
  placeholder?: string;
  validate?: (value: string[]) => string | undefined;
  onChange?: (value: string) => void;
};

export const AppControlledAutocompleteFieldWithLoading = ({
  name,
  validate,
  options,
  loading,
  label = '',
  placeholder,
  onChange,
}: AutocompleteWithLoading) => {
  const [inputValue, setInputValue] = useState('');
  const { getFieldProps, registerField, unregisterField, setFieldValue } = useFormikContext();
  const field = getFieldProps({ name });

  useEffect(() => {
    registerField(name, { validate });
    return () => {
      unregisterField(name);
    };
  }, [name, validate, registerField, unregisterField]);

  const [selectedOptions, setSelectedOptions] = useState<OptionDescriptor[]>([]);
  const [autocompleteOptions, setAutocompleteOptions] = useState<OptionDescriptor[]>(options);

  useEffect(() => {
    setAutocompleteOptions(options);
  }, [options]);

  useEffect(() => {
    // Initial value population
    setSelectedOptions(field.value || []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      selectedOptions.length === field.value.length &&
      selectedOptions.every((o) => field.value.some((v) => v.value === o.value))
    )
      return;
    setSelectedOptions(field.value);
  }, [field.value, selectedOptions]);

  const handleChange = useCallback(
    (value) => {
      setInputValue(value);
      if (onChange) onChange(value);
    },
    [onChange],
  );

  const updateSelection = useCallback(
    (selected: string[]) => {
      const options = selected.map((s) => ({
        value: s,
        label: (autocompleteOptions.find((o) => o.value === s) ||
          selectedOptions.find((o) => o.value === s))!.label,
      }));
      setSelectedOptions(options);
      setFieldValue(name, options);
    },
    [autocompleteOptions, name, selectedOptions, setFieldValue],
  );

  const removeTag = useCallback(
    (tagId) => () => {
      const options = selectedOptions.filter((o) => o.value !== tagId);
      setSelectedOptions(options);
      setFieldValue(name, options);
    },
    [name, selectedOptions, setFieldValue],
  );

  const tagsMarkup = selectedOptions.map((option) => (
    <Tag key={`option${option.value}`} onRemove={removeTag(option.value)}>
      {option.label as string}
    </Tag>
  ));

  return (
    <Field name={name} validate={validate}>
      {() => (
        <>
          <Stack>{tagsMarkup}</Stack>
          <br />
          <Autocomplete
            allowMultiple
            options={autocompleteOptions}
            selected={selectedOptions.map((e) => e.value)}
            onSelect={updateSelection}
            loading={loading}
            textField={
              <Autocomplete.TextField
                label={label}
                value={inputValue}
                onChange={handleChange}
                placeholder={placeholder}
                prefix={<Icon source={SearchMinor} color="base" />}
                autoComplete="off"
              />
            }
          />
        </>
      )}
    </Field>
  );
};
