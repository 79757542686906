import { validation } from '../../helpers/validations.helper';
import { AppControlledTextField } from '../forms/controlled-text-field/controlled-text-field';
import { AppFormLayoutGroup } from '../forms/form-layout-group/form-layout-group';
import { AppFormLayout } from '../forms/form-layout/form-layout';
import { AppCard } from '../structure/card/card';
import { AppLayoutAnnotatedSection } from '../structure/layout/layout-annotated-section';

interface IAccountInfoFormProps {
  formFieldPrefix?: string;
}

export const AccountInfoForm = ({ formFieldPrefix = '' }: IAccountInfoFormProps) => (
  <>
    <AppLayoutAnnotatedSection title="Business info">
      <AppCard sectioned>
        <AppFormLayout>
          <AppFormLayoutGroup>
            <AppControlledTextField
              label="Legal Business Name"
              name={`${formFieldPrefix}businessName`}
              validate={validation.isNotEmpty}
            />
            <AppControlledTextField
              label="TaxID"
              name={`${formFieldPrefix}taxId`}
              placeholder={'11-1111111'}
              validate={validation.isOptionalTaxID}
            />
          </AppFormLayoutGroup>
          <AppFormLayoutGroup>
            <AppControlledTextField
              label="Company Display Name"
              name={`${formFieldPrefix}companyName`}
              placeholder={'Our company'}
              validate={validation.isNotEmpty}
            />
          </AppFormLayoutGroup>
        </AppFormLayout>
      </AppCard>
    </AppLayoutAnnotatedSection>
    <AppLayoutAnnotatedSection
      title="Contact info"
      description="Crowdship will use this information to contact you about relevant updates."
    >
      <AppCard sectioned>
        <AppFormLayout>
          <AppFormLayoutGroup>
            <AppControlledTextField
              label="Contact name"
              name={`${formFieldPrefix}contactName`}
              placeholder={'John Doe'}
              validate={validation.isNotEmpty}
            />
          </AppFormLayoutGroup>
          <AppFormLayoutGroup>
            <AppControlledTextField
              label="Contact email"
              name={`${formFieldPrefix}email`}
              placeholder={'example@email.com'}
              helpText={"We'll use this address for important account communications."}
              validate={validation.isEmail}
            />
            <AppControlledTextField
              label="Fulfillment email"
              name={`${formFieldPrefix}customerEmail`}
              placeholder={'example@email.com'}
              helpText={'This address will be used for order and product related notifications.'}
              validate={validation.isEmail}
            />
          </AppFormLayoutGroup>
          <AppFormLayoutGroup>
            <AppControlledTextField
              label="Accounting email"
              name={`${formFieldPrefix}accountingEmail`}
              placeholder={'example@email.com'}
              helpText={'This address will be used for accounting related notifications.'}
              validate={validation.isEmail}
            />
            <AppControlledTextField
              label="Contact phone"
              name={`${formFieldPrefix}phone`}
              placeholder={'3215551234'}
              validate={validation.isOptionalPhoneNumber}
            />
          </AppFormLayoutGroup>
        </AppFormLayout>
      </AppCard>
    </AppLayoutAnnotatedSection>
    <AppLayoutAnnotatedSection title="Billing address">
      <AppCard sectioned>
        <AppFormLayout>
          <AppFormLayoutGroup>
            <AppControlledTextField
              label="Address line"
              name={`${formFieldPrefix}line1`}
              validate={validation.isNotEmpty}
            />
            <AppControlledTextField label="Address line 2" name={`${formFieldPrefix}line2`} />
            <AppControlledTextField
              label="City"
              name={`${formFieldPrefix}city`}
              validate={validation.isNotEmpty}
            />
            <AppControlledTextField
              label="State"
              name={`${formFieldPrefix}state`}
              validate={validation.isNotEmpty}
            />
            <AppControlledTextField
              label="Zip code"
              name={`${formFieldPrefix}zip`}
              validate={validation.isNotEmpty}
            />
            <AppControlledTextField
              label="Country Code"
              name={`${formFieldPrefix}country`}
              validate={validation.isCountryCode}
            />
          </AppFormLayoutGroup>
        </AppFormLayout>
      </AppCard>
    </AppLayoutAnnotatedSection>
  </>
);
