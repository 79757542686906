import { FormikState } from 'formik';
import { AppControlledTextField } from '../../../../../core/components/forms/controlled-text-field/controlled-text-field';
import { AppControlledRadioGroup } from '../../../../../core/components/forms/radio/controlled-radio-group/controlled-radio-group';
import { AppCard } from '../../../../../core/components/structure/card/card';
import { AppLayoutAnnotatedSection } from '../../../../../core/components/structure/layout/layout-annotated-section';
import { validation } from '../../../../../core/helpers/validations.helper';
import { IPreferences, PAYOUT_METHOD } from '../../../../interfaces/IPreferences';
import { AchWireDetailsForm } from './ach-wire-details-form';
import { IPreferencesSectionProps } from './IPreferencesSectionProps';
import { AppTextContainer } from '../../../../../core/components/text-container/text-container';

export const PayoutSection = ({
  values,
  initialValues,
  resetForm,
  setFieldValue,
}: IPreferencesSectionProps & {
  initialValues: IPreferences;
  setFieldValue: (field: string, value: any) => void;
  resetForm: (nextState?: Partial<FormikState<IPreferences>> | undefined) => void;
}) => {
  return (
    <AppLayoutAnnotatedSection title="Payouts">
      <AppCard sectioned title="Payout method">
        <AppTextContainer>
          <p>
            By default, all retailers are charged for purchase orders via their Crowdship Wallet at
            time of sale. When you ship a retailer’s order, you’ll be paid automatically to the
            account below.
          </p>
          <p>
            To collect payment directly instead, you can disable autopay for a specific retailer on
            their Retailer Settings page.{' '}
            <a
              href="https://crowdship.io/features/supplier-features/payment-options"
              rel="noreferrer"
              target="_blank"
            >
              Learn more
            </a>
            .
          </p>
        </AppTextContainer>
        <br />
        <AppControlledRadioGroup
          optionsDesc={[
            {
              value: PAYOUT_METHOD.ACH,
              label: 'ACH',
              helpText: values.payout.payoutMethod === PAYOUT_METHOD.ACH && <AchWireDetailsForm />,
            },
            {
              value: PAYOUT_METHOD.WIRE_TRANSFER,
              label: 'Wire transfer',
              helpText: values.payout.payoutMethod === PAYOUT_METHOD.WIRE_TRANSFER && (
                <AchWireDetailsForm />
              ),
            },
            {
              value: PAYOUT_METHOD.PAYPAL,
              label: 'PayPal',
              helpText: values.payout.payoutMethod === PAYOUT_METHOD.PAYPAL && (
                <div className="payout-details">
                  <AppControlledTextField
                    label="PayPal email"
                    placeholder=""
                    name="payout.paypalEmail"
                    validate={validation.isEmail}
                  />
                </div>
              ),
            },
          ]}
          name="payout.payoutMethod"
          onChange={(value: string) => {
            if (
              (values.payout.payoutMethod !== PAYOUT_METHOD.PAYPAL &&
                value === PAYOUT_METHOD.PAYPAL) ||
              (values.payout.payoutMethod === PAYOUT_METHOD.PAYPAL &&
                value !== PAYOUT_METHOD.PAYPAL)
            ) {
              resetForm({
                values: {
                  ...initialValues,
                },
              });
              setFieldValue('payout.payoutMethod', value);
            }
          }}
        />
      </AppCard>
    </AppLayoutAnnotatedSection>
  );
};
