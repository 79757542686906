import { createAction } from 'typesafe-actions';
import {
  IImportListProductGroup,
  IImportListProductGroupsWithCount,
} from '../../../api/import-list.api';
import { ACTIONS_CONSTANTS } from '../../actions-constants';

export const getImportListProductsAction = createAction(
  ACTIONS_CONSTANTS.GET_IMPORT_LIST_PRODUCTS,
  (page: number) => page,
)();

export const setImportListProductsAction = createAction(
  ACTIONS_CONSTANTS.SET_IMPORT_LIST_PRODUCTS,
  (productGroups: IImportListProductGroupsWithCount & { page: number }) => productGroups,
)();

export const setImportListProductAction = createAction(
  ACTIONS_CONSTANTS.SET_IMPORT_LIST_PRODUCT,
  (productGroup: IImportListProductGroup) => productGroup,
)();

export const addImportListProductToInventoryAction = createAction(
  ACTIONS_CONSTANTS.POST_PRODUCTS_FROM_IMPORT_LIST_TO_INVENTORY,
  (productIds: string[], importMax: boolean) => ({
    productIds,
    importMax,
  }),
)();

export const addAllImportListProductToInventoryAction = createAction(
  ACTIONS_CONSTANTS.POST_ALL_PRODUCTS_FROM_IMPORT_LIST_TO_INVENTORY,
  (importMax: boolean) => importMax,
)();

export const deleteProductFromImportListAction = createAction(
  ACTIONS_CONSTANTS.DELETE_PRODUCT_FROM_IMPORT_LIST,
  (productId: string) => productId,
)();

export const showInventoryProductsAddedToastAction = createAction(
  ACTIONS_CONSTANTS.SHOW_INVENTORY_PRODUCTS_ADDED_TOAST,
)();

export const hideInventoryProductsAddedToastAction = createAction(
  ACTIONS_CONSTANTS.HIDE_INVENTORY_PRODUCTS_ADDED_TOAST,
)();

export const showLimitExceededBannerAction = createAction(
  ACTIONS_CONSTANTS.SHOW_PRODUCT_LIMIT_EXCEEDED_ERROR_BANNER,
)();

export const hideLimitExceededBannerAction = createAction(
  ACTIONS_CONSTANTS.HIDE_PRODUCT_LIMIT_EXCEEDED_ERROR_BANNER,
)();

export const showLimitExceededModalAction = createAction(
  ACTIONS_CONSTANTS.SHOW_PRODUCT_LIMIT_EXCEEDED_MODAL,
)();

export const hideLimitExceededModalAction = createAction(
  ACTIONS_CONSTANTS.HIDE_PRODUCT_LIMIT_EXCEEDED_MODAL,
)();

export const showProductRemovedToastAction = createAction(
  ACTIONS_CONSTANTS.SHOW_PRODUCT_REMOVED_TOAST,
)();

export const hideProductRemovedToastAction = createAction(
  ACTIONS_CONSTANTS.HIDE_PRODUCT_REMOVED_TOAST,
)();

export const showImportListFetchingErrorToastAction = createAction(
  ACTIONS_CONSTANTS.SHOW_IMPORT_LIST_FETCHING_ERROR_TOAST,
)();
