import { useIndexResourceState } from '@shopify/polaris';
import { SelectionType } from '@shopify/polaris/build/ts/latest/src/utilities/index-provider';
import fileDownload from 'js-file-download';
import moment from 'moment';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { PageAwarePagination } from '../../../../core/components/pagination/page-aware-pagination';
import { AppCard } from '../../../../core/components/structure/card/card';
import { INVOICES_PER_PAGE } from '../../../../core/constants/invoices.constants';
import { IGetPaidOrdersInvoicesParams, invoiceApi } from '../../../api/invoice.api';
import { IOrderInvoice } from '../../../interfaces/IInvoice';
import { InvoiceList } from '../../containers/invoice-list/invoice-list';
import './invoice-layout.scss';

export function PaymentHistoryLayout() {
  const [invoices, setInvoices] = useState<IOrderInvoice[]>([]);
  const [selectedInvoices, setSelectedInvoices] = useState<string[]>([]);
  const [allInvoicesSelected, setAllInvoicesSelected] = useState<boolean>(false);
  const [fetching, setFetching] = useState(false);
  const [isFiltersHidden, setIsFiltersHidden] = useState(false);
  const [total, setTotal] = useState(0);
  const [filterParams, setFilterParams] = useState<IGetPaidOrdersInvoicesParams>({
    page: 0,
    limit: INVOICES_PER_PAGE,
  });
  const [selectedSuppliers, setSelectedSuppliers] = useState<string[]>([]);
  const [suppliers, setSuppliers] = useState<
    {
      label: string;
      value: string;
    }[]
  >([]);

  const totalPageCount = useMemo(() => Math.ceil(total / INVOICES_PER_PAGE), [total]);

  const formatInvoices = useMemo(
    () =>
      invoices.map((i) => ({
        ...i,
        id: `${i.id}`,
      })),
    [invoices],
  );

  const { selectedResources, allResourcesSelected, handleSelectionChange } =
    useIndexResourceState(formatInvoices);

  const deselectAll = useCallback(() => {
    handleSelectionChange('page' as SelectionType, false);
  }, [handleSelectionChange]);

  useEffect(() => {
    setFetching(true);
    invoiceApi
      .getPaidOrderInvoices(filterParams)
      .then(({ data }) => {
        setInvoices(data.invoices);
        setTotal(data.total);
      })
      .catch(console.error)
      .finally(() => setFetching(false));
  }, [filterParams]);

  useEffect(() => {
    invoiceApi
      .getSuppliersForPaymentHistory()
      .then(({ data }) => {
        setSuppliers(data);
        if (data.length === 0) setIsFiltersHidden(true);
      })
      .catch(console.error);
  }, []);

  const handleDownloadPdf = useCallback((invoiceId: string, paidViaCrowdship: boolean) => {
    setFetching(true);
    if (paidViaCrowdship) {
      invoiceApi
        .getInvoicePdfUrl(invoiceId)
        .then(({ data }) => window.open(data.url))
        .catch(console.error)
        .finally(() => {
          setFetching(false);
        });
    } else {
      invoiceApi
        .getInvoicePdf(invoiceId)
        .then(({ data }) => {
          fileDownload(data, `Invoice-${invoiceId}.pdf`);
        })
        .catch(console.error)
        .finally(() => {
          setFetching(false);
        });
    }
  }, []);

  const handleDownloadCreditPdf = useCallback((creditId: string) => {
    setFetching(true);
    invoiceApi
      .getCreditPdfUrl(creditId)
      .then(({ data }) => {
        window.open(data.url);
      })
      .catch(console.error)
      .finally(() => {
        setFetching(false);
      });
  }, []);

  const onPageChange = useCallback(
    (page: number) => {
      setFilterParams({ ...filterParams, page });
      deselectAll();
      setSelectedInvoices([]);
    },
    [deselectAll, filterParams],
  );

  const selectionChanged = (
    selectionType: SelectionType,
    isSelecting: boolean,
    selection?: any,
  ) => {
    // when selecting a single item, ignore if limit is reached (disableAdding) or if the item is processing
    if (selectionType === 'all') setAllInvoicesSelected(true);
    // if all items are selected, any other selection operation will toggle this flag
    else if (allResourcesSelected) setAllInvoicesSelected(false);
    handleSelectionChange(selectionType, isSelecting, selection);
  };

  const handleExportCSV = useCallback(
    (request: { invoicesIds: string[] }) => {
      setFetching(true);
      invoiceApi
        .exportInvoicesCSV(request)
        .then(({ data }) => {
          const now = moment().format('MMDDYYYY');
          fileDownload(data, `Invoices-${now}.csv`);
          setFetching(false);
          setSelectedInvoices([]);
          setAllInvoicesSelected(false);
          deselectAll();
        })
        .catch(console.error);
    },
    [deselectAll],
  );

  const handleExportCSVAll = useCallback(
    (request: Omit<IGetPaidOrdersInvoicesParams, 'limit' | 'page'>) => {
      setFetching(true);
      invoiceApi
        .exportAllInvoices(request)
        .then(({ data }) => {
          const now = moment().format('MMDDYYYY');
          fileDownload(data, `Invoices-${now}.csv`);
          setFetching(false);
          setSelectedInvoices([]);
          setAllInvoicesSelected(false);
          deselectAll();
        })
        .catch(console.error);
    },
    [deselectAll],
  );

  return (
    <AppCard
      title="Invoice list"
      sections={[
        {
          content: (
            <>
              <InvoiceList
                invoices={invoices}
                fetching={fetching}
                onSelectionChange={setSelectedInvoices}
                setFilterParams={setFilterParams}
                suppliers={suppliers}
                setSelectedSuppliers={setSelectedSuppliers}
                selectedSuppliers={selectedSuppliers}
                filterParams={filterParams}
                selectedResources={selectedResources}
                totalPageCount={totalPageCount}
                handleDownloadInvoice={handleDownloadPdf}
                handleDownloadCredit={handleDownloadCreditPdf}
                handleSelectionChange={selectionChanged}
                allResourcesSelected={allResourcesSelected}
                isFiltersHidden={isFiltersHidden}
                listActions={[
                  {
                    content: 'Export by SKU',
                    onAction: () => {
                      if (allInvoicesSelected) {
                        handleExportCSVAll(filterParams);
                      } else {
                        handleExportCSV({
                          invoicesIds: selectedInvoices
                            .map((selectedInvoice) =>
                              invoices
                                .filter((invoice) => invoice.id === selectedInvoice)
                                .map((invoice) => invoice.purchaseOrdersIds)
                                .flat(),
                            )
                            .flat(),
                        });
                      }
                    },
                  },
                ]}
              />
              <PageAwarePagination totalPageCount={totalPageCount} onPageChange={onPageChange} />
            </>
          ),
        },
      ]}
    />
  );
}
