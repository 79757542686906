import { PAYOUT_METHOD } from '../../shopify-supplier/interfaces/IPreferences';

export const getPayoutMethod = (method: PAYOUT_METHOD) => {
  switch (method) {
    case PAYOUT_METHOD.PAYPAL:
      return 'PayPal';
    case PAYOUT_METHOD.ACH:
      return 'ACH Transfer';
    case PAYOUT_METHOD.WIRE_TRANSFER:
      return 'Wire Transfer';
  }
};
