import { PRODUCT_CATEGORY } from '../../core/constants/product.constants';
import { INotificationSources } from '../../core/interfaces/INotificationSources';
import {
  DEFAULT_CANCELLATION_ACCEPT,
  DROP_SHIP_COST_MAPPING,
  MSRP_MAPPING,
  ON_CANCELLATION_ACTION,
  ORDER_PREFERENCES_MARKING_RULE,
  OUT_OF_STOCK_ORDERS_PROCESSING,
  PACKING_SLIP_FORMAT,
  POSTAGE_SETUP,
  SUPPLIER_ORDER_NAME_ORIGIN,
  VISIBILITY_MODE,
} from '../constants/preferences.constants';

export interface IPreferences {
  profile: IProfilePreferences;
  product: IProductPreferences;
  order: IOrderPreferences;
  notifications: INotificationsPreferences;
  shipping: IShippingPreferences;
  visibility: IVisibilityPreferences;
  payout: IPayoutPreferences;
}

export interface ISupplierShippingTypesMapping {
  shippingType: ShippingType;
  availableRates: AVAILABLE_RATES;
  startDeliveryTime: string;
  finishDeliveryTime: string;
  customShippingTypeName: string;
  flatRateCost?: string;
}

export enum AVAILABLE_RATES {
  FREE = 'Free Shipping',
  CARRIER_RATES = 'Use Live Carrier Rates',
  FLAT_RATE = 'Flat Rate Shipping',
}

export type ShippingType =
  | 'Standard Domestic'
  | 'Expedited Domestic'
  | 'Standard International'
  | 'Expedited International';

export enum BUSINESS_TYPE {
  NONE = 'NONE',
  CANNABIS_INFUSED_PRODUCT_MANUFACTURER = 'Cannabis Infused Product Manufacturer',
  CANNABIS_MANUFACTURER = 'Cannabis Manufacturer',
  CANNABIS_PRODUCT_DISTRIBUTOR = 'Cannabis Product Distributor',
}

export enum PAYOUT_METHOD {
  PAYPAL = 'paypal',
  ACH = 'ach',
  WIRE_TRANSFER = 'wire_transfer',
}

export enum PAYOUT_ACCOUNT_TYPE {
  BUSINESS_CHECKING = 'Business Checking',
  BUSINESS_SAVINGS = 'Business Savings',
  PERSONAL_CHECKING = 'Personal Checking',
  PERSONAL_SAVINGS = 'Personal Savings',
}

export interface IPayoutPreferences {
  payoutMethod: PAYOUT_METHOD;
  paypalEmail?: string;
  achOrWireDetails?: {
    routing?: string;
    account?: string;
    swiftCode?: string;
    accountOwnerName?: string;
    accountAddress?: string;
    accountType?: PAYOUT_ACCOUNT_TYPE;
  };
}

export interface ISupplierShippingMapping {
  customCarrierServiceName: string | null;
  crowdshipCarrierServiceKey: string;
  selected: boolean;
}

export interface IWarehouseAddress {
  address1: string;
  address2: string;
  city: string;
  country: string;
  countryCode: string;
  province: string;
  provinceCode: string;
  zip: string;
}

export interface IShippingPreferences {
  mapping: ISupplierShippingMapping[];
  shippingTypesMapping: ISupplierShippingTypesMapping[];
  postageSetup: POSTAGE_SETUP;
  packingSlipFormat: PACKING_SLIP_FORMAT;
  handlingFee: string;
  warehouseAddress: IWarehouseAddress;
  warehouseAddressValid: boolean;
  useCustomWarehouseAddress: 'use custom' | 'use default';
}

export interface IOrderPreferences {
  defaultCancellationAccept: DEFAULT_CANCELLATION_ACCEPT;
  cancellationAcceptThreshold?: string;
  onCancellationAction: ON_CANCELLATION_ACTION;
  outOfStockProcessing: OUT_OF_STOCK_ORDERS_PROCESSING;
  markingRule: ORDER_PREFERENCES_MARKING_RULE;
  addTag: boolean;
  customTag?: string;
  nameOrigin: SUPPLIER_ORDER_NAME_ORIGIN;
  addPrefix: boolean;
  customPrefix?: string;
}

export enum ADJUSTMENT_TYPE {
  PERCENT = 'percent',
  FIXED = 'fixed',
}

export interface IPriceAdjustmentPreference {
  amount: string;
  type: ADJUSTMENT_TYPE;
}

export interface IProfilePreferences {
  logo?: string;
  bio?: string;
  productCategory: PRODUCT_CATEGORY;
  cannabisLicensed: boolean;
  licenseName?: string;
  licenseNumber?: string;
  businessType?: BUSINESS_TYPE;
  phone?: string;
  licenseFile?: string;
}

export interface IProductPreferences {
  dropShipCost: {
    mapping: DROP_SHIP_COST_MAPPING;
    adjustment?: IPriceAdjustmentPreference;
    useSalePrice?: boolean;
    salePriceAdjustment?: IPriceAdjustmentPreference;
  };
  MSRP: {
    mapping: MSRP_MAPPING;
    adjustment?: IPriceAdjustmentPreference;
    useSalePrice?: boolean;
    salePriceAdjustment?: IPriceAdjustmentPreference;
  };
}

export interface INotificationsPreferences {
  orderReceived: INotificationSources;
  orderUnfulfilled: boolean;
  productPriceUpdateError: boolean;
}

export interface IVisibilityPreferences {
  marketplace: IMarketplaceVisibilityPreferences;
  privateNetwork: IPrivateNetworkVisibilityPreferences[];
}

export interface IMarketplaceVisibilityPreferences {
  list: { label: string; value: string }[];
  mode: VISIBILITY_MODE;
  autoApprove: 'true' | 'false';
}

export interface IPrivateNetworkVisibilityPreferences {
  retailer: { label: string; value: string };
  paidViaCrowdship: 'true' | 'false';
  customerId: string;
  handlingFee: string;
  orderPrefix: string;
  tags: string;
}
