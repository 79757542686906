import React from 'react';
import { ICustomerAddress } from '../../../../shopify-retailer/interfaces/IOrder';
import { IAddress } from '../../../../shopify-supplier/interfaces/IOrder';
import { AppCard } from '../../structure/card/card';
import { AppTextStyle } from '../../text/text-style/TextStyle';
import { getCustomerFullName } from '../../../helpers/customer-fullname.helper';

type ShippingProps = {
  shippingAddress?: ICustomerAddress | IAddress;
};

const isCustomAddress = (obj: ICustomerAddress | IAddress): obj is ICustomerAddress => {
  return 'fname' in obj && 'lname' in obj && 'company' in obj;
};

export const ShippingAddress = ({ shippingAddress }: ShippingProps) => {
  return (
    <div className="shipping">
      <AppCard sectioned>
        {shippingAddress ? (
          <div>
            {isCustomAddress(shippingAddress) ? (
              <AppTextStyle variation="strong">
                {getCustomerFullName(
                  shippingAddress.fname,
                  shippingAddress.lname,
                  shippingAddress.company,
                )}
              </AppTextStyle>
            ) : (
              <AppTextStyle variation="strong">{shippingAddress.name}</AppTextStyle>
            )}
            <div>
              {shippingAddress.address1} {shippingAddress.address2}
            </div>
            <div>
              {shippingAddress.city}, {shippingAddress.provinceCode} {shippingAddress.zip}
            </div>
            <div>{shippingAddress.country}</div>
            <div>{shippingAddress.phone}</div>
          </div>
        ) : (
          'Address not set'
        )}
      </AppCard>
    </div>
  );
};
