import React, { useMemo, useState } from 'react';
import { Form, Formik } from 'formik';
import validator from 'validator';
import { AppModal } from '../../../../core/components/overlays/modal/modal';
import { IAddress } from '../../../../shopify-supplier/interfaces/IOrder';
import { AppButton } from '../../../../core/components/button/Button';
import { AppControlledTextField } from '../../../../core/components/forms/controlled-text-field/controlled-text-field';
import { validation } from '../../../../core/helpers/validations.helper';
import { AppControlledSelect } from '../../../../core/components/forms/controlled-select/controlled-select';
import { Country } from '../../../../core/api/country.api';

import './shipping-address-modal.scss';

type ShippingAddressModalProps = {
  open: boolean;
  validating: boolean;
  address?: IAddress;
  countries: Country[];
  onConfirm: (address: IAddress, validate: boolean) => void;
  onClose: () => void;
};

export const ShippingAddressModal = ({
  open,
  address,
  validating,
  countries,
  onConfirm,
  onClose,
}: ShippingAddressModalProps) => {
  const [selectedCountry, setSelectedCountry] = useState(address?.countryCode || '');
  const [validate, setValidate] = useState(true);

  const initialValues: IAddress = useMemo(
    () => ({
      city: address?.city || '',
      address1: address?.address1 || '',
      address2: address?.address2,
      country: address?.country || '',
      countryCode: address?.countryCode || '',
      zip: address?.zip || '',
      province: address?.province,
      provinceCode: address?.provinceCode,
      name: address?.name,
      phone: address?.phone,
    }),
    [address],
  );

  const countriesOption = useMemo(
    () => countries.map((c) => ({ label: c.name, value: c.iso2 })),
    [countries],
  );

  const states = useMemo(
    () =>
      selectedCountry
        ? countries
            .find((c) => c.iso2 === selectedCountry)
            ?.states.map((s) => ({
              label: s.name,
              value: s.state_code,
            })) || []
        : [],
    [selectedCountry, countries],
  );

  return (
    <AppModal open={open} title={'Edit shipping address'} onClose={onClose}>
      <Formik
        initialValues={initialValues}
        onSubmit={(values) => {
          const newAddress: IAddress = states.length
            ? values
            : { ...values, province: null, provinceCode: null };

          onConfirm(newAddress, validate);
        }}
      >
        {({ setFieldValue, submitForm, resetForm, values }) => (
          <Form name="shipping-address" className="address-form">
            <div className="address-form__fields">
              <AppControlledSelect
                label="Country"
                name={'countryCode'}
                options={countriesOption}
                onChange={(value) => {
                  const country = countriesOption.find((c) => c.value === value)?.label;
                  setSelectedCountry(value);
                  setFieldValue('country', country);
                }}
              />
              <AppControlledSelect
                label="Province"
                name={'provinceCode'}
                options={states}
                onChange={(value) => {
                  const province = states.find((s) => s.value === value)?.label;
                  setFieldValue('province', province);
                }}
              />
              <AppControlledTextField
                label="Address"
                name={'address1'}
                validate={validation.isNotEmpty}
              />
              <AppControlledTextField label="Apartment, suite, etc." name={'address2'} />
              <AppControlledTextField label="City" name={'city'} validate={validation.isNotEmpty} />
              <AppControlledTextField
                label="Zip code"
                name={'zip'}
                validate={() =>
                  validation.isPostalCode(
                    values.zip,
                    values.countryCode as validator.PostalCodeLocale,
                  )
                }
              />
            </div>
            <div className="address-form__actions">
              <AppButton
                onClick={() => {
                  setValidate(false);
                  submitForm();
                }}
              >
                Skip validation and Save
              </AppButton>
              <AppButton onClick={resetForm}>Cancel</AppButton>
              <AppButton
                primary
                onClick={() => {
                  setValidate(true);
                  submitForm();
                }}
                loading={validating}
              >
                Save
              </AppButton>
            </div>
          </Form>
        )}
      </Formik>
    </AppModal>
  );
};
