import { AxiosResponse } from 'axios';
import { all, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';
import { showActionError } from '../../../../core/helpers/saga-error-catcher.helper';
import { IChargeResponse, IRetailerOrdersWithCount, ordersApi } from '../../../api/orders.api';
import { IOrderDetails, IRetailerOrdersSummary } from '../../../interfaces/IOrder';
import { getStatusAction } from '../status/status.actions';
import {
  acceptCrowdshipCancellationAction,
  cancelOrderAction,
  chargeForOrderAction,
  editShippingAddressAction,
  getOrderDetailsAction,
  getOrdersAction,
  getOrdersSummaryAction,
  hideUpdatingOrderStatusToastAction,
  processOrderAction,
  requestCancellationAction,
  revokeCancellationAction,
  setAddressValidationAction,
  setOrderDetailsAction,
  setOrderDetailsFetchingAction,
  setOrderListFetchingAction,
  setOrdersAction,
  setOrdersSummaryAction,
  setProcessingAction,
  setShippingAddressAction,
  showAddressValidationFailedToastAction,
  showAddressValidationSuccessToastAction,
  showPaymentFailedToastAction,
} from './orders.actions';

function* getOrderListSaga({ payload, type }: ActionType<typeof getOrdersAction>) {
  yield put(setOrderListFetchingAction(true));
  try {
    const { data }: AxiosResponse<IRetailerOrdersWithCount> = yield ordersApi.getOrders(payload);
    yield put(setOrdersAction(data));
    yield put(setOrderListFetchingAction(false));
  } catch (e) {
    yield showActionError(type, e);
    yield put(setOrderListFetchingAction(false));
  }
}

function* getOrderDetailsSaga({ payload, type }: ActionType<typeof getOrderDetailsAction>) {
  //in this saga we will not set fetching.
  //We track fetching in react component
  yield put(setOrderDetailsFetchingAction(true));
  try {
    const res: AxiosResponse<IOrderDetails | { notFound: true }> = yield ordersApi.getOrderDetails(
      payload,
    );
    if (!('notFound' in res.data)) yield put(setOrderDetailsAction(payload, res.data));
    yield put(setOrderDetailsFetchingAction(false));
  } catch (e) {
    yield showActionError(type, e);
    yield put(setOrderDetailsFetchingAction(false));
  }
}

function* getOrdersSummarySaga({ type }: ActionType<typeof getOrdersSummaryAction>) {
  try {
    const res: AxiosResponse<IRetailerOrdersSummary> = yield ordersApi.getOrdersSummary();
    yield put(setOrdersSummaryAction(res.data));
  } catch (e) {
    yield showActionError(type, e);
  }
}

function* requestCancellationSaga({ payload, type }: ActionType<typeof requestCancellationAction>) {
  try {
    yield ordersApi.requestCancellation(payload.orderId, payload.body);
    yield put(getOrderDetailsAction(payload.orderId));
  } catch (e) {
    yield showActionError(type, e);
  }
}

function* revokeCancellationSaga({ payload, type }: ActionType<typeof revokeCancellationAction>) {
  try {
    yield ordersApi.revokeCancellation(payload);
    yield put(getOrderDetailsAction(payload));
  } catch (e) {
    yield showActionError(type, e);
  }
}

function* cancelOrderSaga({ payload, type }: ActionType<typeof cancelOrderAction>) {
  try {
    yield ordersApi.cancelOrder(payload);
    yield put(getOrderDetailsAction(payload));
    yield put(hideUpdatingOrderStatusToastAction());
    yield put(getStatusAction());
  } catch (e) {
    yield showActionError(type, e);
  }
}

function* acceptCrowdshipCancellationSaga({
  payload,
  type,
}: ActionType<typeof acceptCrowdshipCancellationAction>) {
  try {
    const { data }: AxiosResponse<IChargeResponse> = yield ordersApi.acceptCrowdshipCancellation(
      payload,
    );
    yield put(getStatusAction());
    if ('paymentFailed' in data && data.paymentFailed) yield put(showPaymentFailedToastAction());
    yield put(getOrderDetailsAction(payload));
    yield put(hideUpdatingOrderStatusToastAction());
  } catch (e) {
    yield showActionError(type, e);
  }
}

function* chargeForOrderSaga({ payload, type }: ActionType<typeof chargeForOrderAction>) {
  try {
    const { data }: AxiosResponse<IChargeResponse> = yield ordersApi.chargeForOrder(payload);
    if ('success' in data && data.success) yield put(getStatusAction());
    else if ('paymentFailed' in data && data.paymentFailed)
      yield put(showPaymentFailedToastAction());
    yield put(getOrderDetailsAction(payload));
    yield put(hideUpdatingOrderStatusToastAction());
  } catch (e) {
    yield showActionError(type, e);
  }
}

function* editShippingAddressSaga({ payload, type }: ActionType<typeof editShippingAddressAction>) {
  try {
    yield put(setAddressValidationAction(true));

    const { data }: AxiosResponse<{ isValid: boolean }> = yield ordersApi.editShippingAddress(
      payload.orderId,
      payload.address,
      payload.validate,
    );

    if (data.isValid || !payload.validate) {
      yield put(setShippingAddressAction(payload.address, payload.orderId));
      yield put(showAddressValidationSuccessToastAction());
    } else {
      yield put(showAddressValidationFailedToastAction());
    }
  } catch (e) {
    yield showActionError(type, e);
  }
}

function* processOrderSaga({ payload, type }: ActionType<typeof processOrderAction>) {
  yield put(setProcessingAction(true));
  try {
    yield ordersApi.processOrder(payload);

    yield put(getStatusAction());

    yield put(getOrderDetailsAction(payload));
  } catch (e) {
    yield showActionError(type, e);
  } finally {
    yield put(setProcessingAction(false));
  }
}

export function* orderSaga() {
  yield all([
    takeLatest(getOrdersAction, getOrderListSaga),
    takeLatest(getOrdersSummaryAction, getOrdersSummarySaga),
    takeEvery(getOrderDetailsAction, getOrderDetailsSaga),
    takeEvery(requestCancellationAction, requestCancellationSaga),
    takeEvery(revokeCancellationAction, revokeCancellationSaga),
    takeEvery(cancelOrderAction, cancelOrderSaga),
    takeEvery(acceptCrowdshipCancellationAction, acceptCrowdshipCancellationSaga),
    takeEvery(chargeForOrderAction, chargeForOrderSaga),
    takeEvery(editShippingAddressAction, editShippingAddressSaga),
    takeEvery(processOrderAction, processOrderSaga),
  ]);
}
