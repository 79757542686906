import { createReducer } from 'typesafe-actions';
import { PRODUCTS_PER_PAGE, PRODUCT_INVENTORY } from '../../../../core/constants/product.constants';
import { Paginated, PickOptions } from '../../../../core/helpers/generic.helper';
import { ISupplierProduct, IVariantMapping } from '../../../../core/interfaces/IProduct';
import { ISupplierProductsListFilter } from '../../../../core/interfaces/IProductFilter';
import { IFoundUpdateProductRetailAvailabilityResult } from '../../../api/products.api';
import { ISupplierVariant } from '../../../interfaces/IProduct';
import { RootActions } from '../../actions';
import {
  getProductsAction,
  getVariantsAction,
  hideSuccessVariantPriceUpdateToastAction,
  removeLastAvailabilityUpdateAction,
  resetProductFiltersAction,
  setAvailableGroupsFilterAction,
  setFilterOptionsAction,
  setLastAvailabilityUpdateAction,
  setLimitFilterAction,
  setPageFilterAction,
  setProductFiltersAction,
  setProductsAction,
  setProductsFetching,
  setStockFilterAction,
  setQueryFilterAction,
  setTypeFilterAction,
  setVariantsAction,
  setVendorFilterAction,
  showSuccessVariantPriceUpdateToastAction,
  updateVariantAction,
  setShopifyStatusFilterAction,
  setInventoryFilterAction,
  setUpdatingAllProductsAction,
} from './products.actions';

export type MoveOpType = 'added' | 'removed';

export interface IProductsMoveOp extends IFoundUpdateProductRetailAvailabilityResult {
  action: MoveOpType;
}

interface IProductsReducer {
  products: ISupplierProduct[];
  options: PickOptions<ISupplierProductsListFilter>;
  filters: Paginated<ISupplierProductsListFilter>;
  count: number;
  fetching: boolean;
  variants: IVariantMapping<ISupplierVariant>;
  lastAvailabilityUpdate: IProductsMoveOp | null;
  showSuccessVariantPriceUpdateToast: boolean;
  isUpdatingAllProducts: boolean;
}

const defaultFilterValue: Paginated<ISupplierProductsListFilter> = {
  query: '',
  vendors: [],
  types: [],
  groups: [],
  shopifyStatus: [],
  hasStock: false,
  page: 0,
  limit: PRODUCTS_PER_PAGE,
  productInventory: PRODUCT_INVENTORY.SHOW_ALL,
};

const initialState: IProductsReducer = {
  products: [],
  options: {
    vendors: [],
    types: [],
    groups: [],
    shopifyStatus: [
      { label: 'Active', value: 'active' },
      { label: 'Archived', value: 'archived' },
      { label: 'Draft', value: 'draft' },
    ],
  },
  filters: defaultFilterValue,
  count: 0,
  fetching: false,
  variants: {},
  lastAvailabilityUpdate: null,
  showSuccessVariantPriceUpdateToast: false,
  isUpdatingAllProducts: false,
};

export const productsReducer = createReducer<IProductsReducer, RootActions>(initialState)
  .handleAction(getProductsAction, (state) => ({ ...state, fetching: true }))
  .handleAction(getVariantsAction, (state) => ({ ...state, fetching: true }))
  .handleAction(updateVariantAction, (state, { payload }) => ({
    ...state,
    variants: {
      ...state.variants,
      [payload.productId]: state.variants[payload.productId].map((v) =>
        v.id === payload.id ? { ...v, ...payload } : v,
      ),
    },
  }))
  .handleAction(setProductsAction, (state, { payload }) => ({
    ...state,
    products: payload.productGroups,
    count: payload.total,
    fetching: false,
  }))
  .handleAction(setVariantsAction, (state, { payload }) => ({
    ...state,
    variants: { ...state.variants, [payload.productId]: payload.variants },
    fetching: false,
  }))
  .handleAction(setFilterOptionsAction, (state, { payload }) => ({
    ...state,
    options: payload,
  }))
  .handleAction(showSuccessVariantPriceUpdateToastAction, (state) => ({
    ...state,
    showSuccessVariantPriceUpdateToast: true,
  }))
  .handleAction(hideSuccessVariantPriceUpdateToastAction, (state) => ({
    ...state,
    showSuccessVariantPriceUpdateToast: false,
  }))
  .handleAction(setLastAvailabilityUpdateAction, (state, { payload }) => ({
    ...state,
    lastAvailabilityUpdate: payload,
  }))
  .handleAction(removeLastAvailabilityUpdateAction, (state) => ({
    ...state,
    lastAvailabilityUpdate: null,
  }))
  .handleAction(setProductsFetching, (state, { payload }) => ({ ...state, fetching: payload }))
  .handleAction(setProductFiltersAction, (state, { payload }) => ({
    ...state,
    filters: { ...state.filters, ...payload, page: 0 },
  }))
  .handleAction(resetProductFiltersAction, (state) => ({
    ...state,
    filters: defaultFilterValue,
  }))
  .handleAction(setQueryFilterAction, (state, { payload }) => ({
    ...state,
    filters: { ...state.filters, ...payload, page: 0 },
  }))
  .handleAction(setVendorFilterAction, (state, { payload }) => ({
    ...state,
    filters: { ...state.filters, ...payload, page: 0 },
  }))
  .handleAction(setTypeFilterAction, (state, { payload }) => ({
    ...state,
    filters: { ...state.filters, ...payload, page: 0 },
  }))
  .handleAction(setShopifyStatusFilterAction, (state, { payload }) => ({
    ...state,
    filters: { ...state.filters, ...payload, page: 0 },
  }))
  .handleAction(setStockFilterAction, (state, { payload }) => ({
    ...state,
    filters: { ...state.filters, ...payload, page: 0 },
  }))
  .handleAction(setAvailableGroupsFilterAction, (state, { payload }) => ({
    ...state,
    filters: { ...state.filters, ...payload, page: 0 },
  }))
  .handleAction(setPageFilterAction, (state, { payload }) => ({
    ...state,
    filters: { ...state.filters, ...payload },
  }))
  .handleAction(setLimitFilterAction, (state, { payload }) => ({
    ...state,
    filters: { ...state.filters, ...payload },
  }))
  .handleAction(setInventoryFilterAction, (state, { payload }) => ({
    ...state,
    filters: { ...state.filters, ...payload, page: 0 },
  }))
  .handleAction(setUpdatingAllProductsAction, (state, { payload }) => ({
    ...state,
    isUpdatingAllProducts: payload,
  }));
