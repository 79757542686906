import { Form, Formik } from 'formik';
import React, { useCallback, useMemo, useState } from 'react';
import { AlertMinor } from '@shopify/polaris-icons';
import { AppModal } from '../../../../core/components/overlays/modal/modal';
import { AppCard } from '../../../../core/components/structure/card/card';
import { CANCELLATION_REASON } from '../../../../core/interfaces/ILineItemCancellation';
import { ICancelOrderLineItem, ICancelOrderLineItemsBody } from '../../../api/orders.api';
import { IOrderDetailsProduct } from '../../../interfaces/IOrder';
import { CancellationProductList } from './cancellation-product-list';
import './create-cancellation-modal.scss';
import { AppSelect } from '../../../../core/components/forms/select/select';
import { AppTextField } from '../../../../core/components/forms/text-field/text-field';
import { AppCheckbox } from '../../../../core/components/checkbox/checkbox';

interface CreateCancellationModalProps {
  open: boolean;
  onClose: () => void;
  products: IOrderDetailsProduct[];
  onCreate: (request: ICancelOrderLineItemsBody) => void;
  isSomeLocationUpdated: boolean;
}

export function CreateCancellationModal({
  open,
  onClose,
  products,
  onCreate,
  isSomeLocationUpdated,
}: CreateCancellationModalProps) {
  const [reason, setReason] = useState<CANCELLATION_REASON>(CANCELLATION_REASON.INVENTORY);
  const [commentVisible, setCommentVisible] = useState(false);
  const [restock, setRestock] = useState(false);
  const [comment, setComment] = useState('');

  const changeReason = useCallback(
    (updatedReason: CANCELLATION_REASON) => {
      setReason(updatedReason);
      if (updatedReason === CANCELLATION_REASON.OTHER) {
        setCommentVisible(true);
      } else if (commentVisible) setCommentVisible(false);
    },
    [commentVisible],
  );

  const toggleRestock = useCallback(() => {
    setRestock((r) => !r);
  }, []);

  const handleSubmit = useCallback(
    (submittedProducts: ICancelOrderLineItem[]) => {
      if (submittedProducts.every((p) => !p.quantity)) return onClose();
      onCreate({
        lineItems: submittedProducts,
        restock,
        reason,
        comment: commentVisible && comment ? comment : undefined,
      });
    },
    [onCreate, onClose, reason, commentVisible, comment, restock],
  );

  const initialValues: ICancelOrderLineItem[] = useMemo(
    () =>
      products
        .map((p) => ({
          productId: p.productId,
          variantId: p.variantId,
          quantity: (
            p.totalQty -
            p.cancellations.reduce((q, c) => q + c.quantity.accepted, 0) -
            p.fulfilledQty
          ).toString(),
        }))
        .filter((p) => p.quantity),
    [products],
  );

  const reasonOptions = [
    { label: 'Products are out of stock', value: CANCELLATION_REASON.INVENTORY },
    { label: 'Other', value: CANCELLATION_REASON.OTHER },
  ];

  return (
    <AppModal open={open} onClose={onClose} title={'Select products for cancellation'} large>
      <Formik initialValues={initialValues} onSubmit={handleSubmit} isInitialValid>
        {({ submitForm, values }) => (
          <Form name={'cancelOrderProducts'}>
            <AppCard
              sections={[
                {
                  content: (
                    <>
                      <div className="reason">
                        <div className="reason-select">
                          <AppSelect
                            label="Reason: "
                            labelInline
                            options={reasonOptions}
                            onChange={changeReason}
                            value={reason}
                          />
                        </div>
                        {commentVisible && (
                          <div className="comment-input">
                            <AppTextField
                              label=""
                              value={comment}
                              onChange={setComment}
                              id="cancellation-reason-comment"
                              autoComplete="off"
                            />
                          </div>
                        )}
                      </div>
                      <div className="restock-flag">
                        <AppCheckbox
                          label="Restock items"
                          checked={restock}
                          onChange={toggleRestock}
                        />
                        {isSomeLocationUpdated && (
                          <span className="warning-message">
                            <AlertMinor width={15} height={15} /> Restocking may fail as it appears
                            the inventory location has changed since this order was fulfilled.
                          </span>
                        )}
                      </div>
                      <CancellationProductList products={products} inputValues={values} />
                    </>
                  ),
                },
              ]}
              primaryFooterAction={{
                content: 'Cancel products',
                onAction: submitForm,
              }}
            />
          </Form>
        )}
      </Formik>
    </AppModal>
  );
}
