import { AppIcon } from '../../icon/icon';
import { AppTooltip } from '../../overlays/tooltip/tooltip';
import { CashDollarMajor } from '@shopify/polaris-icons';

type CustomPriceIndicatorProps = {
  show?: boolean;
  isPrice?: boolean;
};

export const CustomPriceIndicator = ({ show, isPrice }: CustomPriceIndicatorProps) =>
  show ? (
    <AppTooltip
      content={`Some variants of this product have a custom ${
        isPrice ? 'price' : 'price for retailer or SRP'
      }`}
      dismissOnMouseOut
    >
      <AppIcon source={CashDollarMajor} color="subdued" />
    </AppTooltip>
  ) : null;
