import React from 'react';
import { useMemo } from 'react';
import { AppList } from '../../../../core/components/list/list';
import { AppCard } from '../../../../core/components/structure/card/card';
import { ICurrency } from '../../../../core/interfaces/ICurrency';
import { IPayoutPreferences } from '../../../../shopify-supplier/interfaces/IPreferences';
import { getPayoutMethod } from '../../../../core/helpers/get-payout-method.helper';

interface ISupplierPayoutInfoProps {
  payoutInfo: IPayoutPreferences | null;
  loading: boolean;
  currency?: ICurrency;
}

export const SupplierPayoutInfo = ({ payoutInfo, loading }: ISupplierPayoutInfoProps) => {
  const payToInfo = useMemo(
    () =>
      !loading && payoutInfo
        ? [
            `Payout method: ${getPayoutMethod(payoutInfo.payoutMethod)}`,
            !!payoutInfo.paypalEmail && `Paypal email: ${payoutInfo.paypalEmail}`,
            !!payoutInfo.achOrWireDetails?.routing &&
              `Routing number: ${payoutInfo.achOrWireDetails.routing}`,
            !!payoutInfo.achOrWireDetails?.account &&
              `Account number: ${payoutInfo.achOrWireDetails.account}`,
            !!payoutInfo.achOrWireDetails?.swiftCode &&
              `SWIFT code: ${payoutInfo.achOrWireDetails.swiftCode}`,
            !!payoutInfo.achOrWireDetails?.accountOwnerName &&
              `Account Owner Name: ${payoutInfo.achOrWireDetails.accountOwnerName}`,
            !!payoutInfo.achOrWireDetails?.accountAddress &&
              `Account Address: ${payoutInfo.achOrWireDetails.accountAddress}`,
            !!payoutInfo.achOrWireDetails?.accountType &&
              `Account Type: ${payoutInfo.achOrWireDetails.accountType}`,
          ].filter((li) => li)
        : [],
    [payoutInfo, loading],
  );

  return (
    <AppCard title="Supplier payment info" sectioned>
      <AppList>{payToInfo}</AppList>
    </AppCard>
  );
};
