import * as H from 'history';
import printJS from 'print-js';

const convertB64ToBlob = (b64Data, contentType = '', sliceSize = 512) => {
  const byteCharacters = atob(b64Data);
  const byteArrays: Uint8Array[] = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize),
      byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
};

const createPdfFrame = (url: string) => {
  const iframe = document.createElement('iframe');
  document.body.appendChild(iframe);

  iframe.style.display = 'none';
  iframe.src = url;
  iframe.onload = function () {
    setTimeout(function () {
      iframe.focus();
      iframe!.contentWindow!.print();
    }, 1000);
  };
};

const printJsSafari = (data: string) => {
  const blob = convertB64ToBlob(data, 'application/pdf');
  const blobUrl = URL.createObjectURL(blob);
  createPdfFrame(blobUrl);
};

const printJsSafariChangingHistory = (
  data: Blob,
  history: H.History<unknown>,
  location: string,
) => {
  const blobUrl = URL.createObjectURL(data);
  history.push(location);
  createPdfFrame(blobUrl);
};

const isSafariUsed = () => {
  return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
};

export const printPdf = (data: string) => {
  isSafariUsed()
    ? printJsSafari(data)
    : printJS({
        printable: data,
        type: 'pdf',
        base64: true,
      });
};

export const printPdfChangingHistory = (
  data: string,
  history: H.History<unknown>,
  location: string,
) => {
  const pdfBlob = new Blob([data], {
    type: 'application/pdf',
  });

  if (isSafariUsed()) {
    printJsSafariChangingHistory(pdfBlob, history, location);
  } else {
    printJS({
      printable: URL.createObjectURL(pdfBlob),
      type: 'pdf',
      onLoadingEnd: () => {
        history.push(location);
      },
    });
  }
};
