import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import {
  getFetching,
  getOrdersSummarySelector,
  getSalesSummarySelector,
} from '../../../redux/modules/dashboard/dashboard-selectors';
import { getOrdersSummaryAction } from '../../../redux/modules/orders/orders.actions';
import { getSalesSummaryAction } from '../../../redux/modules/dashboard/dashboard-actions';
import { AppPage } from '../../../../core/components/structure/page/page';
import { AppStack } from '../../../../core/components/structure/stack/Stack';
import { DashboardCard } from '../../../../core/components/structure/card/dashboard-card/dashboard-card';
import { withCurrency } from '../../../../core/helpers/currency.helper';
import { getLocaleQuantity } from '../../../../core/helpers/quantity.helper';

import './dashboard-statistic.scss';

export const DashboardStatistic = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const salesSummary = useSelector(getSalesSummarySelector);
  const ordersSummary = useSelector(getOrdersSummarySelector);
  const fetching = useSelector(getFetching);

  React.useEffect(() => {
    dispatch(getOrdersSummaryAction());
    dispatch(getSalesSummaryAction());
  }, [dispatch]);

  const onCardLinkClick = () => {
    history.push('/orders');
  };

  return (
    <AppPage title="Dashboard">
      <AppStack vertical={false} distribution={'leading'}>
        <div className="dashboard_layout_card_wrapper">
          <DashboardCard
            content={[
              { title: 'all', value: ordersSummary.numberOfAllOrders || 0 },
              { title: 'unfulfilled', value: ordersSummary.numberOfUnfulfilledOrders || 0 },
            ]}
            title={'Orders'}
            cardAction={[{ content: 'Manage', onAction: onCardLinkClick }]}
            loading={fetching}
          />
        </div>
        <div className="dashboard_layout_card_wrapper">
          <DashboardCard
            content={[
              { title: 'Total Earned', value: withCurrency(salesSummary.supplierTotal) },
              { title: 'Items sold', value: getLocaleQuantity(salesSummary.itemsSold) },
            ]}
            title={'Sales'}
            loading={fetching}
          />
        </div>
      </AppStack>
    </AppPage>
  );
};
