import { all, takeLatest, put } from 'redux-saga/effects';
import { getStatusAction, setStatusAction } from './status.actions';
import { ActionType } from 'typesafe-actions';
import { showActionError } from '../../../../core/helpers/saga-error-catcher.helper';
import { statusApi } from '../../../api/status.api';

function* getStatusSaga({ type }: ActionType<typeof getStatusAction>) {
  try {
    const { data } = yield statusApi.getStatus();
    yield put(setStatusAction(data));
  } catch (e) {
    console.error(e);
    yield showActionError(type, e);
  }
}

export function* statusSaga() {
  yield all([takeLatest(getStatusAction, getStatusSaga)]);
}
