import { AxiosResponse } from 'axios';
import { axiosInstance } from '../../core/api/base.api';
import { Paginated } from '../../core/helpers/generic.helper';
import { IRefundDetails } from '../../core/interfaces/IRefundDetails';
import { ICustomerAddress } from '../../shopify-retailer/interfaces/IOrder';
import {
  IDimensions,
  IOrderDetails,
  IOrderList,
  IOrdersSummary,
  IUnpaidOrdersSummary,
} from '../interfaces/IOrder';

class OrdersApi {
  private backend = process.env.REACT_APP_BACK_END_URL as string;
  private rootPath = '/api/supplier/shopify/orders';

  async getOrdersSummary(): Promise<AxiosResponse<IOrdersSummary>> {
    return await axiosInstance.get(`${this.backend}${this.rootPath}/summary`);
  }

  async getUnpaidOrdersSummary(): Promise<AxiosResponse<IUnpaidOrdersSummary>> {
    return await axiosInstance.get(`${this.backend}${this.rootPath}/unpaid/summary`);
  }

  async getRetailersForUnpaidOrders(): Promise<AxiosResponse<IRetailersForUnpaidOrders[]>> {
    return await axiosInstance.get(`${this.backend}${this.rootPath}/unpaid/retailers`);
  }

  async getOrdersList(params: IGetOrdersQueryParams): Promise<AxiosResponse<IOrderList>> {
    return await axiosInstance.get(`${this.backend}${this.rootPath}`, {
      params: { ...params, statuses: params.statuses?.length ? params.statuses.join() : undefined },
    });
  }

  async exportOrdersCSV(body: { platformOrderIds: number[] }): Promise<AxiosResponse<any>> {
    return await axiosInstance.post(`${this.backend}${this.rootPath}`, body, {
      responseType: 'blob',
    });
  }

  async exportUnpaidOrdersCSV(body: { ids: string[] }): Promise<AxiosResponse<any>> {
    return await axiosInstance.post(`${this.backend}${this.rootPath}/unpaid`, body, {
      responseType: 'blob',
    });
  }

  async exportAllOrders(filter: Omit<IGetOrdersQueryParams, 'limit' | 'page'>) {
    const { query, statuses } = filter;
    return await axiosInstance.post(
      `${this.backend}${this.rootPath}/all`,
      { query, statuses: statuses?.length ? statuses : undefined },
      {
        responseType: 'blob',
      },
    );
  }

  async exportAllUnpaidOrders(filter: Omit<IGetUnpaidOrdersQueryParams, 'limit' | 'page'>) {
    const { retailerId, dateRange, paidViaCrowdship } = filter;
    return await axiosInstance.post(
      `${this.backend}${this.rootPath}/unpaid/all`,
      { paidViaCrowdship, retailerId, startDate: dateRange?.start, endDate: dateRange?.end },
      {
        responseType: 'blob',
      },
    );
  }

  async getLabelCandidates(filters: Paginated<{}>): Promise<
    AxiosResponse<{
      data: ILabelCandidateDetail[];
      total: number;
    }>
  > {
    return await axiosInstance.get(`${this.backend}${this.rootPath}/labels`, {
      params: {
        ...filters,
      },
    });
  }

  async calculateLabels({
    orderIds,
  }: {
    orderIds: string[];
  }): Promise<AxiosResponse<CreateLabelCandidatesResult>> {
    return await axiosInstance.post(`${this.backend}${this.rootPath}/labels/calc`, {
      orderIds,
    });
  }

  async calculateAllLabels(filters: {
    statuses: string[];
    query: string;
  }): Promise<AxiosResponse<CreateLabelCandidatesResult>> {
    const { statuses, query } = filters;

    return await axiosInstance.post(`${this.backend}${this.rootPath}/labels/calc/all`, {
      statuses: statuses.join(),
      query,
    });
  }

  async getLabelsCost(): Promise<AxiosResponse<{ total: number }>> {
    return await axiosInstance.get(`${this.backend}${this.rootPath}/labels/summary`);
  }

  async purchaseLabels(labelCandidates: string[], withPS: boolean): Promise<AxiosResponse<any>> {
    return await axiosInstance.post(
      `${this.backend}${this.rootPath}/labels/purchase`,
      {
        labelCandidates,
        withPS,
      },
      { responseType: 'blob' },
    );
  }

  async purchaseAllLabels(withPS: boolean): Promise<AxiosResponse<any>> {
    return await axiosInstance.post(
      `${this.backend}${this.rootPath}/labels/purchase/all`,
      { withPS },
      { responseType: 'blob' },
    );
  }

  async getUnpaidOrdersList({
    limit,
    page,
    retailerId,
    dateRange,
    paidViaCrowdship,
  }: IGetUnpaidOrdersQueryParams): Promise<AxiosResponse<IOrderList>> {
    return await axiosInstance.get<IOrderList>(`${this.backend}${this.rootPath}/unpaid`, {
      params: {
        limit,
        page,
        paidViaCrowdship,
        retailerId,
        startDate: dateRange?.start,
        endDate: dateRange?.end,
      },
    });
  }

  async getTotalForFilteredInvoiceItems({
    retailerId,
    dateRange,
  }: IGetTotalForFilteredInvoiceItemsQueryParams) {
    return await axiosInstance.get<IGetTotalForFilteredInvoiceItemsResponse>(
      `${this.backend}${this.rootPath}/unpaid/all`,
      {
        params: {
          retailerId,
          startDate: dateRange?.start,
          endDate: dateRange?.end,
        },
      },
    );
  }

  async getOrderDetails(orderId: number): Promise<AxiosResponse<IOrderDetails>> {
    return await axiosInstance.get(`${this.backend}${this.rootPath}/${orderId}/details`);
  }

  async getRefundDetails(orderId: string): Promise<AxiosResponse<IRefundDetails>> {
    return await axiosInstance.get(`${this.backend}${this.rootPath}/refunds/${orderId}/details`);
  }

  async getOrderPackingSlip(orderId: string | number) {
    return await axiosInstance.get<any>(`${this.backend}${this.rootPath}/${orderId}/packing-slip`, {
      responseType: 'blob',
    });
  }

  async acceptCancellationRequest(orderId: number): Promise<AxiosResponse<void>> {
    return await axiosInstance.post(
      `${this.backend}${this.rootPath}/${orderId}/accept-cancellation`,
    );
  }

  async declineCancellationRequest(orderId: number): Promise<AxiosResponse<void>> {
    return await axiosInstance.post(
      `${this.backend}${this.rootPath}/${orderId}/decline-cancellation`,
    );
  }

  async cancelLineItems(
    orderId: number,
    body: ICancelOrderLineItemsBody,
  ): Promise<AxiosResponse<void>> {
    return await axiosInstance.post(
      `${this.backend}${this.rootPath}/${orderId}/cancel-items`,
      body,
    );
  }

  async refundOrder(orderId: number, body: IRefundOrderBody): Promise<AxiosResponse<void>> {
    return await axiosInstance.post(`${this.backend}${this.rootPath}/${orderId}/refund`, body);
  }

  async calcShippingCost(
    orderId: number,
    items: { id: string; name: string; price: number; qty: number; grams: number }[],
    locationId: number,
    totalWeight: number,
    dimensions: IDimensions,
    bypassValidation: boolean,
  ) {
    return await axiosInstance.post<ShipmentCalculationReturnResult>(
      `${this.backend}${this.rootPath}/${orderId}/label/calc`,
      { items, locationId, totalWeight, dimensions, bypassValidation },
    );
  }

  async purchaseShippingLabel(
    orderId: number,
    items: { id: string; qty: number; grams: number }[],
    locationId: number,
    totalWeight: number,
    dimensions: IDimensions,
    rateId: string,
    price: number,
  ) {
    return await axiosInstance.post<{ labelURL: string }>(
      `${this.backend}${this.rootPath}/${orderId}/label/purchase`,
      { items, locationId, rateId, price, totalWeight, dimensions },
    );
  }

  async getLabel(labelURL: string, orderId: number, withPS: boolean) {
    return await axiosInstance.post<any>(
      `${this.backend}${this.rootPath}/${orderId}/label/convert`,
      {
        url: labelURL,
        withPS,
      },
      {
        ...(withPS && { responseType: 'blob' }),
      },
    );
  }

  async voidLabel(orderId: number, labelId: string) {
    return await axiosInstance.delete<void>(
      `${this.backend}${this.rootPath}/${orderId}/label/${labelId}`,
    );
  }
}

export const ordersApi = new OrdersApi();

export interface IGetOrdersQueryParams {
  statuses: string[];
  limit: number;
  page: number;
  query: string;
}

export interface IGetRetailerOrdersQueryParams {
  statuses: string[];
  limit: number;
  page: number;
  query: string;
}

export interface IGetUnpaidOrdersQueryParams {
  limit: number;
  page: number;
  retailerId?: string;
  paidViaCrowdship?: boolean;
  dateRange?: {
    start: number;
    end: number;
  };
}

export interface IGetTotalForFilteredInvoiceItemsQueryParams {
  retailerId?: string;
  dateRange?: {
    start: number;
    end: number;
  };
}

export interface ICancelOrderLineItem {
  productId: string;
  variantId: string;
  quantity: string;
}

export interface ICancelOrderLineItemsBody {
  lineItems: ICancelOrderLineItem[];
  reason: string;
  restock: boolean;
  comment?: string;
}

export interface IRefundOrderBody {
  refundAmount: number;
  reason?: string;
}

export interface IRetailersForUnpaidOrders {
  label: string;
  value: string;
}

export interface ILabelCandidateDetail extends Record<string, unknown> {
  id: string;
  rateId: string;
  amount: number;
  shippingMethod: string;
  totalWeight: number;
  dimensions: IDimensions;
  locationId: number;
  destinationAddress: ICustomerAddress;
  createdAt: string;
  supplierOrder: {
    platformOrderId: number;
    supplierOrderName: string;
  };
}

export interface IGetTotalForFilteredInvoiceItemsResponse {
  total: number;
  ids: string[];
}

type ShipmentCalculationReturnResult =
  | { rateId: string; cost: number; name: string }
  | { cannotFitInOneShipment: true };

type CreateLabelCandidatesResult =
  | { success: true }
  | { noLabelCandidates: true }
  | { error: true };
