import { DropZone } from '@shopify/polaris';
import { NoteMinor } from '@shopify/polaris-icons';
import { useCallback, useState } from 'react';
import { AppDropZone } from '../../../../core/components/drop-zone/drop-zone';
import { AppToast } from '../../../../core/components/feedback-indicators/toast/toast';
import { AppThumbnail } from '../../../../core/components/image-containers/thumbnail/Thumbnail';
import { AppCard } from '../../../../core/components/structure/card/card';
import { AppStack } from '../../../../core/components/structure/stack/Stack';
import { AppCaption } from '../../../../core/components/text/caption/caption';
import { productsApi } from '../../../api/products.api';

type BulkPriceUpdateProps = {
  onFileUploaded?: () => void;
};

export const BulkPriceUpdate = ({ onFileUploaded }: BulkPriceUpdateProps) => {
  const [file, setFile] = useState<any>();
  const [uploading, setUploading] = useState(false);
  const [uploaded, setUploaded] = useState(false);
  const [error, setError] = useState(false);

  const handleDropZoneDrop = useCallback((acceptedFiles) => setFile(acceptedFiles[0]), []);

  const handleFileUpload = useCallback(() => {
    setUploading(true);
    const formData = new FormData();
    formData.append('file', file, file.name);
    productsApi
      .uploadCsvForPriceUpdates(formData)
      .then(() => {
        setUploaded(true);
      })
      .catch((error) => {
        setError(true);
        console.error(error);
      })
      .finally(() => {
        setFile(undefined);
        setUploading(false);
        onFileUploaded && onFileUploaded();
      });
  }, [file, onFileUploaded]);

  return (
    <>
      <AppCard
        title="CSV price updates"
        primaryFooterAction={{
          content: 'Update prices',
          onAction: handleFileUpload,
          disabled: !file,
          loading: uploading,
        }}
      >
        <p style={{ padding: '1em' }}>
          The uploaded CSV file must have columns named "SKU", "SRP" and "Drop Ship Cost", otherwise
          the price will not be updated.
        </p>
        <div style={{ padding: '1em' }}>
          <AppDropZone
            allowMultiple={false}
            onDropAccepted={handleDropZoneDrop}
            accept="text/csv"
            type="file"
            errorOverlayText="File type must be .csv"
          >
            {!file && <DropZone.FileUpload />}
            {file && (
              <div
                style={{
                  padding: '1em',
                  display: 'flex',
                  height: '100%',
                  justifyContent: 'center',
                }}
              >
                <AppStack alignment="center" distribution="center">
                  <AppThumbnail size="small" alt={file.name} source={NoteMinor} />
                  <div>
                    {file.name} <AppCaption>{file.size} bytes</AppCaption>
                  </div>
                </AppStack>
              </div>
            )}
          </AppDropZone>
        </div>
      </AppCard>
      {uploaded && <AppToast content="Prices updated" onDismiss={() => setUploaded(false)} />}
      {error && (
        <AppToast content="Error when uploading file" onDismiss={() => setError(false)} error />
      )}
    </>
  );
};
