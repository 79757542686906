import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { AppLink } from '../../../../../core/components/link/link';
import { AppCard } from '../../../../../core/components/structure/card/card';
import { AppLayoutAnnotatedSection } from '../../../../../core/components/structure/layout/layout-annotated-section';
import { AppTextContainer } from '../../../../../core/components/text-container/text-container';
import { AppDisplayText } from '../../../../../core/components/text/display-text/display-text';
import { AppTextStyle } from '../../../../../core/components/text/text-style/TextStyle';
import { IPreferences } from '../../../../interfaces/IPreferences';
import { getStatusSelector } from '../../../../redux/modules/status/status.selectors';
import { ControlledRetailerAutocompleteField } from '../inputs/controlled-retailer-autocomplete-field';
import { AppList } from '../../../../../core/components/list/list';
import { AppControlledRadioGroup } from '../../../../../core/components/forms/radio/controlled-radio-group/controlled-radio-group';

type MarketplaceSectionProps = {
  values: IPreferences;
};

export const MarketplaceSection = ({ values }: MarketplaceSectionProps) => {
  const { hasMarketplaceApproval } = useSelector(getStatusSelector);

  const selectedPNRetailers = useMemo(
    () => values.visibility.privateNetwork.map((rn) => rn.retailer),
    [values.visibility.privateNetwork],
  );

  return (
    <div className="marketplace-section">
      <div className="description">
        <AppDisplayText size="small">Sell on Crowdship Marketplace</AppDisplayText>
        <AppTextContainer>
          <p>
            1000+ online stores use Crowdship Marketplace to source and drop ship products.
            Instantly expose your products to online retailers, influencers, and marketers ready to
            sell your products.
          </p>
        </AppTextContainer>
      </div>
      <AppLayoutAnnotatedSection
        title="Marketplace Application"
        description="Crowdship Marketplace is a curated catalog for approved Suppliers only. For a list of restricted products, please see our Acceptable use policy."
      >
        <AppCard title="Marketplace Status" sectioned>
          <div className="marketplace-visibility-description">
            <AppTextContainer>
              <p>
                To join Crowdship Marketplace, complete our{' '}
                <AppLink
                  onClick={() =>
                    window.open('https://crowdship.io/marketplace-application', '_blank')
                  }
                  external
                >
                  Supplier Application
                </AppLink>
              </p>
            </AppTextContainer>
            <AppTextContainer>
              <p>
                Note: When selling on Crowdship Marketplace, Crowdship will automatically collect
                payment from retailers as orders are created. You will be paid out every 30 days via
                your desired payout method. A 5% processing fee will be deducted from each payout.
              </p>
            </AppTextContainer>
            <p>
              {!hasMarketplaceApproval && (
                <AppTextStyle variation="negative">
                  You don't yet have approval to push your products to Marketplace
                </AppTextStyle>
              )}
              {hasMarketplaceApproval && (
                <AppTextStyle variation="positive">
                  You have approval to push your products to Marketplace
                </AppTextStyle>
              )}
            </p>
          </div>
        </AppCard>
      </AppLayoutAnnotatedSection>
      {hasMarketplaceApproval && (
        <AppLayoutAnnotatedSection
          title="Marketplace Retailer Approval"
          description={<AppList>{RETAILER_AUTO_APPROVE_DETAILS}</AppList>}
        >
          <AppCard title="Retailer Auto-Approval" sectioned>
            <AppControlledRadioGroup
              optionsDesc={[
                {
                  value: 'true',
                  label: 'Auto approve',
                  helpText: (
                    <>
                      <strong>Note:</strong> You must have Autopay enabled to automatically accept
                      new Retailers and their orders. Check Autopay Settings to accept orders from
                      Marketplace Retailers.
                    </>
                  ),
                },
                {
                  value: 'false',
                  label: 'Manual review',
                  helpText:
                    'I will manually review and approve each Retailer that requests to sell my products.',
                },
              ]}
              name={'visibility.marketplace.autoApprove'}
            />
          </AppCard>
        </AppLayoutAnnotatedSection>
      )}
      <AppLayoutAnnotatedSection
        title="Restrict Visibility"
        description="To restrict specific retailers from selling your products, add them to the list. (optional)"
      >
        <AppCard title="Restrict specific retailers from accessing your products" sectioned>
          <p>
            Your products are <b>not</b> available to the following Retailers:
          </p>
          <ControlledRetailerAutocompleteField
            name="visibility.marketplace.list"
            excludeOptions={selectedPNRetailers}
          />
        </AppCard>
      </AppLayoutAnnotatedSection>
    </div>
  );
};

const RETAILER_AUTO_APPROVE_DETAILS = [
  'Retailers can find your products on Crowdship Marketplace and request to sell them in their store.',
  'You can manually review each retailers request, or automatically approve all retailers to sell your products.',
];
